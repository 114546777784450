import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import FormsComponent from './Forms';

const Forms = () => {
    
    document.title="DTS | Zilter";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                           
                    <BreadCrumb title="Forms" pageTitle="Forms" icon={"ri-book-open-line"} />
                    <Row>
                        <FormsComponent />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Forms;