import BreadCrumb from 'Components/Common/BreadCrumb';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Button,
    Col,
    Container,
    Nav,
    NavItem,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Label,
} from 'reactstrap';
import avtarImage2 from '../../assets/images/users/avatar-1.jpg';
import avtarImage3 from '../../assets/images/users/user-dummy-img.jpg';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import TableContainer from './TableComponent';


const Access = () => {

    const [activeArrowTab, setactiveArrowTab] = useState<number>(1);
    const [passedarrowSteps, setPassedarrowSteps] = useState<number[]>([1]);
    const toggleArrowTab = (tab: number) => {
        if (activeArrowTab !== tab) {
            const modifiedSteps = [...passedarrowSteps, tab];
            if (tab >= 1 && tab <= 3) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    };

    const CreateRole = () => {
        // Implement your logic for creating a role
    };
    const columns = [
        {
            Header:"Email",
            accessor: "email",
            filterable: true,
            isSortable:false,
            disableFilters: true,
            show: true
        },
        {
            Header:"Status",
            accessor: "status",
            filterable: true,
            isSortable:false,
            disableFilters: true,
            show: true
        }
    ]

    const calendarcolumns = [
        {
            Header:"Account",
            accessor: "account",
            filterable: true,
            isSortable:false,
            disableFilters: true,
            show: true
        },
        {
            Header:"Status",
            accessor: "status",
            filterable: true,
            isSortable:false,
            disableFilters: true,
            show: true
        }
    ]

    return (
        <React.Fragment>
            <div className="hstack step-arrow-nav mt-4 mb-4">
                <Row className="w-100 hstack gap-3 justify-content-center">
                    <Col lg={12} className="vstack justify-content-between">
                        <h4>Connected accounts</h4>
                        <div className='vstack gap-1'>
                            <Label className='fw-bold fs-16'>Email</Label>
                            <p>Personal email accounts connected to Zilter to log, track, send, and receive emails.</p>       
                            <div className='px-2'>
                                <TableContainer
                                    columns={columns}
                                    data={[]}
                                    divClass="table-responsive table-card mb-1"
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted"
                                    SearchPlaceholder='Search...'
                                    isPageSizeChange={true}
                                    isBordered={true}
                                    title={"There are no personal email accounts connected"}
                                    subtitle={"They can connect their personal email account in their account settings."}
                                />
                            </div>                    
                            
                        </div>
                        <hr />
                    </Col>
                    <Col lg={12} className="vstack justify-content-between">
                        <div className='vstack gap-1'>
                            <Label className='fw-bold fs-16'>Calendar</Label>
                            <p>Personal calendar connected to use Zilter Meetings, calendar sync and scheduling pages.</p>   
                            <div className='px-2'>                     
                                <TableContainer
                                    columns={calendarcolumns}
                                    data={[]}
                                    divClass="table-responsive table-card mb-1"
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted"
                                    SearchPlaceholder='Search...'
                                    isPageSizeChange={true}
                                    isBordered={true}
                                    title={"There is no personal calendar connected"}
                                    subtitle={"They can connect their personal calendar in their account settings."}
                                />
                            </div>   
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Access);