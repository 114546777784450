import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Input, Label, Row, Table, Modal, ModalHeader, ModalBody, ButtonGroup } from "reactstrap";
import logo from "../../../../../assets/images/dtsimg/svg/zilter_logo_blue.svg";
import moment from "moment";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { confirmRecommendations } from "store/recommendation/action";
import { SendEmail } from "store/applications/action";
import { useSelector } from "react-redux";
import { applicationsMetadataGetRequest, applicationUpdateRequest, applicationGetRequest } from "store/applications/action";
import { ApplicationState } from "store";
import { ToastContainer, toast } from 'react-toastify';
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import ReactDOMServer from "react-dom/server";
import currency from "common/currency";
import config from "config";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { authorizationletterCreateRequest } from "store/authorizationletters/action";

const { api } = config;

const DTS_API_STUDENT = api.DTS_API_STUDENT
const DTS_URL = api.url
interface EmailBodyProps {
    studentName: string; // Name of the student
    reviewLink: string;  // Link for the student to review
}

const AuthorizationForm = (props: any) => {
    const { onCloseClick, show, applicationsDetails, tenantId } = props;
    const navigate = useNavigate();
    const location = useLocation();
    // const applicationsDetails: any = useSelector((state: ApplicationState) => state.applications.details);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const dataReceived = location.state;
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFomData] = useState<any>()
    const [value, setValue] = useState<any>(null)
    const [reason, setReason] = useState<string>("")
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedSingle, setSelectedSingle] = useState<any>(null);
    const [ucasId, setUcasId] = useState<any>(null)
    const [channelId, setChannelId] = useState<any>(null)
    const [courseName, setCourseName] = useState<any>(null)
    const [disabled, setDisabled] = useState(false)
    const [inputs, setInputs] = useState<any>({
        reason: '',
        last_name: '', 
        first_name: '', 
        course_name: '',  
        date_of_birth: '', 
        country_of_nationality: '', 
        channel_name: '', 
        createdAt: '', 
        ucas_id:''
    })
    const [formValues, setFormValues] = useState<any>(null)
    // Toggle modal visibility
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    useEffect(() => {
        if (dataReceived) setFomData(dataReceived)
    }, [])

    // "https://qa.zilter.io/authority-letter/205a6c0a805c4a90bb4d2abbc17ba1ff/37b5fed8-def8-4c5e-b9c5-fb3f50b8db6f"

    const SingleOptions = currency.map(country => ({
        value: country?.name, // Use the country's name as the value
        label: country?.name, // Use the country's name as the label
    }));



    function handleSelectSingle(selectedSingle: any) {
        setSelectedSingle(selectedSingle);
    }
    const EmailBody: React.FC<EmailBodyProps> = ({ studentName, reviewLink }) => (
        <div>
            <p>Dear {studentName || "Student"},</p>
            <p>I hope this email finds you well.</p>
            <p>
                You have been provided a unique link to complete an important process.
                Kindly follow the steps below to review, submit, and complete your task:
            </p>
            <ol>
                <li>
                    <strong>Review and Verify Information:</strong> Ensure all the
                    details displayed are accurate and up-to-date.
                </li>
                <li>
                    <strong>Selfie Verification:</strong> You will be prompted to take a
                    selfie to verify your identity. Please follow the instructions
                    carefully.
                </li>
                <li>
                    <strong>Submit and Generate PDF:</strong> After completing the
                    review and verification, submit the information. A PDF containing
                    the completed details will be generated for your records.
                </li>
            </ol>
            <p>         <strong><a style={{ color: "blue" }} href={reviewLink}>Click here</a></strong>&nbsp;<span>to begin the process</span></p>
            <p>Thank you for your prompt attention to this matter.</p>

        </div>
    );

    const onSend = () => {     
        setDisabled(true)  
        const handleSuccessAuth = (body: any) => {        
            onCloseClick()
            let countryname = applicationsDetails?.valuesJson?.student?.country_of_nationality || applicationsDetails?.valuesJson?.student?.nationality || applicationsDetails?.valuesJson?.student?.country_of_origin          
            let country = countryname ? SingleOptions.filter((selected: any) => selected?.value.toLowerCase() === countryname.toLowerCase()) : []
            setSelectedSingle(country)  
            let newInputs = {...inputs, reason: null, id:applicationsDetails?.id, last_name: applicationsDetails?.valuesJson?.student?.last_name, first_name: applicationsDetails?.valuesJson?.student?.first_name, course_name: applicationsDetails?.valuesJson?.courses?.name,  date_of_birth: applicationsDetails?.valuesJson?.student?.date_of_birth, country_of_nationality: country[0]?.value, channel_name:{label: applicationsDetails?.valuesJson?.channel_name?.name, value: applicationsDetails?.valuesJson?.channel_name?.id}, createdAt: applicationsDetails?.createdAt, ucas_id:applicationsDetails?.valuesJson?.ucas_id }
            setInputs(newInputs)  
                const toemail =
                    applicationsDetails?.valuesJson?.student?.email || "";
                const studentName =
                    applicationsDetails?.valuesJson?.student?.name || "Student";
                    
                const reviewLink = `${DTS_URL}/authority-letter/${tenantId}/${body?.id}`;

                // Convert React component to static HTML
                const emailBodyHTML = ReactDOMServer.renderToStaticMarkup(
                    <EmailBody studentName={studentName} reviewLink={reviewLink} />
                );
                const data = {
                    // parentId: emailinfo.id,
                    studentId: applicationsDetails?.valuesJson?.student?.id,
                    applicationId: applicationsDetails?.id,
                    to: toemail,
                    subject: "Action Required: Review, Submit, and Generate PDF with Selfie Verification",
                    body: emailBodyHTML
                }
               const handleSuccess = () => {
                    const message = () =>
                        toast(`Email Sent Successfully`, {
                            position: "top-center",
                            hideProgressBar: true,
                            className: "bg-success text-white"
                        });
                    message();
                }
                const handleFailure = () => {}
            dispatch(SendEmail(data, handleSuccess, handleFailure))
            setDisabled(false)
         }
        const handleFailureAuth = () => { }
        dispatch(authorizationletterCreateRequest({...inputs, applicationId: applicationsDetails?.id}, handleSuccessAuth, handleFailureAuth))
    
    }

    useEffect(() => {
        if(applicationsDetails?.id) {  
            let countryname = applicationsDetails?.valuesJson?.student?.country_of_nationality || applicationsDetails?.valuesJson?.student?.nationality || applicationsDetails?.valuesJson?.student?.country_of_origin          
            let country = countryname ? SingleOptions.filter((selected: any) => selected?.value.toLowerCase() === countryname.toLowerCase()) : []
            setSelectedSingle(country)  
            let newInputs = {...inputs, id:applicationsDetails?.id, last_name: applicationsDetails?.valuesJson?.student?.last_name, first_name: applicationsDetails?.valuesJson?.student?.first_name, course_name: applicationsDetails?.valuesJson?.courses?.name,  date_of_birth: applicationsDetails?.valuesJson?.student?.date_of_birth, country_of_nationality: country[0]?.value, channel_name:{label: applicationsDetails?.valuesJson?.channel_name?.name, value: applicationsDetails?.valuesJson?.channel_name?.id}, createdAt: applicationsDetails?.createdAt, ucas_id:applicationsDetails?.valuesJson?.ucas_id }
            setInputs(newInputs)   
            setFormValues(newInputs)      
        }
    }, [applicationsDetails])


    const allMandatoryFieldsFilled = () => {
        const keys = inputs && typeof inputs === 'object' ? Object.keys(inputs) : [];
        if(keys.length){
            for (const field of keys) {
                if (!inputs[field]) {
                    return false;
                }
            }
            return true;
        }
        else return false
    };
    const onChange = (option: any, key: any) => {  
        if(isNaN(Date.parse(option)) === false) {
            setFormValues({...formValues, [key]: option})
            setInputs({...inputs, [key]: option})
        }
        else if (option?.value) {
            setFormValues({...formValues, [key]: option?.value})
            setInputs({...inputs, [key]: option?.value})
        }
        else {
            setFormValues({ ...formValues, [key]: option })
            setInputs({ ...inputs, [key]: option })
        }
    };

    
    return (
        <>{inputs?.id ?
        <Modal 
            isOpen={show}
            fullscreen
        >
            <ModalHeader className="p-3 bg-primary-subtle hstack justify-content-center">   
                <Label aria-disabled to="/#" className="d-inline-block auth-logo  hstack justify-content-center">
                    <img src={logo} alt="" height="50" />
                </Label>
            </ModalHeader>
            <ModalBody>
                <div>
                    <Button color="primary" onClick={onCloseClick} className="mt-4 ms-3">
                        Back
                    </Button>
                </div>
                <div id="authorization-request-form">
                    <div className="p-3 mt-1 d-flex justify-content-center">
                        <Col xl={8}>
                            <div>
                                <Row>
                                    <Label className="text-center mt-3 mb-5 fs-20">{applicationsDetails?.valuesJson?.channel_name?.name} Authorization Form</Label>
                                </Row>
                                <div className="mx-5 mb-2">
                                    <Table className="table-bordered align-middle mb-0">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "40%", whiteSpace: "nowrap" }}>First Name</td>
                                                <td style={{ width: "60%" }}>
                                                {applicationsDetails?.valuesJson?.student?.first_name ?
                                                    applicationsDetails.valuesJson?.student?.first_name
                                                    :
                                                        <FormBuilder 
                                                            type={"textfield"}
                                                            defaultValue={inputs?.first_name}
                                                            value={inputs?.first_name}
                                                            name={"first_name"}
                                                            placeholder={"Enter First Name"}
                                                            className="w-100 h-100"
                                                            onChange={(e: any) => onChange(e, 'first_name')}
                                                            isEditState={true}
                                                            // rest={rest}
                                                        />
                                                }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "40%", whiteSpace: "nowrap" }}>Last Name</td>
                                                <td style={{ width: "60%" }}>
                                                {applicationsDetails?.valuesJson?.student?.last_name ?
                                                    applicationsDetails.valuesJson?.student?.last_name
                                                    :
                                                    <FormBuilder 
                                                        type={"textfield"}
                                                        defaultValue={inputs?.last_name}
                                                        value={inputs?.last_name}
                                                        name={"last_name"}
                                                        placeholder={"Enter Last Name"}
                                                        className="w-100 h-100"
                                                        onChange={(e: any) => onChange(e, 'last_name')}
                                                        isEditState={true}
                                                        // rest={rest}
                                                    />
                                                }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "40%", whiteSpace: "nowrap" }}>Date of Birth:</td>
                                                <td style={{ width: "60%" }}>
                                                {applicationsDetails?.valuesJson?.student?.date_of_birth ?
                                                    moment(applicationsDetails.valuesJson?.student?.date_of_birth).format("LL")
                                                    :
                                                    <FormBuilder 
                                                        type={"datetime"}
                                                        defaultValue={inputs?.date_of_birth}
                                                        value={inputs?.date_of_birth}
                                                        name={"date_of_birth"}
                                                        placeholder={"Select Date of Birth"}
                                                        className="w-100 h-100"
                                                        onChange={(e: any) => onChange(e, 'date_of_birth')}
                                                        isEditState={true}
                                                        // rest={rest}
                                                    />
                                                }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "40%", whiteSpace: "nowrap" }}>Country of Nationality:</td>
                                                <td style={{ width: "60%" }}>
                                                    {applicationsDetails?.valuesJson?.student?.nationality ?
                                                    applicationsDetails?.valuesJson?.student?.nationality :
                                                    <Select
                                                        value={selectedSingle}
                                                        onChange={(selectedSingle: any) => {
                                                            handleSelectSingle(selectedSingle);
                                                            onChange(selectedSingle, 'country_of_nationality')
                                                        }}
                                                        options={SingleOptions}
                                                    />}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "40%", whiteSpace: "nowrap" }}>University application reference Number/UCAS ID</td>
                                                <td style={{ width: "60%" }}>
                                                            <FormBuilder 
                                                                type={"textfield"}
                                                                defaultValue={inputs?.ucas_id}
                                                                value={inputs?.ucas_id}
                                                                name={"ucas_id"}
                                                                placeholder={"Enter UCAS ID"}
                                                                className="w-100 h-100"
                                                                onChange={(e: any) => onChange(e, 'ucas_id')}
                                                                isEditState={true}
                                                                // rest={rest}
                                                            /></td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "40%", whiteSpace: "nowrap" }}>Course Applied</td>
                                                <td style={{ width: "60%" }}>{applicationsDetails?.valuesJson?.courses?.name ?
                                                    applicationsDetails?.valuesJson?.courses?.name :
                                                    ""
                                                }</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "40%", whiteSpace: "nowrap" }}>Date Application Submitted</td>
                                                <td style={{ width: "60%" }}>
                                                    {applicationsDetails?.createdAt ? moment(applicationsDetails?.createdAt).format("LL") : ""}
                                                </td>

                                            </tr>
                                            <tr>
                                                <td style={{ width: "40%", whiteSpace: "nowrap" }}>Your Agency</td>
                                                <td style={{ width: "60%" }}>
                                                    <FormBuilder 
                                                        type={"datasetselect"}
                                                        defaultValue={inputs?.channel_name?.value}
                                                        value={inputs?.channel_name?.value}
                                                        name={"channel_name"}
                                                        placeholder={"Select Agency"}
                                                        className="w-100 h-100"
                                                        onChange={(e: any) => onChange(e, 'channel_name')}
                                                        isEditState={true}
                                                        rest={{
                                                            "label": "Channel",
                                                            "key": "channelId",
                                                            "placeholder": "Select Channel",
                                                            "quickCreate": true,
                                                            "type": "datasetselect",
                                                            "model": "channels",
                                                            "optionLabel": "name",
                                                            "validate": {
                                                                "required": true
                                                            }
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "40%", whiteSpace: "nowrap" }}>Reason(s) for taking assistance:</td>
                                                <td style={{ width: "60%" }}>
                                                    {reason ? reason :
                                                         <FormBuilder 
                                                            type={"textarea"}
                                                            defaultValue={inputs?.reason ?  inputs?.reason :''}
                                                            value={inputs?.reason ?  inputs?.reason : ''}
                                                            name={"reason"}
                                                            placeholder={"Enter Reason(s) here..."}
                                                            className="w-100 h-100"
                                                            onChange={(e: any) => onChange(e, 'reason')}
                                                            isEditState={true}
                                                        />
                                                    }

                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="mx-5">                                    
                                    <Row className="mt-3 mb-5">
                                        <Label className="mb-1 fs-14">
                                            {/* {`Upon receipt of my completed form, I am aware and authorize the university to remove my current agent and change it in the student database to my proposed Agent (${applicationsDetails.valuesJson.channel_name?.name || "NA"}). Based on my previous Privacy Declaration, (as applicable) all future correspondence will be sent to ${applicationsDetails.valuesJson.channel_name?.name || "NA"}`} */}
                                            Upon receipt of my completed form, I authorize {inputs?.channel_name?.label} as my representative and
                                            based on my previous Privacy Declaration, (as applicable) all future correspondence
                                            will be sent to {inputs?.channel_name?.label}.
                                        </Label>

                                    </Row>
                                    <div className="d-flex justify-content-between align-items-baseline">
                                        <div>
                                            <Label>Date: {moment().format("LL")}</Label>
                                        </div>
                                        <div className="">
                                           <Button color="primary" onClick={() => onSend()} className="btn btn-sm" disabled={!allMandatoryFieldsFilled() || disabled}>
                                                Send to student
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
            </ModalBody>
        </Modal>
        : null
        }</>
    );
};

export default AuthorizationForm;
