export interface AgencychangeformsMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}
export interface Agencychangeforms {
  createdAt: string,
  createdBy: string,
  deletedAt: string,
  id: string,
  updatedAt: string,
  valuesJson: string
}

export interface Pagination {
  size: number,
  page: number,
  pages: number,
  total: number,
  elements: number,
  last: boolean
}

export enum AgencychangeformsActionTypes {
  GET_AGENCYCHANGEFORMMETADATA_REQUEST = "@@authorizationletter/GET_AGENCYCHANGEFORMMETADATA_REQUEST",
  GET_AGENCYCHANGEFORMMETADATA_SUCCESS = "@@authorizationletter/GET_AGENCYCHANGEFORMMETADATA_SUCCESS",
  GET_AGENCYCHANGEFORMMETADATA_ERROR = "@@authorizationletter/GET_AGENCYCHANGEFORMMETADATA_ERROR",
  GET_AGENCYCHANGEFORM_REQUEST = "@@authorizationletter/GET_AGENCYCHANGEFORM_REQUEST",
  GET_AGENCYCHANGEFORM_SUCCESS = "@@authorizationletter/GET_AGENCYCHANGEFORM_SUCCESS",
  GET_AGENCYCHANGEFORM_ERROR = "@@authorizationletter/GET_AGENCYCHANGEFORM_ERROR",
  GET_AGENCYCHANGEFORMS_REQUEST = "@@authorizationletter/GET_AGENCYCHANGEFORMS_REQUEST",
  GET_AGENCYCHANGEFORMS_SUCCESS = "@@authorizationletter/GET_AGENCYCHANGEFORMS_SUCCESS",
  GET_AGENCYCHANGEFORMS_ERROR = "@@authorizationletter/GET_AGENCYCHANGEFORMS_ERROR",
  PUT_AGENCYCHANGEFORM_REQUEST = "@@authorizationletter/PUT_AGENCYCHANGEFORM_REQUEST",
  PUT_AGENCYCHANGEFORM_SUCCESS = "@@authorizationletter/PUT_AGENCYCHANGEFORM_SUCCESS",
  PUT_AGENCYCHANGEFORM_ERROR = "@@authorizationletter/PUT_AGENCYCHANGEFORM_ERROR",
  DELETE_AGENCYCHANGEFORM_REQUEST = "@@authorizationletter/DELETE_AGENCYCHANGEFORM_REQUEST",
  DELETE_AGENCYCHANGEFORM_SUCCESS = "@@authorizationletter/DELETE_AGENCYCHANGEFORM_SUCCESS",
  DELETE_AGENCYCHANGEFORM_ERROR = "@@authorizationletter/DELETE_AGENCYCHANGEFORM_ERROR",
  POST_AGENCYCHANGEFORM_REQUEST = "@@authorizationletter/POST_AGENCYCHANGEFORM_REQUEST",
  POST_AGENCYCHANGEFORM_SUCCESS = "@@authorizationletter/POST_AGENCYCHANGEFORM_SUCCESS",
  POST_AGENCYCHANGEFORM_ERROR = "@@authorizationletter/POST_AGENCYCHANGEFORM_ERROR",
}

export interface AgencychangeformsState {
  readonly list: Agencychangeforms[] | null;
  readonly details: Agencychangeforms | null;
  readonly pagination: Pagination | null;
  readonly metaDataFields: AgencychangeformsMetadata[];
  readonly loading: boolean;
  readonly dataLoading: boolean;
  readonly metaLoading: boolean;
}
