import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { components } from 'react-select';
import { Button, Card, CardBody, CardHeader, Input, Label } from 'reactstrap';


const Territory = (props: any) => {  
    const { onAddRule, inputs, handleRemoveComponent } = props;
    const [bonusNote, setBonusNote] = useState([]) 


    useEffect(() => {
        setBonusNote(inputs?.bonus?.note)
    }, [inputs?.bonus])

    return (
        <React.Fragment>
            <Card className='border border-dashed border-end-0 border-start-0 vstack gap-2'>
                <CardHeader className='hstack bg-primary-subtle justify-content-between'>
                    <Label className='fw-bold fs-20 text-primary'>Bonus Rule</Label>
                    <button onClick={() => {props.setOpen(false); handleRemoveComponent('bonus')}} className="btn btn-sm btn-soft-danger remove-list fs-12"                                                                                >
                        <i className="ri-delete-bin-fill"></i>{" "}
                    </button>{" "}
                </CardHeader>
                <CardBody>   
                    <div>
                        <Label className='fw-bold fs-16 text-primary'>Note</Label>
                        <Input  rows={8} type={'textarea'} className="form-control ps-2" placeholder={'Enter Note'} value={bonusNote} onChange={(e: any) => setBonusNote(e.target.value)}
                            onKeyDown={(e: any) => {
                                if (e.key === 'Enter') {
                                e.preventDefault();
                                }
                            }}
                        />
                    </div>                
                    <div className='hstack justify-content-center p-2'>
                        <Button
                            onClick={() => onAddRule({note: bonusNote}, 'bonus')}
                            color="primary"
                            className="btn-primary">
                            {inputs?.bonus ? 'Update Rule' : 'Save Rule'}
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(Territory);
