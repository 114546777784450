import React, { useCallback, useState } from 'react';
import { Button, Card, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import ChannelFinanace from './ChannelFinanace';
import SubagentFinance from './SubagentFinance';
import { useNavigate } from 'react-router-dom';

const Details = (props: any) => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState<string>('channel');

    const toggleTab = (tab: any) => {
        if (activeTab !== tab) {
          setActiveTab(tab);
        }
      }
      
    return (
        <React.Fragment>            
            <Card>       
                <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === 'channel' }, "fw-bold cursor-pointer")}
                            onClick={() => { toggleTab('channel'); }}
                            href="#">
                            {'Channel'}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === 'subagent' }, "fw-bold cursor-pointer")}
                            onClick={() => { toggleTab('subagent'); }}
                            href="#">
                            {'Subagent'}
                        </NavLink>
                    </NavItem>
                </Nav>
                <Row>
                    <Col lg={12} md={12} xl={12} className='p-4'>
                        <TabContent activeTab={activeTab} className="text-muted">
                            <TabPane tabId="channel">
                                <ChannelFinanace />
                            </TabPane>
                            <TabPane tabId="subagent">
                                <SubagentFinance />
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(Details);