import {FinanceActionTypes, Finance} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { createQueryLink, responseHandler } from "utils";

const { api } = config;
const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const financeGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: FinanceActionTypes.GET_FINANCE_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/commissions/${id}`, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, FinanceActionTypes.GET_FINANCE_SUCCESS, FinanceActionTypes.GET_FINANCE_ERROR, null, null, null, "GET"); 
     
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const financeSubagentGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: FinanceActionTypes.GET_SUBAGENT_FINANCE_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/commissions/subagent/${id}`, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, FinanceActionTypes.GET_SUBAGENT_FINANCE_SUCCESS, FinanceActionTypes.GET_SUBAGENT_FINANCE_ERROR, null, null, null, "GET"); 
     
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};


export const financeDetailsGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: FinanceActionTypes.GET_FINANCE_DETAILS_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/commissions/${id}`, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, FinanceActionTypes.GET_FINANCE_DETAILS_SUCCESS, FinanceActionTypes.GET_FINANCE_DETAILS_ERROR, null, null, null, "GET"); 
     
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};


export const financeSubagentDetailsGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: FinanceActionTypes.GET_SUBAGENT_FINANCE_DETAILS_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/commissions/subagent/${id}`, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, FinanceActionTypes.GET_SUBAGENT_FINANCE_DETAILS_SUCCESS, FinanceActionTypes.GET_SUBAGENT_FINANCE_DETAILS_ERROR, null, null, null, "GET"); 
     
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const financeMetadataGetRequest: AppThunk = (filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: FinanceActionTypes.GET_FINANCEMETADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery =   createQueryLink(filterObject)    
        try {  
            const response = await fetch(searchQuery, {
                method: "GET",
                headers: headers
            });    
            await responseHandler(response, dispatch, FinanceActionTypes.GET_FINANCEMETADATA_SUCCESS, FinanceActionTypes.GET_FINANCEMETADATA_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const financeUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: FinanceActionTypes.PUT_FINANCE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/commissions/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );                         
        const error = {
            204: {
                "message": "Commission updated Successfully",
                "success": true
            },
            200: {
                "message": "Commission updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, FinanceActionTypes.PUT_FINANCE_SUCCESS, FinanceActionTypes.PUT_FINANCE_ERROR, error, handleSuccess, handleError);
    
    };
};


export const financeSubagentUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: FinanceActionTypes.PUT_FINANCE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/commissions/subagent/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );                         
        const error = {
            204: {
                "message": "Commission updated Successfully",
                "success": true
            },
            200: {
                "message": "Commission updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, FinanceActionTypes.PUT_FINANCE_SUCCESS, FinanceActionTypes.PUT_FINANCE_ERROR, error, handleSuccess, handleError);
    
    };
};

export const financeDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: FinanceActionTypes.DELETE_FINANCE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/commissions/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const error = {
                204: {
                    "message": "Commission deleted Successfully",
                    "success": true
                },
                200: {
                    "message": "Commission deleted Successfully",
                    "success": true
                }
            }  
            await responseHandler(response, dispatch, FinanceActionTypes.DELETE_FINANCE_SUCCESS, FinanceActionTypes.DELETE_FINANCE_ERROR, error, handleSuccess, handleError);
   
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: FinanceActionTypes.DELETE_FINANCE_ERROR
            });
            handleSuccess();
        }
    };
};


export const financeSubagentDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: FinanceActionTypes.DELETE_SUBAGENT_FINANCE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/commissions/subagent/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const error = {
                204: {
                    "message": "Commission deleted Successfully",
                    "success": true
                },
                200: {
                    "message": "Commission deleted Successfully",
                    "success": true
                }
            }  
            await responseHandler(response, dispatch, FinanceActionTypes.DELETE_SUBAGENT_FINANCE_SUCCESS, FinanceActionTypes.DELETE_SUBAGENT_FINANCE_ERROR, error, handleSuccess, handleError);
   
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: FinanceActionTypes.DELETE_SUBAGENT_FINANCE_ERROR
            });
            handleSuccess();
        }
    };
};

const financechannelcreateQueryLink = (id: any, filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/commissions/channel/${id}?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const financeChannelListGetRequest: AppThunk = (id,filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: FinanceActionTypes.GET_CHANNEL_FINANCES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = financechannelcreateQueryLink(id,filterObject,pageno);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: FinanceActionTypes.GET_CHANNEL_FINANCES_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: FinanceActionTypes.GET_CHANNEL_FINANCES_SUCCESS,
                payload: body_1
            });
        }
    };
};


const financesubagentcreateQueryLink = (id: any, filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/commissions/university/subagent/${id}?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const financeSubagentListGetRequest: AppThunk = (id,filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: FinanceActionTypes.GET_SUBAGENT_FINANCES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = financesubagentcreateQueryLink(id,filterObject,pageno);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: FinanceActionTypes.GET_SUBAGENT_FINANCES_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: FinanceActionTypes.GET_SUBAGENT_FINANCES_SUCCESS,
                payload: body_1
            });
        }
    };
};


const financesubagentcreate = (id: any, filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/commissions/subagents/${id}?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const financeSubagentCommissionsGetRequest: AppThunk = (id,filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: FinanceActionTypes.GET_SUBAGENT_FINANCES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = financesubagentcreate(id,filterObject,pageno);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: FinanceActionTypes.GET_SUBAGENT_FINANCES_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: FinanceActionTypes.GET_SUBAGENT_FINANCES_SUCCESS,
                payload: body_1
            });
        }
    };
};


const financeuniversitycreateQueryLink = (id: any, filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/commissions/university/${id}?&subagent=`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const financeUniversityListGetRequest: AppThunk = (id,filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: FinanceActionTypes.GET_UNIVERSITY_FINANCES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = financeuniversitycreateQueryLink(id,filterObject,pageno);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: FinanceActionTypes.GET_UNIVERSITY_FINANCES_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: FinanceActionTypes.GET_UNIVERSITY_FINANCES_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const financeCreateRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: FinanceActionTypes.POST_FINANCE_REQUEST
        });
        try {
            const response = await fetch(
                `${DTS_API_STUDENT}/commissions`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "token": token
                    },
                    body: JSON.stringify(data)
                }
            );
            const error = {
                208: {
                    "message": "Commission already exist",
                    "success": false
                },
                201: {
                    "message": "Commission added Successfully",
                    "success": true
                },                
                200: {
                    "message": "Commission added Successfully",
                    "success": true
                }
            }
            await responseHandler(response, dispatch, FinanceActionTypes.POST_FINANCE_SUCCESS, FinanceActionTypes.POST_FINANCE_ERROR, error, handleSuccess, handleError);

        } catch (error) {
            dispatch({ type: FinanceActionTypes.POST_FINANCE_ERROR, payload: error });
            handleError();
        }
    };
};

export const financeSubagentCreateRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: FinanceActionTypes.POST_FINANCE_REQUEST
        });
        try {
            const response = await fetch(
                `${DTS_API_STUDENT}/commissions/subagent`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "token": token
                    },
                    body: JSON.stringify(data)
                }
            );
            const error = {
                208: {
                    "message": "Commission already exist",
                    "success": false
                },
                201: {
                    "message": "Commission added Successfully",
                    "success": true
                },                
                200: {
                    "message": "Commission added Successfully",
                    "success": true
                }
            }
            await responseHandler(response, dispatch, FinanceActionTypes.POST_FINANCE_SUCCESS, FinanceActionTypes.POST_FINANCE_ERROR, error, handleSuccess, handleError);

        } catch (error) {
            dispatch({ type: FinanceActionTypes.POST_FINANCE_ERROR, payload: error });
            handleError();
        }
    };
};

export const downloadFinanceBulkTemplate: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: FinanceActionTypes.DOWNLOAD_FINANCE_BULKENTRY_TEMPALTE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Properties-Model', 'commissions');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/properties/download`, {
            method: "GET",
            headers: headers,
        });
        try {
            const body = await response
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: FinanceActionTypes.DOWNLOAD_FINANCE_BULKENTRY_TEMPALTE_ERROR
                });
                handleError();
            } else {
                dispatch({
                    type: FinanceActionTypes.DOWNLOAD_FINANCE_BULKENTRY_TEMPALTE_SUCCESS,
                    payload: []
                });
                handleSuccess(body_1);
            }
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: FinanceActionTypes.DOWNLOAD_FINANCE_BULKENTRY_TEMPALTE_ERROR
            });
            handleSuccess();
        }
    };
};


export const setCommissionFields: AppThunk = (fields: any) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({
            type: FinanceActionTypes.SET_COMMISSION_FIELDS_REQUEST,
            payload: fields
        });
    };
};

export const getCommissionFields: AppThunk = () => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({
            type: FinanceActionTypes.GET_COMMISSION_FIELDS_REQUEST
        });  
        dispatch({
            type: FinanceActionTypes.GET_COMMISSION_FIELDS_SUCCESS,
        });
    };
};

const financerulescreateQueryLink = (filterObject: any, page: any, subagent: any) => {
    let query = `${DTS_API_STUDENT}/commissions/rules?subagentId=${subagent}&`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const financeRulesListGetRequest: AppThunk = (filterObject,pageno, subagent) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: FinanceActionTypes.GET_FINANACE_RULES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = financerulescreateQueryLink(filterObject,pageno, subagent);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: FinanceActionTypes.GET_FINANACE_RULES_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: FinanceActionTypes.GET_FINANACE_RULES_SUCCESS,
                payload: body_1
            });
        }
    };
};


const financesubagentrulescreateQueryLink = (filterObject: any, page: any, channel: any) => {
    let query = `${DTS_API_STUDENT}/commissions/rules?channelId=&`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const financeSubagentRulesListGetRequest: AppThunk = (filterObject,pageno, channel) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: FinanceActionTypes.GET_FINANACE_RULES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = financesubagentrulescreateQueryLink(filterObject,pageno, channel);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: FinanceActionTypes.GET_FINANACE_RULES_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: FinanceActionTypes.GET_FINANACE_RULES_SUCCESS,
                payload: body_1
            });
        }
    };
};




