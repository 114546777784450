import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
} from "reactstrap";
import { ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import QuickForm from "Components/Common/FormBuilder/QuickForm";
import { financeUniversityListGetRequest, financeCreateRequest } from "store/finance/action";
import { useParams } from "react-router";
const AddEntry = ({ props, show, onCloseClick, dataFields }: any) => {   
    const { id } = useParams(); 
    const [disabled, setDisabled] = useState<any>(false)
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  
    const OnAddEntry = (formValues: any) => {
        setDisabled(true)
        let data = {
            "valuesJson": formValues,
            "instituteId": id,
          }
       
        const handleSuccess = (body: any) => {
            onCloseClick()
            dispatch(financeUniversityListGetRequest(id));
            setDisabled(false)
        }
        const handleError = () => {
        }
        dispatch(financeCreateRequest(data, handleSuccess ,handleError))
    }

    return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onCloseClick}
            backdrop={false}
        >
            
            <OffcanvasHeader className="bg-light" toggle={() =>{onCloseClick(!show)}}>
                {props.t("student.add_new_entry")}
            </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <Card>
                        <CardBody>
                            <div className="live-preview vstack gap-2">
                                <QuickForm dataFields={dataFields} btnAction={OnAddEntry} btn_text={'Add Entry'}  btndisabled={disabled}/>
                            </div>
                        </CardBody>
                    </Card>                    
                </OffcanvasBody>
        </Offcanvas>
    );
};

export default AddEntry;

