import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Input, Label } from 'reactstrap';

interface Options {
    label: string;
    value: string;
}

interface Permission {
    name: string;
    options?: Options[];
    value: string; // or the appropriate type
}

interface Feature {
    description: string;
    editable: boolean;
    name: string;
    value: boolean; // or the appropriate type
    type: string;
    permissions: Permission[];
    options?: Options[];
}

interface SubItem {
    name: string;
    features: Feature[];
}

interface MenuItem {
    name: string;
    items: SubItem[];
}

interface CreateInputs {
    role: string;
}

interface RolesProps extends WithTranslation {
    setCreateInputs: React.Dispatch<React.SetStateAction<CreateInputs>>;
    createInputs: any;
    record: any;
    nextClicked: () => void;
}

const Roles: React.FC<RolesProps> = ({ setCreateInputs, createInputs, t, record, nextClicked }) => {
    return (
        <div className="w-100">
            <Label className="form-label fw-bold fs-20 hstack justify-content-center" htmlFor="password-input">
                {createInputs?.role}
            </Label>
            <p className="mb-4 mt-4 fw-medium hstack justify-content-center">
                {t('roles.role_name_edit_description')} 
            </p>
            <div className='hstack gap-2'>
                <Input
                    type="text"
                    className="form-control w-100"
                    id="steparrow-gen-info-username-input"
                    placeholder={t('roles.enter_role_name')}
                    value={createInputs.role}
                    onChange={(e) => setCreateInputs({ ...createInputs, role: e.target.value })}
                />
                <Button color="primary" className="btn-icon" onClick={nextClicked}> <i className="ri-arrow-right-line" /> </Button>
            </div>
        </div>
    );
};

export default withTranslation()(Roles);
