
const periods = [{
    label: "Today vs. Yesterday",
    value: "DAY",
    current: "Today",
    previous: "Yesterday",
}, {
    label: "This Week vs. Last Week",
    value: "WEEK",
    current: "This Week",
    previous: "Last Week",
}, {
    label: "This Month vs. Last Month",
    value: "MONTH",
    current: "This Month",
    previous: "Last Month",
}, {
    label: "This Quarter vs. Last Quarter",
    value: "QUARTER",
    current: "This Quarter",
    previous: "Last Quarter",
}, {
    label: "This Year vs. Last Year",
    value: "YEAR",
    current: "This Year",
    previous: "Last Year",
}] 

export { periods };