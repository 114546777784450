import {CompanyActionTypes, Company} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { createQueryLink, responseHandler } from "utils";
const { api } = config;
const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const companyGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: CompanyActionTypes.GET_COMPANY_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/models/companies/${id}`, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, CompanyActionTypes.GET_COMPANY_SUCCESS, CompanyActionTypes.GET_COMPANY_ERROR, null, null, null, "GET");   
            
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const companiesMetadataGetRequest: AppThunk = (model, filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CompanyActionTypes.GET_COMPANYMETADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        headers.append('Properties-Model', model); 
        const searchQuery =   createQueryLink(filterObject)    
        try {  
            const response = await fetch(searchQuery, {
                method: "GET",
                headers: headers
            });    
            await responseHandler(response, dispatch, CompanyActionTypes.GET_COMPANYMETADATA_SUCCESS, CompanyActionTypes.GET_COMPANYMETADATA_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const companyUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CompanyActionTypes.PUT_COMPANY_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/models/companies/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );                      
        const error = {
            204: {
                "message": "Company updated Successfully",
                "success": true
            },
            200: {
                "message": "Company updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, CompanyActionTypes.PUT_COMPANY_SUCCESS, CompanyActionTypes.PUT_COMPANY_ERROR, error, handleSuccess, handleError);
    };
};

export const companyDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CompanyActionTypes.DELETE_COMPANY_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/models/companies/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const error = {
                204: {
                    "message": "Company deleted Successfully",
                    "success": true
                },
                200: {
                    "message": "Company deleted Successfully",
                    "success": true
                }
            }  
            await responseHandler(response, dispatch, CompanyActionTypes.DELETE_COMPANY_SUCCESS, CompanyActionTypes.DELETE_COMPANY_ERROR, error, handleSuccess, handleError);
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: CompanyActionTypes.DELETE_COMPANY_ERROR
            });
            handleSuccess();
        }
    };
};

export const companyListGetRequest: AppThunk = (filters,pageno, pageSize, signal) => {
    let requestbody = filters ? filters : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CompanyActionTypes.GET_COMPANIES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }              
        const response = await fetch(`${DTS_API_STUDENT}/models/get/companies?page=${pageno?pageno:0}&pageSize=${pageSize?pageSize:10}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestbody),
            signal
        }).catch((error) => {
            return false;
        });
        if(typeof response === 'object' && !Array.isArray(response) && response !== null) {
            const body = await response.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: CompanyActionTypes.GET_COMPANIES_ERROR,
                    payload: body_1
                });
            } else {
                dispatch({
                    type: CompanyActionTypes.GET_COMPANIES_SUCCESS,
                    payload: body_1
                });
            }
        }
    };
};

export const getCompanyBanksList: AppThunk = (id, filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CompanyActionTypes.GET_COMPANY_BANKS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }                
        const response = await fetch(`${DTS_API_STUDENT}/banks/company/${id}/`, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CompanyActionTypes.GET_COMPANY_BANKS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: CompanyActionTypes.GET_COMPANY_BANKS_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const companyCreateRequest: AppThunk = (data, handleSuccess, handleError) => {
  return async (dispatch: Dispatch, getState: any): Promise<void> => {
    const { auth: { token } } = getState();
    dispatch({ type: CompanyActionTypes.POST_COMPANY_REQUEST });
    try {
      const response = await fetch(
        `${DTS_API_STUDENT}/models/companies`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "token": token
          },
          body: JSON.stringify(data)
        }
      );
      const error = {
        208: {
            "message": "Company already exist",
            "success": false
        },
        201: {
            "message": "Company created Successfully",
            "success": true
        },        
        200: {
            "message": "Company created Successfully",
            "success": true
        }
    }
    await responseHandler(response, dispatch, CompanyActionTypes.POST_COMPANY_SUCCESS, CompanyActionTypes.POST_COMPANY_ERROR, error, handleSuccess, handleError);

    } catch (error) {
        dispatch({ type: CompanyActionTypes.POST_COMPANY_ERROR, payload: error });
        handleError();
    }
  };
};




