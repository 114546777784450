import React, { useEffect, useState } from 'react';
import { Container, Input, Label } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { startCase } from 'lodash';
import { courseGetRequest, courseUpdateRequest } from 'store/course/action';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import UpdateForm from 'Components/Common/FormBuilder/UpdateForm';

const Details = (props: any) => {
    const { id, childId} = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const details: any= useSelector((state: ApplicationState) => state.courses.details);
    const [fields, setFields] = useState([])
    const [value, setValue]= useState(false)
    const [count, setCount] = useState(0)
    const [data, setData] = useState({})

    useEffect(() => {
        dispatch(courseGetRequest(childId || id))
    }, [])

    useEffect(() => {
        let fields: any=[]
        if(details?.valuesJson?.course_duration && details?.valuesJson?.currency && details?.valuesJson?.course_duration_period) { 
            let count = parseInt(details?.valuesJson?.course_duration) || 0;
            [...Array(count)].map((_, index) => {
                let ConditionalDefaultValue: any = null;
                let period = details?.valuesJson?.course_duration_period ? `${details?.valuesJson?.course_duration_period}`.replace("s", "").toLowerCase() : null;
              
                if(index === 0) {
                    ConditionalDefaultValue = {
                        "isDefaultValue": true,
                        "values": `${details?.valuesJson?.course_fee}${details?.valuesJson?.currency}`
                    }  
                    let data = {[`course_fee`]: details?.valuesJson?.course_fee}                  
                    setData(data)
                }
                let field = {
                    "label": period ?  startCase(`${period} ${index+1}`) : null,
                    "key": index === 0 ? `course_fee` : `course_fee_${period}${index+1}`,
                    "placeholder": `Enter ${details?.valuesJson?.course_duration_period} ${index+1} Fee`,
                    "quickCreate": true,
                    "type": "textfield",
                    "ConditionalDefaultValue": ConditionalDefaultValue
                }
                let newField = JSON.stringify(field);
                let JsonnewField = {valuesJson: newField };
                fields.push(JsonnewField)
            })
            setCount(count)
        }
        setFields(fields)
    }, [details])

    const onUpdate = (formValues: any) => {
        const handleSuccess = (body: any) => {
            dispatch(courseGetRequest(childId || id))
        }
        dispatch(courseUpdateRequest(childId || id, formValues, handleSuccess, () => { }))
    }

    return (
        <React.Fragment>            
            <Container fluid>  
                {
                    count > 0 ?
                    <div>                        
                        <div className='py-2 px-4'>
                            <Input
                                className={"form-check-input"}
                                type={"checkbox"}
                                checked={value}
                                onChange={(e:any) => setValue(e.target.checked)}
                            />
                            <Label className="form-label fs-15 fw-semibold">Is the fee different for every year?</Label>
                        </div> 
                        {
                            value ?
                            <div>
                                <div> 
                                    <span>Note:</span>
                                    <ol>
                                    <li>Please Enter respective year fee in respective year.
                                    </li>    
                                    <li>Please enter only number values.
                                    </li>   
                                    <li>Please enter values in currency {`${details?.valuesJson?.currency}`}.
                                    </li>    
                                    </ol>                             
                                    {/* <span>{`Please Enter Fee for each year.Please enter only number values.(Enter currency: ${details?.valuesJson?.currency})`}</span> */}
                                    </div> 
                                    <UpdateForm BtnClassName={"w-auto btn-primary"} md={6} lg={6} sm={12} xl={6} xs={12} dataFields={fields} onUpdate={(values: any) => onUpdate(values)} btn_text={'Update'} disabled={false} theme={"primary"} data={data} />
                                </div>
                            : null
                        }
                    </div>
                    : null
                }
            </Container>
        </React.Fragment>
    );
};

export default withTranslation()(Details);