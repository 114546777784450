import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { Table, Row } from "reactstrap";
import { DefaultColumnFilter } from "../../Components/Common/filters";
import { Link, useNavigate } from "react-router-dom";
import Paginator from "Components/Common/Paginator";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
import { checkTheyOwn } from "utils";

interface TableContainerProps {
    columns?: any;
    data?: any;
    isGlobalFilter?: any;
    isAddOptions?: any;
    divClassName?: any;
    tableClassName?: any;
    theadClassName?: any,
    tableClass?: any;
    theadClass?: any;
    isBordered?: boolean;
    isAddUserList?: any;
    handleOrderClicks?: any;
    handleUserClick?: any;
    handleCustomerClick?: any;
    isAddCustList?: any;
    customPageSize?: any;
    className?: any;
    customPageSizeOptions?: any;
    iscustomPageSize?: boolean;
    isProductsFilter?: boolean;
    isPageSizeChange?: any;
    isCustomerFilter?: any;
    isOrderFilter?: any;
    isContactsFilter?: any;
    isCompaniesFilter?: any;
    isLeadsFilter?: any;
    isCryptoOrdersFilter?: any;
    isInvoiceListFilter?: any;
    isTicketsListFilter?: any;
    isNFTRankingFilter?: any;
    isTaskListFilter?: any;
    trClass?: any;
    thClass?: any;
    divClass?: any;
    handleEditClick: (e: any) => void,
    handleLeadClick: (e: any) => void,
    setisDeleteOpen: (e: any) => void,
    pageChanged: (e: any) => void,
    pagination: any;
    pageSize: any;
    setpreview: (e: any) => void;
    setPagination: (e: any) => void;
    onChangePageSize: (e: any) => void;
    handleSort: (e: any) => void;
    sortBy:string;
    sortOrder:string;
    props: any;
    access: any;
    loading: any;
}

const TableContainer = ({
    columns,
    data,
    isPageSizeChange,
    isGlobalFilter,
    isProductsFilter,
    isCustomerFilter,
    isOrderFilter,
    isContactsFilter,
    isCompaniesFilter,
    isLeadsFilter,
    isCryptoOrdersFilter,
    isInvoiceListFilter,
    isTicketsListFilter,
    isNFTRankingFilter,
    isTaskListFilter,
    isAddOptions,
    isAddUserList,
    handleOrderClicks,
    handleUserClick,
    handleCustomerClick,
    isAddCustList,
    customPageSize,
    tableClass,
    theadClass,
    trClass,
    thClass,
    divClass,
    handleEditClick,
    handleLeadClick,
    setisDeleteOpen,
    pageChanged,
    pagination,
    onChangePageSize,
    setpreview,
    handleSort,
    sortOrder,
    sortBy,
    pageSize,
    props,
    access,
    loading
}: TableContainerProps) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        // preGlobalFilteredRows,
        setGlobalFilter,
        // state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: 0, pageSize: customPageSize, selectedRowIds: 0, sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );
    const navigate = useNavigate();
    
    const navigateDetails = (id: any) => {
        navigate(`/companies/${id}`)
    }

    const onChangeInSelect = (event: any) => {
        setPageSize(Number(event.target.value));
        onChangePageSize(event.target.value);
    };
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    
    const [view, setView] = useState(false);
    const [edit, setEdit] = useState<any>(null);
    const [deleteaccess, setDeleteAccess] = useState<any>(null);

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setView(true)
            setEdit("all")
            setDeleteAccess("all")
        }
        else if (access && access.length) {
            access.map((item: any, index: any) => {
                if (item.name) {
                    switch (item.name) {
                        case "View": item.value == "" || item.value == "none" ? setView(false) : setView(true)
                            break
                        case "Edit": item.value == "" || item.value == "none" ? setEdit(null) : setEdit(item.value)
                            break
                        case "Delete": item.value == "" || item.value == "none" ? setDeleteAccess(null) : setDeleteAccess(item.value)
                            break
                    }
                }

            })
        }
        else {
            setView(false)
            setEdit(false)
            setDeleteAccess(false)
        }
    }, [access, userProfile])

    return (
        <div>
        {loading ?
            <DtsTablePlaceholder 
                columns={columns} 
                theadClass={theadClass}     
                tableClass="align-middle table-nowrap"
                thClass="border-bottom-1 table-soft-primary"
            />
            : 
        <div>{page && page.length ? 
            <Table responsive={true} hover {...getTableProps()} className={tableClass + ' m-0'}>
                <thead className={theadClass}>
                    {headerGroups.map((headerGroup: any) => (
                        <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th key={column.id} className={thClass + " fw-bold"}
                                    {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}
                                >
                                    <div onClick={() => handleSort(column.id)}  className={"cursor-pointer" + `${column.accessor == 'channel_name' ? '': ' otherth'}`}  title={column.Header}>
                                        {column.render("Header")}
                                        {sortBy === column.id && (
                                            <span>
                                                {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                                            </span>
                                        )}
                                    </div>   
                                </th>
                            ))}
                            <th className={thClass + " fw-bold"} >
                                Actions
                            </th>
                        </tr>
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {page.map((row: any) => {
                        prepareRow(row);
                        let they_own = checkTheyOwn(row.original, userProfile?.id)
                        return (
                            <Fragment key={row.getRowProps().key}>
                                <tr style={{verticalAlign: 'middle'}}>
                                    {row.cells.map((cell: any) => {
                                        let classList = cell.column.className;
                                        return (
                                            <td key={cell.id} style={{verticalAlign: 'middle'}} className='align-items-center justify-content-between' {...cell.getCellProps()}>
                                                    <Link to={""}className="text-decoration-none"  style={{width:"230px"}} >{cell.render("Cell")}</Link>
                                            </td>
                                        );
                                    })}
                                    <td>
                                        
                                    <ul className="list-inline hstack gap-2 mb-0">
                                    {edit && (edit?.toLowerCase() === "all" || (edit?.toLowerCase() === "they_own" && they_own)) ?
                                            <li className="list-inline-item edit" title="View">
                                                <button className="btn btn-sm btn-soft-secondary remove-list" onClick={() => { const LeadData = row.original; handleLeadClick(LeadData); setpreview(true); }}
                                                >
                                                    <i className="ri-pencil-fill"></i>{" "}
                                                </button>{" "}
                                            </li>
                                         : null }
                                            {deleteaccess && (deleteaccess?.toLowerCase() === "all" || (deleteaccess?.toLowerCase() === "they_own" && they_own)) ?
                                            <li className="list-inline-item edit" title="Delete">
                                                <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => { const LeadData = row.original; handleLeadClick(LeadData); setisDeleteOpen(true); setpreview(false); }}
                                                >
                                                    <i className="ri-delete-bin-fill"></i>{" "}
                                                </button>{" "}
                                            </li>
                                         : null }
                                        </ul>
                                    </td>
                                </tr>
                            </Fragment>
                        );
                    })}
                </tbody>
            </Table>: <div className="hstack p-5 fw-semibold fs-16 justify-content-center w-100"><span className="hstack p-5  justify-content-center  fw-semibold fs-16 text-center w-100">No data Found</span></div>}
            <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                <div className="col-sm">
                    <div className="text-muted">
                        {props.t("applications.showing")}
                        <span className="fw-semibold ms-1">
                        {data.length > 0 ? `${pagination.offset + 1}-${Math.min(pagination.offset + pagination.size, pagination.elements)} of ${pagination.elements} results` : `0-0 of 0 results`}
                        </span>
                    </div>
                </div>          
                <div className="col-sm-auto">
                    <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                        currentPage={pagination?.page}
                        pageChanged={(e:any) => pageChanged(e)}
                        pageSize={pageSize} 
                        isPageSizeChange={isPageSizeChange}
                        onChangeInSelect={(e:any) => onChangeInSelect(e)}
                        pagination={{...pagination, size: pageSize}}
                        props={props}
                     />
                </div>
            </Row>
        </div>}
        </div>
    );
};

TableContainer.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;