import React, { useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ViewsTab from 'Components/Common/Views/ViewsTab';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { Action } from 'redux';
import Application from './Applications';
import { downloadApplicationsBulkTemplate } from 'store/documents/action';
import { applicationsMetadataGetRequest } from 'store/applications/action';

const Applications = (props: any) => {    
    const metaData = useSelector((state: ApplicationState) => state.applications.metaDataFields);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();

    useEffect(() => {
        const filters = { pageSize: 500 }
        dispatch(applicationsMetadataGetRequest("applications", filters));
    }, [])
    
    document.title="DTS | Zilter";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                           
                    <Row>
                        <ViewsTab 
                            component={Application}
                            metadata={metaData}
                            model={"applications"}
                            title={props.t("applications.applications")} 
                            isBulkUpload={true}
                            downloadBulkTemplate={downloadApplicationsBulkTemplate}
                        />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Applications);

