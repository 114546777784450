import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, Col, Label, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import Select from "react-select";
import { downloadBulkUploadTemplate } from 'store/documents/action';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';

interface FProps {
    setselectedFiles: (event: any) => void;
    selectedFiles: any;
    setImportJson: (event: any) => void;
    importJson: any;
}
const Upload: React.FC<FProps> = ({selectedFiles, setselectedFiles, setImportJson, importJson} : any) => {    
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [importType, setImportType] = useState({})
    const formatBytes = (bytes: any, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const handleAcceptedFiles = (files: any) => {
        files.map((file: any) => {
            let extension = file.name.split(".")
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
                uuid: `${uuidv4()}.${extension[extension.length-1]}`
            })
        });
        setselectedFiles(files);
    }

    const onClickFileDownload = () => {
        const handleSuccess = async (body: any) =>{

            try {        
                // Create a Blob from the response data
                const fileBlob = await body.blob();
        
                // Create a temporary URL for the Blob
                const url = window.URL.createObjectURL(fileBlob);
        
                // Create a temporary <a> element to trigger the download
                const tempLink = document.createElement("a");
                tempLink.href = url;
                tempLink.setAttribute("download", importJson.model); // Set the desired filename for the downloaded file
        
                // Append the <a> element to the body and click it to trigger the download
                document.body.appendChild(tempLink);
                tempLink.click();
        
                // Clean up the temporary elements and URL
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("Error downloading file:", error);
            }
        }
        const handleFailure = () =>{
            
        }
        dispatch(downloadBulkUploadTemplate(importJson.model, handleSuccess, handleFailure))
    }
    
    const options = [
        {
            value:'create-update',
            label:'Create and Update',   
        },
        {
            value:'create',
            label:`Create new ${importJson?.model} only`,   
        },
        {
            value:'update',
            label:`Update existing ${importJson?.model} only`,   
        }
    ]

    const handleselected = (option: any) => {
        setImportType(option)
        setImportJson({...importJson, importAction: option.value})
    }
    useEffect(() => {
        setImportJson({...importJson, importAction: 'create'})
    },[])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleAcceptedFiles,
        maxSize: 10 * 1024 * 1024, // 10 MB
        accept: {
            'text/csv': ['.csv'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-excel': ['.xls'],
        },
    });

    useEffect(() => {        
        setImportType({
            value:'create',
            label:`Create new ${importJson?.model} only`,   
        })

    }, [importJson?.model])

    return (
        <div>
            <div className='mt-3'>               
            <div className="mt-3">
                <div {...getRootProps()} className="dropzone dz-clickable cursor-pointer">
                    <div className="dz-message needsclick pt-4">
                        <div className="mb-3">
                            <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                        </div>
                        <h4>
                            {isDragActive ? 'Drop the file here ...' : 'Drop files here or click to upload.'}
                        </h4>
                    </div>
                    <input {...getInputProps()} />
                </div>
            </div>

            </div>   
            <div className='hstack justify-content-end px-2'>
                <Link to={''} className='fs-16 fw-semibold' onClick={() => onClickFileDownload()}>Download sample File</Link>
            </div>
            <div className="list-unstyled mb-0" id="file-previews">
                {selectedFiles.map((f: any, i: any) => {
                    return (
                        <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                        >
                            <div className="p-2">
                                <Row className="me-1 text-nowrap text-truncate text-nowrap-ellipsis text-muted align-items-center">
                                 
                                    <Col>
                                        <Link
                                            to="#"
                                            className="text-decoration-none text-ellipsis text-muted font-weight-bold"
                                        >
                                            File uploaded, and renamed {f.uuid}
                                        </Link>
                                        <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    );
                })}
            </div>
            <div className='pt-2'>
                <Label className='fs-16 fw-bold'>Choose how to import</Label>
                <Select
                    className="fs-16 fw-medium"
                    defaultOptions
                    placeholder={"Select..."}
                    value={importType}
                    onChange={(option : any) => handleselected(option)}
                    options={options}
                    name="choices-single-default"
                ></Select>
            </div>
        </div> 
    )
}

export default Upload
