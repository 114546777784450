import { useEffect, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";

const ArchiveModal = ({ props, show, onCloseClick, record, onUpdate }: any) => {
    const [groupName, setGroupName] = useState('')
    useEffect(() => {
        if (record && record.id) setGroupName(record.groupTitle)
    }, [record])
    return (
        <Modal backdrop={"static"} id="myModal" isOpen={show} toggle={() => { onCloseClick(); }} centered>
            <ModalHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" id="myModalLabel" toggle={() => { onCloseClick(); }}>
                <span className="m-0 me-2 text-white">{props.t("properties.update_a_new_property_group")}</span>
            </ModalHeader>
            <ModalBody className='text-center p-5'>
                <div className="mt-1">
                    <p className="text-muted fs-15 mb-4">{props.t("properties.update_group_disclaimer")}</p>
                    <Input
                        type="text"
                        size={14}
                        className="search"
                        placeholder="Enter Group Name"
                        onChange={(e) => setGroupName(e.target.value)}
                        value={groupName}
                    />
                    <div className="hstack gap-2 justify-content-center mt-3">
                        <Button className="btn btn-primary" onClick={() => onUpdate(groupName)} disabled={!groupName}>
                            {props.t("properties.save")}
                        </Button>
                        <Button color="danger" onClick={() => onCloseClick()} outline>
                            {props.t("properties.cancel")}
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    ) as unknown as JSX.Element;
};

export default ArchiveModal;