import { useEffect } from "react";
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import moment from "moment";
import { useParams } from "react-router-dom";
import { whatsappsGetRequest } from 'store/whatsapp/action';
import { isJson } from "utils";


const Whatsapp = (props: any) => {
    const { id, model, details } = props;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const user = useSelector((state: ApplicationState) => state.auth.userProfile);
    const whatsapps = useSelector((state: ApplicationState) => state.whatsapp.whatsapps);
    useEffect(() => {
        dispatch(whatsappsGetRequest(id))
        if(model == "students") {
            if (details && details.id) {
                dispatch(whatsappsGetRequest(id))
            }
        }
        else {
            if (details && details.id) {
                let studentsId = details?.valuesJson?.student?.id;
                dispatch(whatsappsGetRequest(studentsId, {applicationId: details.id}))
            }
        }   
    }, [])
    return (
        <div className="dts-whatsapp">
            <div className="row container d-flex justify-content-center">
                <div className="col-md-12">
                    <div className="direct-chat direct-chat-warning position-relative">
                        <div className="box-body">
                            <div className="direct-chat-messages">
                                {
                                    whatsapps && whatsapps.length ? whatsapps.map((whatsapp: any, messageIndex: number) => {
                                        const message = isJson(whatsapp.whats_app_json)
                                        if (id !== whatsapp.from.id) {
                                            if(message.type === 'chat') {
                                                return (
                                                    <div key={messageIndex} className="direct-chat-msg right">
                                                        <div className="direct-chat-info clearfix">
                                                            <span className="direct-chat-name pull-right">{whatsapp.from.name}</span>
                                                            <span className="direct-chat-timestamp pull-left">{moment.unix(message.timestamp).format("MMM Do, h:mm a")}</span>
                                                        </div>
                                                        <img className="direct-chat-img" src="https://img.icons8.com/office/36/000000/person-female.png" alt="message user" />
                                                        <div className="direct-chat-text">{message.body}</div>
                                                    </div>
                                                )
                                            }
                                        } else {
                                            if(message.type === 'chat') {
                                                return (
                                                        <div key={messageIndex} className="direct-chat-msg">
                                                            <div className="direct-chat-info clearfix">
                                                                <span className="direct-chat-name pull-left">{whatsapp.from.name}</span>
                                                                <span className="direct-chat-timestamp pull-right">{moment.unix(message.timestamp).format("MMM Do, h:mm a")}</span>
                                                            </div>
                                                            <img className="direct-chat-img" src="https://img.icons8.com/color/36/000000/administrator-male.png" alt="message user" />
                                                            <div className="direct-chat-text">{message.body}</div>
                                                        </div>
                                                )
                                            }
                                        }
                                        return null;
                                    }) : 
                                    <h6>No Messages</h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Whatsapp;