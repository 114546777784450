export interface AuthorizationlettersMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}
export interface Authorizationletters {
  createdAt: string,
  createdBy: string,
  deletedAt: string,
  id: string,
  updatedAt: string,
  valuesJson: string
}

export interface Pagination {
  size: number,
  page: number,
  pages: number,
  total: number,
  elements: number,
  last: boolean
}

export enum AuthorizationlettersActionTypes {
  GET_AUTHORIZATIONLETTERMETADATA_REQUEST = "@@authorizationletter/GET_AUTHORIZATIONLETTERMETADATA_REQUEST",
  GET_AUTHORIZATIONLETTERMETADATA_SUCCESS = "@@authorizationletter/GET_AUTHORIZATIONLETTERMETADATA_SUCCESS",
  GET_AUTHORIZATIONLETTERMETADATA_ERROR = "@@authorizationletter/GET_AUTHORIZATIONLETTERMETADATA_ERROR",
  GET_AUTHORIZATIONLETTER_REQUEST = "@@authorizationletter/GET_AUTHORIZATIONLETTER_REQUEST",
  GET_AUTHORIZATIONLETTER_SUCCESS = "@@authorizationletter/GET_AUTHORIZATIONLETTER_SUCCESS",
  GET_AUTHORIZATIONLETTER_ERROR = "@@authorizationletter/GET_AUTHORIZATIONLETTER_ERROR",
  GET_AUTHORIZATIONLETTERS_REQUEST = "@@authorizationletter/GET_AUTHORIZATIONLETTERS_REQUEST",
  GET_AUTHORIZATIONLETTERS_SUCCESS = "@@authorizationletter/GET_AUTHORIZATIONLETTERS_SUCCESS",
  GET_AUTHORIZATIONLETTERS_ERROR = "@@authorizationletter/GET_AUTHORIZATIONLETTERS_ERROR",
  PUT_AUTHORIZATIONLETTER_REQUEST = "@@authorizationletter/PUT_AUTHORIZATIONLETTER_REQUEST",
  PUT_AUTHORIZATIONLETTER_SUCCESS = "@@authorizationletter/PUT_AUTHORIZATIONLETTER_SUCCESS",
  PUT_AUTHORIZATIONLETTER_ERROR = "@@authorizationletter/PUT_AUTHORIZATIONLETTER_ERROR",
  DELETE_AUTHORIZATIONLETTER_REQUEST = "@@authorizationletter/DELETE_AUTHORIZATIONLETTER_REQUEST",
  DELETE_AUTHORIZATIONLETTER_SUCCESS = "@@authorizationletter/DELETE_AUTHORIZATIONLETTER_SUCCESS",
  DELETE_AUTHORIZATIONLETTER_ERROR = "@@authorizationletter/DELETE_AUTHORIZATIONLETTER_ERROR",
  POST_AUTHORIZATIONLETTER_REQUEST = "@@authorizationletter/POST_AUTHORIZATIONLETTER_REQUEST",
  POST_AUTHORIZATIONLETTER_SUCCESS = "@@authorizationletter/POST_AUTHORIZATIONLETTER_SUCCESS",
  POST_AUTHORIZATIONLETTER_ERROR = "@@authorizationletter/POST_AUTHORIZATIONLETTER_ERROR",
}

export interface AuthorizationlettersState {
  readonly list: Authorizationletters[] | null;
  readonly details: Authorizationletters | null;
  readonly pagination: Pagination | null;
  readonly metaDataFields: AuthorizationlettersMetadata[];
  readonly loading: boolean;
  readonly dataLoading: boolean;
  readonly metaLoading: boolean;
}
