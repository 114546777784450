export interface channelsMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface Channel {
  createdAt : string,
  createdBy : string,
  deletedAt:string,
  id : string,
  updatedAt : string,
  valuesJson:string
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    last: boolean
  }

export enum ChannelActionTypes {
    GET_CHANNELMETADATA_REQUEST = "@@channel/GET_CHANNELMETADATA_REQUEST",
    GET_CHANNELMETADATA_SUCCESS = "@@channel/GET_CHANNELMETADATA_SUCCESS",
    GET_CHANNELMETADATA_ERROR = "@@channel/GET_CHANNELMETADATA_ERROR",
    GET_CHANNEL_REQUEST = "@@channel/GET_CHANNEL_REQUEST",
    GET_CHANNEL_SUCCESS = "@@channel/GET_CHANNEL_SUCCESS",
    GET_CHANNEL_ERROR = "@@channel/GET_CHANNEL_ERROR",
    GET_CHANNELS_REQUEST = "@@channel/GET_CHANNELS_REQUEST",
    GET_CHANNELS_SUCCESS = "@@channel/GET_CHANNELS_SUCCESS",
    GET_CHANNELS_ERROR = "@@channel/GET_CHANNELS_ERROR",
    PUT_CHANNEL_REQUEST = "@@channel/PUT_CHANNEL_REQUEST",
    PUT_CHANNEL_SUCCESS = "@@channel/PUT_CHANNEL_SUCCESS",
    PUT_CHANNEL_ERROR = "@@channel/PUT_CHANNEL_ERROR",
    DELETE_CHANNEL_REQUEST = "@@channel/DELETE_CHANNEL_REQUEST",
    DELETE_CHANNEL_SUCCESS = "@@channel/DELETE_CHANNEL_SUCCESS",
    DELETE_CHANNEL_ERROR = "@@channel/DELETE_CHANNEL_ERROR",
    POST_CHANNEL_REQUEST = "@@channel/POST_CHANNEL_REQUEST",
    POST_CHANNEL_SUCCESS = "@@channel/POST_CHANNEL_SUCCESS",
    POST_CHANNEL_ERROR = "@@channel/POST_CHANNEL_ERROR",    
    GET_APPLICATIONS_COMMISSIONS_LIST_REQUEST = "@@channel/GET_APPLICATIONS_COMMISSIONS_LIST_REQUEST",
    GET_APPLICATIONS_COMMISSIONS_LIST_SUCCESS = "@@channel/GET_APPLICATIONS_COMMISSIONS_LIST_SUCCESS",
    GET_APPLICATIONS_COMMISSIONS_LIST_ERROR = "@@channel/GET_APPLICATIONS_COMMISSIONS_LIST_ERROR",
    GET_INVOICES_STUDENTS_LIST_REQUEST = "@@channel/GET_INVOICES_STUDENTS_LIST_REQUEST",
    GET_INVOICES_STUDENTS_LIST_SUCCESS = "@@channel/GET_INVOICES_STUDENTS_LIST_SUCCESS",
    GET_INVOICES_STUDENTS_LIST_ERROR = "@@channel/GET_INVOICES_STUDENTS_LIST_ERROR",
}

export interface ChannelState {
    readonly list: Channel[] | null;
    readonly details: Channel | null;
    readonly pagination: Pagination | null;
    readonly metaDataFields: channelsMetadata[];
    readonly applicationsChannel: any;
    readonly invoicesstudents: any;
    readonly loading: boolean;
    readonly dataLoading: boolean;
    readonly metaLoading: boolean;
}
