import {Reducer} from "redux";
import {FormActionTypes, FormState} from "./types";


export const initialState: FormState = { 
    formData: {},
    loading: false,
};

const reducer: Reducer<FormState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case FormActionTypes.SET_FORM_DATA: {
            localStorage.setItem('formData', JSON.stringify(action.payload))
            return {...state, formData: action.payload};
        } 
        default: {
            return state;
        }
    }
};

export {reducer as FormReducer};
