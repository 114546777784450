import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import FeatherIcon from "feather-icons-react";


const ArchiveModal = ({ props, show, onCloseClick, data, record, onArchived }: any) => {
    return (
        <Modal backdrop={"static"} id="myModal" isOpen={show} toggle={() => { onCloseClick(); }} centered>
            <ModalHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" id="myModalLabel" toggle={() => { onCloseClick(); }}>
                <span className="m-0 me-2 text-white">{props.t("properties.archive_the_property")}</span>
            </ModalHeader>
            <ModalBody className='text-center p-5'>
                <div className="mt-1">
                    <FeatherIcon icon="archive" className="mb-1 text-danger display-2" style={{ width: 60, height: 60 }} />
                    <h4 className="mb-2 mt-2">{record.name}</h4>
                    <p className="text-muted fs-15 mb-4">{props.t("properties.archive_disclaimer")}</p>
                    <div className="hstack gap-2 justify-content-center">
                        <Button className="btn btn-danger" onClick={() => onArchived()}>
                            {props.t("properties.archive")}
                        </Button>
                        <Button color="danger" onClick={() => onCloseClick()} outline>
                            {props.t("properties.cancel")}
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    ) as unknown as JSX.Element;
};

export default ArchiveModal;