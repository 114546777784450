import React, { useEffect, useState } from "react";
import { useGoogleLogin } from '@react-oauth/google';
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import axios from "axios";
import config from "../../config";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { getGoogleCalendarData, postGoogleCalendarSync } from "store/calendarSync/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const { api } = config;

const DTS_API_DOMAIN = api.DTS_API_DOMAIN
// interface FProps {
//     props: any;
//     confirmModal: boolean,
//     setConfirmModal: (event: boolean) => void;
//     // handleTokenResponse: (response: any) => void;
// }
const ConfirmSyncModal = ({ t, confirmModal, setConfirmModal }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const auth = useSelector((state: ApplicationState) => state.auth);
    const [email, setEmail] = useState<string>('')
    const [token, setToken] = useState<string | null | undefined>('')
    const [googleResponse, setGoogleResponse] = useState<any>()
    const tog_animationZoom = () => {
        setConfirmModal(!confirmModal);
    }
    const login = useGoogleLogin({
        onSuccess: codeResponse => {
          handleGoogleCalendarSync(codeResponse.code, codeResponse.scope);
        },
        flow: 'auth-code',
        scope: "https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.readonly",
      });
    const handleGoogleCalendarSync = (code: any, scope: any) => {
        const data =
        {
            code: code,      
            scope: scope,
            provider: 'google',
            isSignup: "false"
        }
        const handleSuccess = (body: any): void => {
            dispatch(getGoogleCalendarData())
        };
        const handleError = (body: any): void => {
        };
        dispatch(postGoogleCalendarSync(data, handleSuccess, handleError));
    };

    return (
        <>
            <Modal id="flipModal" isOpen={confirmModal} modalClassName="zoomIn" centered >
                <ModalHeader className="pb-3 bg-info" toggle={() => { tog_animationZoom() }}>
                    <h5 className="text-white modal-title" id="exampleModalLabel">{t("userpreferences.connect_your_google_account")}</h5>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            {t("userpreferences.confirm_modal_para1")}
                        </p>
                        <p>
                            <li><strong>{t("userpreferences.google_calendar")}</strong>
                                <p className="mx-3">{t("userpreferences.confirm_modal_para2")}</p>
                            </li>
                        </p>
                        <p>
                            {t("userpreferences.confirm_modal_para3")}
                        </p>
                        <p>
                            {t("userpreferences.confirm_modal_para4")}<span><strong><Link to="#">{t("userpreferences.here")}</Link></strong></span>
                        </p>
                        <p>
                            {t("userpreferences.confirm_modal_para5")}{t("userpreferences.confirm_modal_para4")}<span><strong><Link to="#">{t("userpreferences.privacy_policy")}</Link></strong></span>
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-start">
                    <Button
                        color="success"
                        className="btn btn-success"
                        onClick={() => {
                            login();
                            setConfirmModal(false);
                        }}
                    >
                        {t("userpreferences.accept_connect_with_google")}
                    </Button>
                    <Button
                        color="danger"
                        className="btn btn-danger"
                        onClick={() => {
                            setConfirmModal(false);
                        }}
                    >
                        {t("userpreferences.cancel")}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default withTranslation()(ConfirmSyncModal);