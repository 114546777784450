import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { withTranslation } from "react-i18next";

interface DeleteProcessFlowModelProps {
    isOpen: boolean;
    processFlow: any;
    toggle: (isClosed: any) => void;
    onDelete: () => void;
    t: (key: string) => string;
}

const DeleteProcessFlowModal = ({ isOpen, toggle, processFlow , onDelete, t}: DeleteProcessFlowModelProps) => {
    return (
        <Modal backdrop="static" isOpen={isOpen} toggle={toggle} centered={true}>
            <ModalBody className='text-center p-5'>
                <div className="mt-1">     
                    <i className="ri-delete-bin-5-line display-5 text-danger"></i>                                  
                    <h4 className="mb-2 mt-2">{processFlow?.label}</h4>
                    <p className="text-muted fs-15 mb-4">{t('properties.process_flow.common.process_flow.delete.helper_text_1')} {processFlow?.label}. {t('properties.process_flow.common.process_flow.delete.helper_text_2')}</p>
                    <div className="hstack gap-2 justify-content-center">
                        <Button className="btn btn-danger" onClick={() => onDelete()}>
                            {t('properties.process_flow.common.process_flow.delete.btn_delete')}
                        </Button>
                        <Button color="danger" onClick={() => toggle(false)} outline>
                            {t('properties.process_flow.common.process_flow.delete.btn_cancel')}
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
};

export default withTranslation()(DeleteProcessFlowModal);