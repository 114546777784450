
export enum FormActionTypes {
    SET_FORM_DATA = "@@role/SET_FORM_DATA",
    PUT_FORM_SUCCESS = "@@role/PUT_FORM_SUCCESS",
    PUT_FORM_REQUEST = "@@role/PUT_FORM_REQUEST",
    PUT_FORM_ERROR = "@@role/PUT_FORM_ERROR",
    POST_FORM_SUCCESS = "@@role/POST_FORM_SUCCESS",
    POST_FORM_REQUEST = "@@role/POST_FORM_REQUEST",
    POST_FORM_ERROR = "@@role/POST_FORM_ERROR",
}

export interface FormState {
    readonly formData: any;   
    readonly loading: any; 
}
