import { Reducer } from "redux";
import { SubagentActionTypes, SubagentState } from "./types";


export const initialState: SubagentState =
{
    metaDataFields: [],
    details: null,
    list: null,
    pagination: null,
    invoicesstudents: [],
    loading: false,
    subagentusers: [],
    dataLoading: false,
    usersLoading: false,
    metaLoading: false
}
const reducer: Reducer<SubagentState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case SubagentActionTypes.GET_SUBAGENTMETADATA_REQUEST: {
            return { ...state, metaLoading: true };
        }
        case SubagentActionTypes.GET_SUBAGENTMETADATA_SUCCESS: {
            return {
                ...state,
                metaLoading: false,
                metaDataFields: action.payload.content
            };
        }
        case SubagentActionTypes.GET_SUBAGENTMETADATA_ERROR: {
            return { ...state, metaLoading: false, errors: action.payload };
        }
        case SubagentActionTypes.GET_SUBAGENT_REQUEST: {
            return { ...state, loading: true };
        }
        case SubagentActionTypes.GET_SUBAGENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                details: action.payload,
            };
        }
        case SubagentActionTypes.GET_SUBAGENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }          
        case SubagentActionTypes.GET_SUBAGENTS_USERS_REQUEST: {
            return { ...state, usersLoading: true };
        }
        case SubagentActionTypes.GET_SUBAGENTS_USERS_SUCCESS: {
            return {
                ...state,
                usersLoading: false,
                subagentusers: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable?.offset
                }
            };
        }
        case SubagentActionTypes.GET_SUBAGENTS_USERS_ERROR: {
            return { ...state, usersLoading: false, errors: action.payload };
        } 
        
        case SubagentActionTypes.PUT_USER_REQUEST: {
            return { ...state, loading: true };
        }
        case SubagentActionTypes.PUT_USER_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case SubagentActionTypes.PUT_USER_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        
        case SubagentActionTypes.GET_INVOICES_STUDENTS_LIST_REQUEST: {
            return { ...state, loading: true };
        }
        case SubagentActionTypes.GET_INVOICES_STUDENTS_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                invoicesstudents: action.payload.content
            };
        }
        case SubagentActionTypes.GET_INVOICES_STUDENTS_LIST_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        } 

        case SubagentActionTypes.POST_SUBAGENT_REQUEST: {
            return { ...state, loading: true };
        }
        case SubagentActionTypes.POST_SUBAGENT_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case SubagentActionTypes.POST_SUBAGENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case SubagentActionTypes.GET_SUBAGENTS_REQUEST: {
            return { ...state, dataLoading: true };
        }
        case SubagentActionTypes.GET_SUBAGENTS_SUCCESS: {
            return {
                ...state,
                dataLoading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case SubagentActionTypes.GET_SUBAGENTS_ERROR: {
            return { ...state, dataLoading: false, errors: action.payload };
        }
        case SubagentActionTypes.PUT_SUBAGENT_REQUEST: {
            return { ...state, loading: true };
        }
        case SubagentActionTypes.PUT_SUBAGENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                subagentlist: action.payload.content
            };
        }
        case SubagentActionTypes.PUT_SUBAGENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case SubagentActionTypes.DELETE_SUBAGENT_REQUEST: {
            return { ...state, loading: true };
        }
        case SubagentActionTypes.DELETE_SUBAGENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case SubagentActionTypes.DELETE_SUBAGENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        default: {
            return state;
        }
    }
};

export { reducer as SubagentReducer };
