
import import_svg from '../../../../assets/images/svg/upload-file-1.svg';

export const importFile = [
    {
        "title": "Import File from Computer",
        "description": "Upload any XLS or XLSX files with students, applications, institutes, courses information.",
        "svg": import_svg,
        "value": 'file'
    }
    // {
    //     "title": "Import on Opt-out list",
    //     "description": "Import a list of peoaple who are disqualified from receiving emails.",
    //     "svg": import_svg,
    //     "value": 'list'
    // },
    // {
    //     "title": "Reapeat a past import",
    //     "description": "Use a past import to as a template for a new import with a new line",
    //     "svg": import_svg,
    //     "value": 'repeat'
    // }
]