import { createSelector } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { components } from 'react-select';
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import country from './countrycode.json';

const Territory = (props: any) => {
    const { inputs, handleRemoveComponent } = props;
    // const [selectedTerritory, setSelectedTerritory] = useState([]) 
    const [includedTerritory, setIncludedTerritory] = useState([])
    const [excludedTerritory, setExcludedTerritory] = useState([])
    const [territoriesOption, setTerritoriesOptions] = useState([])
    const [type, setType] = useState<any>({
        label: 'Included',
        value: 'included'
    })
    const [isAllIncluded, setIsAllIncluded] = useState<any>(false)


    const ValueContainer = ({ children, getValue, ...props }: any) => {
        var length = getValue().length;
        if (length) {
            return (
                <components.ValueContainer {...props}>
                    {!props.selectProps.inputValue &&
                        `${length} ${length !== 1 ? "Territories" : "Territory"} selected`
                    }
                    {React.Children.map(children, child => {
                        return child.type === components.Input ? child : null;
                    })}
                </components.ValueContainer>
            );
        }
        return <components.ValueContainer {...props}>{children}</components.ValueContainer>
    };

    useEffect(() => {
        let territories: any = country.map((item: any) => {
            return { label: item.name, value: item.name }
        })
        setTerritoriesOptions(territories)
    }, [])

    const onDelete = (item: any, type: any) => {
        switch (type) {
            case 'included':
                let included = includedTerritory.filter((selected: any) => selected?.value !== item.value)
                setIncludedTerritory(included)
                break;
            case 'excluded':
                let excluded = excludedTerritory.filter((selected: any) => selected?.value !== item.value)
                setExcludedTerritory(excluded)
                break;
        }
    }

    useEffect(() => {
        let territories = inputs?.territories;
        let type = typeOption.find((item: any) => territories?.inclusionType === item.value);
        if(type) {
            setType(type);
        }
        setIncludedTerritory(territories?.includedTerritory);
        setExcludedTerritory(territories?.excludedTerritory);
        setIsAllIncluded(territories?.isAllIncluded);
    }, [inputs?.territories])

    const typeOption = [
        {
            label: 'Included',
            value: 'included'
        },
        {
            label: 'Excluded',
            value: 'excluded'
        },
        {
            label: 'Both',
            value: 'both'
        }
    ]

    const onAddTerritory = () => {
        let territories = {
            inclusionType: type?.value,
            includedTerritory: includedTerritory,
            excludedTerritory: excludedTerritory,
            isAllIncluded: isAllIncluded
        }
        props.onAddRule(territories, 'territories')
        setIncludedTerritory([]);
        setExcludedTerritory([]);
        setIsAllIncluded(false)
        setType({})
    }

    return (
        <React.Fragment>
            <Card className='border border-dashed border-end-0 border-start-0 vstack gap-2'>
                <CardHeader className='hstack bg-primary-subtle justify-content-between'>
                    <Label className='fw-bold fs-20 text-primary'>Territory Wise</Label>
                    <button onClick={() => { props.setOpen(false); handleRemoveComponent('territories') }} className="btn btn-sm btn-soft-danger remove-list fs-12"                                                                                >
                        <i className="ri-delete-bin-fill"></i>{" "}
                    </button>{" "}
                </CardHeader>
                <CardBody>
                    <div>
                        <Label className='fw-bold fs-20 text-primary'>Inclusion Type</Label>
                        <Select
                            placeholder="Select..."
                            closeOnSelect={true}
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable
                            isSearchable
                            name="fields"
                            options={typeOption}
                            values={inputs?.inclusionType}
                            onChange={(option: any) => { setType(option); setIncludedTerritory([]); setExcludedTerritory([]); setIsAllIncluded(false); }}
                            value={type}
                        />
                    </div>
                    <div>
                        {type?.value !== 'excluded' && includedTerritory?.length ?
                            <div>
                                <Label className='fw-semibold fs-16 text-primary'>Selected Included Territory</Label>
                                <div className='hstack w-100 p-1 gap-2'>
                                    {includedTerritory && includedTerritory.length ? includedTerritory.map((item: any) => {
                                        return <div className='ms-0'>
                                            <span className="badge bg-primary fs-12">{item?.label}{"  "}
                                                <i className="ri-close-circle-line text-danger fs-12 cursor-pointer" onClick={() => onDelete(item, 'included')}></i>{" "}</span>
                                        </div>
                                    }) : null}
                                </div>
                            </div>
                            : null}
                        {type?.value !== 'included' && excludedTerritory.length ?
                            <div>
                                <Label className='fw-semibold fs-16 text-primary'>Selected Excluded Territory</Label>
                                <div className='hstack w-100 p-1 gap-2'>
                                    {excludedTerritory && excludedTerritory.length ? excludedTerritory.map((item: any) => {
                                        return <div className='ms-0'>
                                            <span className="badge bg-primary fs-12">{item?.label}{"  "}
                                                <i className="ri-close-circle-line text-danger fs-12 cursor-pointer" onClick={() => onDelete(item, 'excluded')}></i>{" "}</span>
                                        </div>
                                    }) : null}
                                </div>
                            </div>
                            : null}
                    </div>
                    <hr />
                    <Row className='align-item-end'>
                        {type?.value !== 'excluded' ?
                            <Col className='align-item-end'>
                                {type?.value === 'included' ? <div className='p-4'>
                                    <Input
                                        className={"form-check-input"}
                                        type={"checkbox"}
                                        checked={isAllIncluded}
                                        onChange={(e: any) => {setIsAllIncluded(!isAllIncluded); setIncludedTerritory([])}}
                                    />
                                    <Label className="form-label">Include All Territories</Label>
                                </div> : null}
                                {
                                    !isAllIncluded ?
                                        <Select
                                            placeholder="Select Included..."
                                            closeOnSelect={true}
                                            isMulti
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable
                                            isSearchable
                                            name="fields"
                                            options={territoriesOption}
                                            values={inputs?.territories}
                                            onChange={setIncludedTerritory}
                                            value={includedTerritory}
                                            components={{ ValueContainer }}
                                        />
                                        : null
                                }
                            </Col>
                            : null}
                            {type?.value !== 'included' ?
                                <Col className='align-item-end'>
                                    <Select
                                        placeholder="Select Excluded..."
                                        closeOnSelect={true}
                                        isMulti
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable
                                        isSearchable
                                        name="fields"
                                        options={territoriesOption}
                                        values={inputs?.territories}
                                        onChange={setExcludedTerritory}
                                        value={excludedTerritory}
                                        components={{ ValueContainer }}
                                    />
                                </Col>
                                : null}
                    </Row>
                    <div className='hstack justify-content-center p-2'>
                        <Button
                            onClick={() => onAddTerritory()}
                            color="primary"
                            className="btn-primary">
                            {inputs?.territories && Object.values(inputs?.territories)?.length ? 'Update Rule' : 'Save Rule'}
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(Territory);
