import React, { useState, useEffect, useMemo } from 'react';
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import { toast } from 'react-toastify';
import { Form, Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { ApplicationState } from 'store';
import { taskAddRequest, tasksListGetRequest } from 'store/task/action';
import { usersListGetRequest } from 'store/user/action';
import { getStudentsActivity } from 'store/student/action';
import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import Select from "react-select";
import { components } from "react-select";


const EditTasks = ({ setIsEditOpen, isEditOpen, taskDetails, model, details, handleActivitiesList, handleTaskUpdateSuccess }: any) => {
    let { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const tasks = useSelector((state: ApplicationState) => state.task.tasks);
    const userList = useSelector((state: ApplicationState) => state.user.users);
    const [users, setUsers] = useState<any[]>([])
    const [taskData, setTaskData] = useState<any>(taskDetails);
    const [studentId, setStudentId] = useState<any>(null);
    const [applicationId, setApplicationId] = useState<any>(null);
    const [sortBy, setsortBy] = useState<any>(null);
    const [user, setUser] = useState<any>();
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<{ value: any; label: any }[]>([]);

    const assignedToList = useMemo(() => {
        return taskDetails?.taskAssignments?.map((assignment: { assignedTo: { name: any; id: any } }) => {
            const { name, id } = assignment?.assignedTo;
            return { label: name, value: id };
        }) || [];
    }, [taskDetails?.taskAssignments]);

    const toggleEdit = (() => setIsEditOpen(!isEditOpen));

    useEffect(() => {
        setTaskData(taskDetails)
    }, [taskDetails])

    useEffect(() => {
        const roleValues: any = [];
        userList && userList.map((item: any) => {
            roleValues.push({ value: item.id, label: item.name });
        });
        setUsers(roleValues);
    }, [userList]);

    useEffect(() => {
        if (assignedToList.length > 0) {
            setSelectedUser(assignedToList);
        }
    }, [assignedToList]);

    useEffect(() => {
        const task: any = [];
        tasks && tasks.map((item: any) => {
            task.push({ value: item.id, label: item.name });
        });
    }, [tasks]);

    useEffect(() => {
        dispatch(usersListGetRequest())
    }, [])

    const handleGetList = (filterObject?: any, handleSuccess?: any) => {
        if(model === "students") {
            if (id) {
                setStudentId(id)
                dispatch(tasksListGetRequest(id, filterObject));
                dispatch(getStudentsActivity(details.id, filterObject, handleSuccess))
            }
        }
        else {
            if (details && details.id) {                
                setApplicationId(details.id)
                let studentsId = details && details?.valuesJson && details?.valuesJson.student ? details?.valuesJson?.student?.id : "";
                setStudentId(studentsId)
                let filters = {...filterObject, applicationId: id}
                dispatch(tasksListGetRequest(studentsId, filters))
                dispatch(getStudentsActivity(studentsId, filters, handleSuccess))
            }
        }     
    }

    useEffect(() => {
        handleGetList()   
    }, [details])


    const handleEditTask = () => {
        // const userIdString = users && users.map((userString: { value: any; }) => userString.value).join(',');
        // const assined = taskData && taskData.taskAssignments?.map((assignment: { assignedTo: { name: any; id: any; }; }) => {
        //     const { name, id } = assignment.assignedTo;
        //     return { label: name, value: id };
        // });
        // const assignedToValues = assined.map((option: { value: any; }) => option.value);
        const data = {
            id: taskData.id,
            title: taskData.title,
            status: taskData.status,
            priority: taskData.priority,
            dueDate: taskData.dueDate,
            studentId: studentId,
            applicationId: applicationId,
            assignedTo: user
        }

        const handleSuccess = (): void => {
            toast(`Task edited successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            setIsEditOpen(false);
            handleGetList() 
            dispatch(getStudentsActivity(id, {}, 0))  
            handleActivitiesList && handleActivitiesList({ PageSize: 50 }, handleTaskUpdateSuccess)
            
        };

        const handleError = (): void => {
            // const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            // message();
        };
        dispatch(taskAddRequest(data, handleSuccess, handleError));
    }

    const handleUserSearch = (option: any, name: string) => {
        let queryParams: any = new URLSearchParams({});
        queryParams.set('status', "CONFIRMED")
        switch (name) {
          case 'searchUser':
            if (option) {
              queryParams.set('search', option.toString());
        
            }
            break;
        }
        dispatch(usersListGetRequest(queryParams.toString()));
      }

    const handleInputChange = (inputValue: any) => {
        handleUserSearch(inputValue, "searchUser");
      };

      const handleUserSelect = (sortBy: any) => {
        setUser(sortBy.value);
        setSelectedUser(sortBy);
        setMenuIsOpen(false);
      };
      const CustomOption = (props: any) => {
        return (
            <components.Option {...props}>
                <div className="custom-option">
                    <img src={avatar3} alt="" className="img-fluid rounded-circle avatar-xxs mx-2" />
                    <span>{props.data.label}</span>
                </div>
            </components.Option>
        );
    };

    const sortbystatus = [
        {
            options: [
                { label: "New", value: "NEW" },
                { label: "Inprogress", value: "INPROGRESS" },
                { label: "Completed", value: "COMPLETED" },
            ],
        },
    ];


    const sortbypriority = [
        {
            options: [
                { label: "Low", value: "LOW" },
                { label: "Medium", value: "MEDIUM" },
                { label: "High", value: "HIGH" },
            ],
        },
    ];
console.log("selectedUser",user)
    document.title = "To Do Lists | Zilter";
    return (
        <React.Fragment>
            {taskDetails && taskDetails.id ?
                <Modal id="createTask" isOpen={isEditOpen} modalClassName="zoomIn" centered tabIndex={-1}>
                    <ModalHeader toggle={() => toggleEdit()} className="p-3 bg-primary-subtle"> {"Edit Task"} </ModalHeader>

                    <Modal id="createTask" isOpen={isEditOpen} modalClassName="zoomIn" centered tabIndex={-1}>
                        <ModalHeader toggle={toggleEdit} className="p-3 bg-primary-subtle">
                            {"Edit Task"}
                        </ModalHeader>

                        <ModalBody>
                            <div id="task-error-msg" className="alert alert-danger py-2"></div>

                            <input type="hidden" id="taskid-input" className="form-control" />
                            <div className="mb-3">
                                <label htmlFor="task-title-input" className="form-label">Task Title</label>
                                <Input
                                    type="text"
                                    id="task-title-input"
                                    className="form-control"
                                    placeholder="Enter task title"
                                    name="task"
                                    onChange={(e) => setTaskData({ ...taskData, title: e.target.value })}
                                    value={taskData.title}
                                />
                            </div>
                            <div className="mb-3 position-relative">
                            <label htmlFor="task-assign-input" className="form-label">Assign To&nbsp;<span>{sortBy?.label}</span></label>
                            <div className="avatar-group d-flex justify-content-center" id="assignee-member">
                                {sortBy ? (
                                    <div className='p-1'>
                                        <Link to="#">
                                            <img
                                                src={avatar1} // Use your image source here
                                                width={36}
                                                height={36}
                                                alt=""
                                                className="rounded-circle avatar-xs"
                                            />
                                        </Link>
                                    </div>
                                ) : null}
                            </div>
                            <div className="select-element">
                            <Select
                        placeholder="Assign to . . ."
                        value={selectedUser}
                        isMulti={false}
                        onInputChange={handleInputChange}
                        onChange={handleUserSelect}
                        options={users}
                        classNamePrefix="js-example-basic-multiple mb-0"
                        components={{ Option: CustomOption }}
                        isLoading={false}
                        menuIsOpen={menuIsOpen}
                        onMenuOpen={() => setMenuIsOpen(true)}
                        onMenuClose={() => setMenuIsOpen(false)}
                      />
                            </div>
                        </div>
                            {/* <div className="mb-2 gap-2 d-flex position-relative">
                                <div>
                                    <label htmlFor="task-assign-input" className="form-label">Assigned To:</label>
                                </div>
                                <div>
                                    <Label>{assignedToList[0]?.label}</Label>
                                </div>
                            </div> */}
                            <Row className="g-4 mb-3">
                                <Col lg={6}>
                                    <label htmlFor="task-status" className="form-label">Status</label>
                                    <Input
                                        name="status"
                                        type="select"
                                        className="form-select"
                                        id="status-field"
                                        onChange={(e) => setTaskData({ ...taskData, status: e.target.value })}
                                        value={taskData.status}
                                    >
                                        {sortbystatus.map((item, key) => (
                                            <React.Fragment key={key}>
                                                {item.options.map((optionItem, optionKey) => (
                                                    <option value={optionItem.value} key={optionKey}>{optionItem.label}</option>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </Input>
                                </Col>
                                <Col lg={6}>
                                    <label htmlFor="priority-field" className="form-label">Priority</label>
                                    <Input
                                        name="priority"
                                        type="select"
                                        className="form-select"
                                        id="priority-field"
                                        onChange={(e) => setTaskData({ ...taskData, priority: e.target.value })}
                                        value={taskData.priority}
                                    >
                                        {sortbypriority.map((sortItem, sortKey) => (
                                            <React.Fragment key={sortKey}>
                                                {sortItem.options.map((optionItem, optionKey) => (
                                                    <option value={optionItem.value} key={optionKey}>{optionItem.label}</option>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </Input>
                                </Col>
                            </Row>
                            <div className="mb-4">
                                <label htmlFor="task-duedate-input" className="form-label">Due Date:</label>
                                <Flatpickr
                                    name="dueDate"
                                    id="date-field"
                                    className="form-control"
                                    placeholder="Due date"
                                    options={{
                                        enableTime: true,
                                        dateFormat: "Y-m-d H:i",
                                    }}
                                    onChange={dates => {
                                        const selectedDate = dates[0]; // Flatpickr returns an array of selected dates, so we take the first one
                                        const formattedDate = selectedDate ? selectedDate.toISOString() : null; // Format the selected date using toISOString
                                        setTaskData({ ...taskDetails, dueDate: formattedDate }); // Update task details
                                    }}
                                    value={(taskDetails.dueDate)}
                                />
                            </div>
                            <div className="hstack gap-2 justify-content-end">
                                <Button type="button" color='primary' className="btn btn-ghost-primary" onClick={() => {
                                    toggleEdit();
                                    setTaskData(taskDetails);
                                }}>
                                    <i className="ri-close-fill align-bottom"></i> Close
                                </Button>
                                <Button type="submit" color='primary' className="btn btn-primary" onClick={handleEditTask} id="editTodo">
                                    {"Update Task"}
                                </Button>
                            </div>
                        </ModalBody>
                    </Modal>
                </Modal >
                : null}
        </React.Fragment >
    );
};

export default EditTasks;