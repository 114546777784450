export interface InvoicesMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface notes   {
    id: string,
    shortNote: string,
    detailedNote: string,
    createdBy: string,
    createdAt: string,
    replies: any,
    updatedAt: string,
    deletedAt: string
}
export interface Invoice {
  createdAt : string,
  createdBy : string,
  deletedAt:string,
  id : string,
  updatedAt : string,
  valuesJson:string
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    last: boolean
  }

export enum InvoiceActionTypes {
    GET_INVOICEMETADATA_REQUEST = "@@invoice/GET_INVOICEMETADATA_REQUEST",
    GET_INVOICEMETADATA_SUCCESS = "@@invoice/GET_INVOICEMETADATA_SUCCESS",
    GET_INVOICEMETADATA_ERROR = "@@invoice/GET_INVOICEMETADATA_ERROR",
    GET_INVOICE_REQUEST = "@@invoice/GET_INVOICE_REQUEST",
    GET_INVOICE_SUCCESS = "@@invoice/GET_INVOICE_SUCCESS",
    GET_INVOICE_ERROR = "@@invoice/GET_INVOICE_ERROR",
    GET_INVOICES_REQUEST = "@@invoice/GET_INVOICES_REQUEST",
    GET_INVOICES_SUCCESS = "@@invoice/GET_INVOICES_SUCCESS",
    GET_INVOICES_ERROR = "@@invoice/GET_INVOICES_ERROR",
    PUT_INVOICE_REQUEST = "@@invoice/PUT_INVOICE_REQUEST",
    PUT_INVOICE_SUCCESS = "@@invoice/PUT_INVOICE_SUCCESS",
    PUT_INVOICE_ERROR = "@@invoice/PUT_INVOICE_ERROR",
    DELETE_INVOICE_REQUEST = "@@invoice/DELETE_INVOICE_REQUEST",
    DELETE_INVOICE_SUCCESS = "@@invoice/DELETE_INVOICE_SUCCESS",
    DELETE_INVOICE_ERROR = "@@invoice/DELETE_INVOICE_ERROR",
    POST_INVOICE_REQUEST = "@@invoice/POST_INVOICE_REQUEST",
    POST_INVOICE_SUCCESS = "@@invoice/POST_INVOICE_SUCCESS",
    POST_INVOICE_ERROR = "@@invoice/POST_INVOICE_ERROR"
}

export interface InvoiceState {
    readonly list: Invoice[] | null;
    readonly details: Invoice | null;
    readonly pagination: Pagination | null;
    readonly metaDataFields: InvoicesMetadata[];
    readonly loading: boolean;
    readonly dataLoading: boolean;
    readonly metaLoading: boolean;
}
