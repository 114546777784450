import React, { useEffect, useState } from "react";
import { Label, Input, Form, FormFeedback, Button, ButtonGroup } from "reactstrap";

interface DtsRadioInputProps {
    label: string;
    value: any;
    checked?: boolean;
    onChange: (value: any) => void;
    onSaveClick: () => void;
    name?: any;
    rest?: any;
    actionButtons?: any;
    defaultValue?: any;
    setdisabled?: any;
    setError?: any;
    formerror?: any;
    readOnly: any;
    resetvalue?: any;
}

const DtsRadioInput: React.FC<DtsRadioInputProps> = ({resetvalue, readOnly,setdisabled, onSaveClick, defaultValue, actionButtons, rest, label, value, onChange, name }) => {
    const [errormsg, setErrormsg] = useState();
    const [hover, setHover] = useState<any>(false)
    const [formoptions, setOptions] = useState<any>(false)
    const [resetValue, setResetValue] = useState<any>(null)
    const onhandleChange = (value: any) => {
        onChange(value)
        validateInput(value);
    }
    useEffect(() => {
        onChange && onChange(resetvalue)
        setResetValue(resetvalue)
    }, [resetvalue])
    
    // useEffect(() => {
    //     if(rest && rest.validate && rest.validate.required) {           
    //         if(value === '')
    //         { 
    //             const inputdiv: any = document.getElementById(`${name}input`);
    //             if(inputdiv) inputdiv.classList.add('d-block');
    //         } 
    //         else {
    //             const inputdiv: any = document.getElementById(`${name}input`);
    //             if(inputdiv) inputdiv.classList.remove('d-block');
    //         }            
    //     }
    // },[value])

    useEffect(() => {
        if(rest && rest.validate) {           
            if(errormsg)
            { 
                if (typeof setdisabled === 'function') {
                    setdisabled(true)
                }
                const inputdiv: any = document.getElementById(`${name}input`);
                if(inputdiv) inputdiv.classList.add('d-block');
            } 
            else {
                if (typeof setdisabled === 'function') {
                    setdisabled(false)
                }
                const inputdiv: any = document.getElementById(`${name}input`);
                if(inputdiv) inputdiv.classList.remove('d-block');
            }            
        }
    },[value])

    const validateInput = (value: any) => { 
        if(rest && rest.validate && rest.validate.required) {
            if(value === "") {
               let error: any =  `Please enter ${label}`;
                setErrormsg(error)
                return false
            }
        }
        setErrormsg(undefined)
        return true
    }
    useEffect(() => {
        if (rest && rest.validate && rest.validate.required) {
            if (value === '') {
                const inputdiv: any = document.getElementById(`${name}input`);
                if (inputdiv) inputdiv.classList.add('d-block');
            }
            else {
                const inputdiv: any = document.getElementById(`${name}input`);
                if (inputdiv) inputdiv.classList.remove('d-block');
            }
        }
    }, [value])

    useEffect(() => {
        if (rest && rest.validate && rest.validate.required) {
            const feedback: any = document.getElementById(`${name}`);
            if (feedback) feedback.classList.remove('d-none');
        }
    }, [rest])

    const onCheckClick = (e: any) => {
        e.preventDefault();
        onSaveClick();
        setHover(false)
    }
    useEffect(() => { 
        if(rest && rest.values && rest.values.length) {
            let newoptions = rest.values.sort((a: any, b: any) => a.id - b.id);
            let tempOptions = newoptions.filter((a: any, b: any) => a.in_forms == true);
            setOptions(tempOptions);
        }       
        
    },[rest])
    return (
        <div>
            {label ? <Label htmlFor={name} className="form-label">{label}<span id={`${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label> : null}

            {
                actionButtons ?
                    <div>
                        {hover ?
                            <div>
                                <div className="d-flex border rounded-3 align-items-center">
                                    <Form className="form-control">
                                        {rest && rest.values && rest.values.length ? <div>
                                            <div className="hstack justify-content-start mx-3 gap-5 flex-wrap">
                                            {formoptions && formoptions.length ? formoptions.map((item: any) => {
                                                    if(item.informs == false) return
                                                    else return <div  className="ps-4">
                                                        <Input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id={item.value}
                                                            checked={item.value == value}
                                                            onChange={(e) => onhandleChange(item.value)}
                                                        />
                                                        <Label className="form-check-label" htmlFor={name}>
                                                            {item.label}
                                                        </Label>
                                                    </div>
                                                }): null}
                                            </div>
                                            {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}
                                        </div>
                                            :
                                            <div className="form-check">
                                                <Input
                                                    className="form-check-input"
                                                    type="radio"
                                                    id={value}
                                                    checked={value == name}
                                                    onChange={(e) => onhandleChange(name)}
                                                />
                                                <Label className="form-check-label" htmlFor={name}>
                                                    {label}
                                                </Label>
                                                {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}
                                            </div>
                                        }
                                    </Form>
                                    <ButtonGroup>
                                        <Button onClick={(e) => { e.preventDefault();  setHover(false); onChange(resetValue) }} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-close-fill" /> </Button>
                                        <Button onClick={(e) => {onCheckClick(e)}} color="primary" className="btn-icon"> <i className="ri-check-fill" /> </Button>
                                    </ButtonGroup>
                                </div>   
                                {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}
                            </div>
                            : <div>
                                 <div className="hstack justify-content-between topbar-user">
                                    <div className="fs-14 fw-medium form-control border-0 topbar-user">
                                        {value}
                                    </div>
                                    {
                                            readOnly ? null
                                            : <Button onClick={() => setHover(true)} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-edit-2-fill" /> </Button>
                                        }
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div>
                        <Form>
                            {rest && rest.values && rest.values.length ? <div>
                                <div className="hstack justify-content-start mx-3 gap-5 flex-wrap">
                                    {rest.values.sort((a: any, b: any) => a.id - b.id).map((item: any) => {
                                        if(item.in_forms == false) return
                                        else return <div>
                                            <Input
                                                className="form-check-input"
                                                type="radio"
                                                id={item.value}
                                                checked={item.value == value}
                                                onChange={(e) => onhandleChange(item.value)}
                                            />
                                            <Label className="form-check-label" htmlFor={name}>
                                                {item.label}
                                            </Label>
                                        </div>
                                    })}
                                </div>
                                {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}
                            </div>
                                :
                                <div className="form-check">
                                    <Input
                                        className="form-check-input"
                                        type="radio"
                                        id={value}
                                        checked={value == name}
                                        onChange={(e) => onhandleChange(name)}
                                    />
                                    <Label className="form-check-label" htmlFor={name}>
                                        {label}
                                    </Label>
                                    {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}
                                </div>
                            }
                        </Form>
                        {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}
                    </div>
            }
        </div>
    );
};

export default DtsRadioInput;
