import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "reactstrap";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Wizard from "pages/Signup/steps/Wizard";
import { useDispatch } from "react-redux";
import { signupRequest } from "store/auth/action";
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

interface SignUpProps {

}

interface SignUpState {
    email: string;
    first_name: string;
    otp: string;
    last_name: string;
    company_name: string;
    company_website: string;
    password: string;
    people_count: string;
}
  
const SignUp: React.FC<SignUpProps> = () => {  

    document.title = "SignUp | Zilter";
    return (
        <Container fluid>
            <ToastContainer />
            <Row className="hstack justify-content-center">
                <Col xl={12} lg={12} md={12} sm={11} xs={11}>
                    <Row>
                        <Wizard />
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default SignUp;
