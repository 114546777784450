
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import Pagination from 'react-paginating';
import { Link } from "react-router-dom";

const Paginator = ({ total, currentPage, pageChanged, isPageSizeChange, onChangeInSelect, pagination, t }: any) => {
	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(total / pagination?.size); i++) {
		pageNumbers.push(i);
	}
	useEffect(() => {
		if (pageNumbers.length && pageNumbers.length < currentPage) {
			pageChanged(pageNumbers.length)
		}
	}, [pageNumbers.length, currentPage, pageChanged])

	const pageComponent = (
		pages: any,
		currentPage: any,
		hasNextPage: any,
		hasPreviousPage: any,
		previousPage: any,
		nextPage: any,
		totalPages: any,
		getPageItemProps: any) => {
		return <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0 gap-1">
			{pagination?.first ? null : <li className={"page-item"}>
				<Link to="#" className="page-link"  {...getPageItemProps({
					pageValue: 0,
					onPageChange: () =>pageChanged("first")
				})}>{"<<"}</Link>
			</li> }
			<li className={pagination?.first ? "page-item disabled" : "page-item"}>
				<Link to="#" className="page-link" {...getPageItemProps({
					pageValue: previousPage,
					onPageChange: () =>pageChanged("prev")
				})}>{t("student.previous")}</Link>
			</li>
			{pages.map((item: any, key: any) => {
				return <React.Fragment key={key}>
					<li className="page-item">
						<Link to="#" className={pagination?.page === item ? "page-link active" : "page-link"} {...getPageItemProps({
							pageValue: item,
							key: item,
							onPageChange: () =>pageChanged(item)
						})}>{item}</Link>
					</li>
				</React.Fragment>
			})}
			<li className={pagination?.last ? "page-item disabled" : "page-item"}>
				<Link to="#" className="page-link" {...getPageItemProps({
					pageValue: nextPage,
					onPageChange: () =>pageChanged("next")
				})}>{t("student.next")}</Link>
			</li>
			{pagination?.last ? null : <li className={"page-item"}>
				<Link to="#" className="page-link"  {...getPageItemProps({
					pageValue: totalPages,
					onPageChange: () =>pageChanged("last")
				})}>{">>"}</Link>
			</li>}
			{isPageSizeChange && (
				<li>
					<div>
						<select
							className="form-control fs-14 pl-1 pr-1 d-flex align-items-center pt-0 pb-0 text-center"
							value={pagination?.size}
							onChange={onChangeInSelect}
							style={{height:32}}
						>
							{[10, 20, 30, 40, 50, 100, 200].map((pageSize: any) => (
								<option key={pageSize} value={pageSize}>
									{t("student.show")} {pageSize}
								</option>
							))}
						</select>
					</div>
				</li>
			)}
		</ul>
	}
	return (
		<Pagination
			className="bg-red"
			total={total}
			limit={pagination?.size}
			pageCount={3}
			currentPage={currentPage}
		>
			{({
				pages,
				currentPage,
				hasNextPage,
				hasPreviousPage,
				previousPage,
				nextPage,
				totalPages,
				getPageItemProps
			}: any) => (
				<div>
					{pageComponent(pages,
						currentPage,
						hasNextPage,
						hasPreviousPage,
						previousPage,
						nextPage,
						totalPages,
						getPageItemProps)}
				</div>
			)}
		</Pagination>
	);
}

export default withTranslation()(Paginator);
