import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    Input,
    Label,
    Row,
    TabContent,
    TabPane,
    Tooltip,
} from 'reactstrap';
import FeatherIcon from 'feather-icons-react';
import MenuTab from './MenuTab';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import FormBuilder from 'Components/Common/FormBuilder/FormBuilder';

interface Options {
    label: string;
    value: string;
}

interface Permission {
    name: string;
    options?: Options[];
    value: string; // or the appropriate type
}

interface Feature {
    description: string;
    editable: boolean;
    name: string;
    value: boolean; // or the appropriate type
    type: string;
    permissions: Permission[];
    options?: Options[];
}

interface SubItem {
    name: string;
    features: Feature[];
}

interface MenuItem {
    name: string;
    items: SubItem[];
}

interface CreateInputs {
    role: string;
}

interface PermissionsProps extends WithTranslation {
    createInputs: CreateInputs;
    setAccessData: React.Dispatch<React.SetStateAction<MenuItem[]>>;
    accessData: MenuItem[];
    setAccessPermissions: (e :any) => void;
    accessPermissions: any;
}

const Permissions: React.FC<PermissionsProps> = ({
    createInputs,
    setAccessData,
    accessData,
    setAccessPermissions,
    accessPermissions,
    t
}) => {
    const history = useNavigate();
    const [allexpanded, setAllexpanded] = useState<boolean>(false);
    const [IsCrmOpen, setIsCrmOpen] = useState<boolean>(false);
    const [isAccountOpen, setIsAccountOpen] = useState<boolean>(false);
    const [openpermission, setOpenpermission] = useState([]);
    const [iscurrentState, setIscurrentState] = useState('');
    const [menutab, setMenutab] = useState(1);
    const [menu, setMenu] = useState<any>(1);
    const [customverticalTab, setcustomverticalTab] = useState<any>(2);
    const [ttop, setttop] = useState<boolean>(false);
    const permissionslist = useSelector((state: ApplicationState) => state.role.permissionsdata);
    useEffect(() => {
        if (iscurrentState !== 'CRM') {
            setIsCrmOpen(false);
        }
    }, [history, IsCrmOpen]);

    useEffect(() => {
        setMenu(accessData);
        setMenutab(1);
    }, []);

    const customtoggleVertical = (tab: any) => {
        if (customverticalTab !== tab) {
            setcustomverticalTab(tab);
        }
    };

    
    const handleChildrens = (item: any, index:  any) => {
        return (
            <li
            className={
                'nav-item list-unstyled cursor-pointer ' +
                (menutab === item.id ? 'nav-link fs-14 fw-bolder border-3 border-start border-dark'  : ' fw-normal ps-1')
            }
            onClick={() => {setMenutab(item.id); customtoggleVertical(item.children[0].id)}}
        >
            <span
                onClick={item.click}
                className={
                    'hstack justify-content-between nav-item p-2 fs-14 m-0 fw-normal' +
                    (menutab === item.id ? ' fw-bolder' : '')
                }
                data-bs-toggle="collapse"
            >
                {item.name}
                {menutab === item.id ? (
                    <i className="ri-arrow-up-s-line fw-bold fs-18" />
                ) : (
                    <i className="ri-arrow-down-s-line fw-bold fs-18" />
                )}
            </span>
            <Collapse className="accordion-collapse" isOpen={menutab === item.id} id={item.name}>
                <ul className="nav nav-sm flex-column">
                    {item &&
                        item.children.map((subSubChildItem: any, key: number) => {
                            
                            if(subSubChildItem.children.length) {
                                subSubChildItem.children.map((child: any, index: any) => {
                                    handleChildrens(child,index)
                                })
                                    
                            }
                            else return <li
                                className={ ' cursor-pointer nav-item apex p-2 fw-normal'+
                                    customverticalTab === subSubChildItem.id
                                        ? 'fw-bolder'
                                        : ''
                                }
                                key={subSubChildItem.id}
                            >
                                <span
                                    className={
                                        'hstack justify-content-between nav-item p-2 fs-12 m-0 fw-normal' +
                                        (customverticalTab === subSubChildItem.id ? 'fw-bolder' : '')
                                    }
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        customtoggleVertical(subSubChildItem.id);
                                    }}
                                >
                                   <span className="ps-3" >{subSubChildItem.name}</span>
                                </span>
                            </li>
                    })}
                </ul>
            </Collapse>
        </li>
        )
    }

    const handleleaf = (item: any, index: any) => {
        return (
            <li
                className={
                    'nav-item list-unstyled cursor-pointer ' +
                    (menutab === item.id ? 'nav-link fs-14 fw-normal border-3 border-start border-dark' : 'ps-1')
                }
                onClick={(e: any) => {
                    e.stopPropagation();
                    customtoggleVertical(item.id);
                    setMenutab(item.id)
                }}
            >
                <span  className={
                    'hstack justify-content-between nav-item p-2 fs-14 m-0 fw-normal' +
                    (menutab === item.id ? ' fw-bolder' : '')
                }>{item.name}</span>
            </li>
        )
    }

    const handleLeafRight = (item: any,index: any) => {  
        return <TabPane tabId={item.id} key={item.id}>
            <>
                <MenuTab
                    mainItem={item || []}
                    index={item.id}
                    accessdataindex={item.id}
                    allexpanded={allexpanded}
                    setAccessData={setAccessData}
                    accessData={accessData}
                    openpermission={openpermission}
                    setOpenpermission={setOpenpermission}
                    setAccessPermissions={setAccessPermissions}
                    accessPermissions={accessPermissions}
                />
            </>
        </TabPane>
    }
    
    const handleChildrensRight = (item: any, index: any) => {
        if (item && item.children && item.children.length) {
            return <React.Fragment key={item.id}>
            {item.children.map((child: any, key: any) => {
                if (child && child.children && child.children.length) {
                    handleChildrensRight(child.children, index)
                } else  return <React.Fragment key={child.id}>{handleLeafRight(child,index)}</React.Fragment>
            })}
            </React.Fragment>
        } else  return <React.Fragment key={item.id}>{handleLeafRight(item, index)}</React.Fragment>
    }
    return (
        <div className="w-100">
            <div className="vstack gap-2 mt-3">
                <div className="fw-bold fs-20 hstack justify-content-center">{t("roles.set_up_role_access_level")}</div>
                <p className="fw-medium hstack justify-content-center">
                    {t("roles.permissions_description")}
                </p>
                <div className="hstack justify-content-center">
                    <h3>
                        <span className="badge bg-primary-subtle text-primary badge-border">{createInputs.role}</span>
                    </h3>
                </div>
            </div>
            
            <div className="p-4 bg-light-subtle">
                <Row className="hstack justify-content-between">
                    <Col l className="hstack justify-content-start ms-1">
                        <h5 className='fw-bold'>{t("roles.choose_permissions")}</h5>
                    </Col>
                    <Col className="hstack justify-content-end me-4">
                        <div className="form-check-info fs-16 hstack align-items-center gap-1">
                            <FormBuilder 
                                label={t("roles.expand_all_permissions")}
                                type={"checkbox"}
                                // value={value}
                                className="w-100 h-100 text-start"
                                onChange={() => {setAllexpanded(!allexpanded);setOpenpermission([])}}
                                // rest={fieldJson}
                            />
                            <div>
                                <Tooltip
                                    placement="top"
                                    isOpen={ttop}
                                    target="TooltipTop"
                                    toggle={() => {
                                        setttop(!ttop);
                                    }}
                                >
                                    {t("roles.check_this_box_to_expand_permissions_in_every_tab")}
                                </Tooltip>
                                <span id="TooltipTop">
                                    <i className="ri-information-line icon-xs icon-dark cursor-pointer" />
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} xxl={3} lg={3}>
                        <Card>
                            {accessData && accessData.length ? (
                                accessData.map((item: any, index: any) => (
                                    <React.Fragment key={item.id}>
                                        {!item.children.length ? handleleaf(item, index) : <> {handleChildrens(item,index)}</> }                                       
                                    </React.Fragment>
                                ))
                            ) : null}
                        </Card>
                    </Col>
                    <Col md={9} xxl={9} lg={9}>
                        <TabContent activeTab={customverticalTab} className="text-muted mt-3 mt-lg-0">
                            {accessData && accessData.length ? (
                                accessData.map((item: any, index: any) => {
                                    return <React.Fragment key={item.id}>
                                    {item && item.children && item.children.length ? <>{handleChildrensRight(item, index)}</> : <>{handleLeafRight(item, index)}</>}
                                    </React.Fragment>
                                })) : null} 
                        </TabContent>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default withTranslation()(Permissions);
