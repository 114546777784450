import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import InfoEdit from './InfoEdit';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { checkTheyOwn, handleSelectValue, isJson } from 'utils';
import { withTranslation } from 'react-i18next';
import { documentDownload } from 'store/documents/action';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import FeatherIcon from "feather-icons-react";

const OverviewTab = (props: any) => {
    const { info, metaData, translater, updateAction, reducerState, detailsGetAction, model, label, createInvoice, details} = props;
    
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const [editaccess, seteditaccess] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState(false);
    const [filterFields, setfilterFields] = useState([]);

    const handleViewAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    }

    useEffect(() => {
        let filterFields: any = []
        metaData && metaData.length && metaData.map((field: any) => {
            let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : field?.valuesJson ? field.valuesJson : {}
            let permissionsJson = field?.permissionsJson && isJson(field?.permissionsJson) ? JSON.parse(field?.permissionsJson) : {}
            let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleViewAccess(permissionsJson) : true;
            if (field.valuesJson && isFieldviewaccess && item.key != 'about') return filterFields.push(item)
        })
        setfilterFields(filterFields)
    }, [metaData])

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            seteditaccess(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.[model];
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'edit') {
                            if(item.value == "they_own") {
                                if(details) {
                                    let they_own: any= checkTheyOwn(details, userProfile?.id)
                                    seteditaccess(they_own)
                                }
                            }
                            else if(item.name.toLowerCase() == 'edit') {
                                item.value == "" || item.value == "none" ? seteditaccess(false) : seteditaccess(true)
                            }
                        }

                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile, details])

    const onClickFileDownload = (documentKey: any, key: any) => {
        const handleSuccess = async (body: any) => {
            try {
                // Create a Blob from the response data
                const fileBlob = await body.blob();
    
                // Create a temporary URL for the Blob
                const url = window.URL.createObjectURL(fileBlob);
    
                // Create a temporary <a> element to trigger the download
                const tempLink = document.createElement("a");
                tempLink.href = url;
                tempLink.setAttribute("download", key); // Set the desired filename for the downloaded file
    
                // Append the <a> element to the body and click it to trigger the download
                document.body.appendChild(tempLink);
                tempLink.click();
    
                // Clean up the temporary elements and URL
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("Error downloading file:", error);
            }
        }
        const handleFailure = () => {
    
        }
        dispatch(documentDownload(documentKey, handleSuccess, handleFailure))
    }

    const DetailsList = ({ label, value, item, index, type }: any) => {
        return (
            <React.Fragment>
                <Col md={model == "templates" ? 12 : 4} lg={model == "templates" ? 12 : 4} sm={model == "templates" ? 12 : 4} Key={index}>
                    <div className="p-2 border border-dashed rounded">
                        <div className="d-flex align-items-center">
                            <div className="avatar-sm me-2">
                                <div className="avatar-title rounded bg-transparent text-success fs-24">
                                    <i className={item.icon}></i>
                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <p className=" mb-1 fs-14 fw-bold">{label} :</p>
                                {type == "texteditor" ? <div dangerouslySetInnerHTML={{ __html: value }} />
                                    : <h5 className="mb-0 fs-14" style={{overflowWrap: "anywhere"}}>
                                        {typeof info?.[item.key] !== 'object' ? value : ''}
                                    </h5>}
                            </div>
                        </div>
                    </div>
                </Col>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Row>
                <Col xl={12} lg={12}>
                    <Card>
                        <CardHeader className='hstack align-items-center'>
                            <Col className='hstack justify-content-start p-0 ps-3 align-items-end'>
                                <h4>{props.t(`${translater}.overview_title`)}</h4>
                            </Col>
                            <Col className='hstack justify-content-end gap-2'>
                                {createInvoice && createInvoice()}
                                {
                                    isEdit ? 
                                    <Button
                                    color="danger"
                                    size='sm'
                                    onClick={() => setIsEdit(false)}
                                    className="btn-label py-2">
                                        <i className="ri-close-fill label-icon align-middle fs-16 me-2"></i>
                                        {props.t(`${translater}.close`)}
                                    </Button>   
                                    :
                                    editaccess ?
                                    <Button
                                        type="button"
                                        size='sm'
                                        className="btn-label py-2"
                                        color="primary"
                                        onClick={() => setIsEdit(true)}
                                    >
                                        <i className="ri-pencil-fill label-icon align-middle fs-16 me-2"></i>
                                        {props.t(`${translater}.update`)}
                                    </Button> : null
                                }
                            </Col>
                        </CardHeader>
                        <CardBody>
                            <div className="text-muted">
                                {info.about ? <div><h6 className="mb-3 fw-bold text-uppercase">Summary</h6>
                                    <p>{info.about}</p></div> : null}

                                <div className="border-top border-top-dashed p-3">
                                    {
                                        isEdit ? <InfoEdit label={label} model={model} updateAction={updateAction} translater={translater} reducerState={reducerState} detailsGetAction={detailsGetAction} /> :
                                            <Row className="gy-3">
                                                {filterFields && filterFields.length ? filterFields.map((item: any, key: any) => {
                                                    let rendervalue = info?.[item.key];
                                                    if (item?.mergeColumns?.columns) {
                                                        let value = item?.mergeColumns?.columns
                                                        let values = value.split(",")
                                                        let newValue = "";
                                                        values?.length && values.map((item: any) => {
                                                            newValue = info?.[item] ? `${newValue ? newValue : ""} ${info?.[item]}${item.mergeColumns?.separator || " "}` : ""
                                                        })
                                                        rendervalue = newValue;
                                                    }   
                                                    if(item.tableRenderType == 'hyperlink' && info?.[item.key] && info?.[item.key] !== "NA") {
                                                        rendervalue = info?.[item.key]
                                                    } 
                                                    else if (item.type === 'file' && info?.[item.key] && info?.[item.key] !== "NA" && typeof info?.[item.key] !== 'object' ) {
                                                        rendervalue =  <div className='ms-3'>
                                                            <FeatherIcon icon="download" className="icon-xs text-primary cursor-pointer" onClick={() => onClickFileDownload(info?.[item.key], `${item.key}_${info?.id}`)} />
                                                        </div>
                                                    }
                                                    else if(item.tableRenderType == 'tag') {            
                                                        let value = info?.[item.key]
                                                        let intakearray = value?.split(",")
                                                        rendervalue = <div>
                                                            {intakearray?.length && intakearray.map((item: any) => {                                          
                                                                return <div className='ms-0'>
                                                                    <span className="badge bg-success">{handleSelectValue(item, item.values)}</span>
                                                                </div>
                                                            })}
                                                        </div>
                                                    } 
                                                    else if(item.isMulti  || item.type == 'selectboxes') {            
                                                        let value = info?.[item.key]
                                                        let arr = value && value.length && value.map((item:any) => {
                                                            if(item?.value) return item.value
                                                            else return item
                                                        })
                                                        rendervalue = "";
                                                            if(arr && arr.length) {
                                                                rendervalue = arr.map((arritem: any, index: any) => {
                                                                    let label = handleSelectValue(arritem, item.values);   
                                                                    return `${label}${index+1 !== arr.length ? ", " : ''}`
                                                                })
                                                            }
                                                            
                                                    }                                                            
                                                    else if (item.optionLabel) {
                                                        rendervalue = info?.[item.key]?.[item.optionLabel] ? info?.[item.key]?.[item.optionLabel] : 'NA'
                                                    }         
                                                    else if (item.type == 'select' || item.type == 'radio' || item.type == 'checboxes') {
                                                        rendervalue = handleSelectValue(info?.[item.key], item.values);
                                                    }
                                                    return <DetailsList
                                                        label={item.label}
                                                        value={rendervalue}
                                                        item={item}
                                                        key={key}
                                                        type={item.type}
                                                    />
                                                }) : null}
                                            </Row>
                                    }
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default withTranslation()(OverviewTab);