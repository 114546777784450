import React from 'react';
import { withTranslation } from 'react-i18next';
import { studentListGetRequest, studentCreateRequest, studentDelete, studentGetRequest, studentUpdateRequest, studentsMetadataGetRequest } from 'store/student/action';
import Listing from 'Components/Common/Views/Listing';
import { noteAddRequest, notesListGetRequest } from 'store/note/action';
import Notes from 'Components/Common/Views/innerComponent/Preview/PreviewNotes';

const Students = (props: any) => {    
    const { viewId, isCreateModal, setCreateOpen } = props;  
    const previewAccordianItems = [{
        title: "Notes",
        id: "notes", 
        component: Notes
    }]
    
    document.title = "DTS | Zilter";
    return ( 
        <React.Fragment>   
            <Listing 
                viewId={viewId}
                isCreateModal={isCreateModal}
                setCreateOpen={setCreateOpen}
                updateAction={studentUpdateRequest}
                getListAction={studentListGetRequest}
                deleteAction={studentDelete}
                detailsGetAction={studentGetRequest}
                metaDatagetRequest={studentsMetadataGetRequest}
                createAction={studentCreateRequest}
                model={"students"}
                reducerState={"student"}
                translater={"student"}
                previewAccordianItems={previewAccordianItems}
                noteAddRequest={noteAddRequest}
                notesListGetRequest={notesListGetRequest}
                extraNameBtn={true}
            />
        </React.Fragment>
    );
};
export default withTranslation()(Students);


