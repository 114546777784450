import {NoteActionTypes, Note} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { responseHandler } from "utils";

const { api } = config;

const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const noteGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: NoteActionTypes.GET_NOTE_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/notes/${id}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: NoteActionTypes.GET_NOTE_ERROR,
                    payload: body
                });
                // handleError(body);
            } else {
                dispatch({
                    type: NoteActionTypes.GET_NOTE_SUCCESS,
                    payload: body
                });
                // handleSuccess(body);
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
            // Handle parsing error here
        }
    };
};

const createQueryLink = (filterObject: any, page: any) => {
    const keys = Object.keys(filterObject);
    const values = Object.values(filterObject);
    let query = `${DTS_API_STUDENT}/notes`;
    for (let i = 0; i < keys.length; i++) {
        if (values[i]) query += `${keys[i]}=${values[i]}&`;
    }
    return query;
};


const createNotesQueryLink = (id: any,filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/notes/${id}?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const notesListGetRequest: AppThunk = (id: any, filterObject: any, page: any) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: NoteActionTypes.GET_NOTES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        let searchString = createNotesQueryLink(id, filterObject, page)        
        const response = await fetch(`${searchString}`, {
            method: "GET",
            headers: headers
        });
    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: NoteActionTypes.GET_NOTES_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: NoteActionTypes.GET_NOTES_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const noteDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: NoteActionTypes.DELETE_NOTE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/notes/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: NoteActionTypes.DELETE_NOTE_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: NoteActionTypes.DELETE_NOTE_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};

export const noteAddRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: NoteActionTypes.POST_NOTE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/notes`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
       
             
        const error = {
            201: {
                "message": "Note Added Successfully",
                "success": true
            },        
            200: {
                "message": "Note Added Successfully",
                "success": true
            }
        }
        await responseHandler(response, dispatch, NoteActionTypes.POST_NOTE_SUCCESS, NoteActionTypes.POST_NOTE_ERROR, error, handleSuccess, handleError);

    };
};