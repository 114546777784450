export interface DealsMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface notes   {
    id: string,
    shortNote: string,
    detailedNote: string,
    createdBy: string,
    createdAt: string,
    replies: any,
    updatedAt: string,
    deletedAt: string
}
export interface Deal {
  createdAt : string,
  createdBy : string,
  deletedAt:string,
  id : string,
  updatedAt : string,
  valuesJson:string
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    last: boolean
  }

export enum DealActionTypes {
    GET_DEALMETADATA_REQUEST = "@@deal/GET_DEALMETADATA_REQUEST",
    GET_DEALMETADATA_SUCCESS = "@@deal/GET_DEALMETADATA_SUCCESS",
    GET_DEALMETADATA_ERROR = "@@deal/GET_DEALMETADATA_ERROR",
    GET_DEAL_REQUEST = "@@deal/GET_DEAL_REQUEST",
    GET_DEAL_SUCCESS = "@@deal/GET_DEAL_SUCCESS",
    GET_DEAL_ERROR = "@@deal/GET_DEAL_ERROR",
    GET_DEALS_REQUEST = "@@deal/GET_DEALS_REQUEST",
    GET_DEALS_SUCCESS = "@@deal/GET_DEALS_SUCCESS",
    GET_DEALS_ERROR = "@@deal/GET_DEALS_ERROR",
    PUT_DEAL_REQUEST = "@@deal/PUT_DEAL_REQUEST",
    PUT_DEAL_SUCCESS = "@@deal/PUT_DEAL_SUCCESS",
    PUT_DEAL_ERROR = "@@deal/PUT_DEAL_ERROR",
    DELETE_DEAL_REQUEST = "@@deal/DELETE_DEAL_REQUEST",
    DELETE_DEAL_SUCCESS = "@@deal/DELETE_DEAL_SUCCESS",
    DELETE_DEAL_ERROR = "@@deal/DELETE_DEAL_ERROR",
    POST_DEAL_REQUEST = "@@deal/POST_DEAL_REQUEST",
    POST_DEAL_SUCCESS = "@@deal/POST_DEAL_SUCCESS",
    POST_DEAL_ERROR = "@@deal/POST_DEAL_ERROR"
}

export interface DealsState {
    readonly list: Deal[] | null;
    readonly details: Deal | null;
    readonly pagination: Pagination | null;
    readonly metaDataFields: DealsMetadata[];
    readonly loading: boolean;
    readonly dataLoading: boolean;
    readonly metaLoading: boolean;
}
