export interface ProductsMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface notes   {
    id: string,
    shortNote: string,
    detailedNote: string,
    createdBy: string,
    createdAt: string,
    replies: any,
    updatedAt: string,
    deletedAt: string
}
export interface Product {
  createdAt : string,
  createdBy : string,
  deletedAt:string,
  id : string,
  updatedAt : string,
  valuesJson:string
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    last: boolean
  }

export enum ProductActionTypes {
    GET_PRODUCTMETADATA_REQUEST = "@@product/GET_PRODUCTMETADATA_REQUEST",
    GET_PRODUCTMETADATA_SUCCESS = "@@product/GET_PRODUCTMETADATA_SUCCESS",
    GET_PRODUCTMETADATA_ERROR = "@@product/GET_PRODUCTMETADATA_ERROR",
    GET_PRODUCT_REQUEST = "@@product/GET_PRODUCT_REQUEST",
    GET_PRODUCT_SUCCESS = "@@product/GET_PRODUCT_SUCCESS",
    GET_PRODUCT_ERROR = "@@product/GET_PRODUCT_ERROR",
    GET_PRODUCTS_REQUEST = "@@product/GET_PRODUCTS_REQUEST",
    GET_PRODUCTS_SUCCESS = "@@product/GET_PRODUCTS_SUCCESS",
    GET_PRODUCTS_ERROR = "@@product/GET_PRODUCTS_ERROR",
    PUT_PRODUCT_REQUEST = "@@product/PUT_PRODUCT_REQUEST",
    PUT_PRODUCT_SUCCESS = "@@product/PUT_PRODUCT_SUCCESS",
    PUT_PRODUCT_ERROR = "@@product/PUT_PRODUCT_ERROR",
    DELETE_PRODUCT_REQUEST = "@@product/DELETE_PRODUCT_REQUEST",
    DELETE_PRODUCT_SUCCESS = "@@product/DELETE_PRODUCT_SUCCESS",
    DELETE_PRODUCT_ERROR = "@@product/DELETE_PRODUCT_ERROR",
    POST_PRODUCT_REQUEST = "@@product/POST_PRODUCT_REQUEST",
    POST_PRODUCT_SUCCESS = "@@product/POST_PRODUCT_SUCCESS",
    POST_PRODUCT_ERROR = "@@product/POST_PRODUCT_ERROR"
}

export interface ProductsState {
    readonly list: Product[] | null;
    readonly details: Product | null;
    readonly pagination: Pagination | null;
    readonly metaDataFields: ProductsMetadata[];
    readonly loading: boolean;
    readonly dataLoading: boolean;
    readonly metaLoading: boolean;
}
