export const fields = [
    {
        "label": "From",
        "key": "value",
        "placeholder": "Enter value",
        "quickCreate": true,
        "type": "number",
        "validate": {
            "required": true
        }
    },
    {
        "label": "To",
        "key": "highValue",
        "placeholder": "Enter value",
        "quickCreate": true,
        "type": "number",
        "validate": {
            "required": true
        }
    }
]