import React, { useEffect, useState } from "react";
import { Card, CardBody, Form, Button, Col } from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import moment from "moment";
import { isJson } from "utils";

const DTSFullForm = ({ onCloseClick, dataFields, btnAction, btn_text, data, md, lg, sm, xl, xs, BtnClassName, isUpdate, preformValues}: any) => {
	const filterFields: any = [];
	

	dataFields &&
		dataFields.length &&
		dataFields.map((field: any) => {
			let item = field &&
				field?.valuesJson && isJson(field.valuesJson)
				? JSON.parse(field.valuesJson): {}
			if (field?.valuesJson) return filterFields.push(item);
		});

	const [formValues, setFormValues] = useState<any>({});
	const [disabled, setdisabled] = useState<any>(true);
	const [formerror, setError] = useState<any>([]);
	const [fieldsValues, SetFieldValues] = useState<any>(null)

	const handlePrefieldData = (data: any) => {
		let newFormValues: any = {};
		const keys = Object.keys(data)
		dataFields && dataFields && dataFields.map((field: any) => {
			let item = field?.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
			keys && keys.length && keys.map((key: any) => {
				let key1 = key.toLowerCase();
				let key2 = item.key.toLowerCase();
				if(key1 == key2 || key1.includes(key2) || key2.includes(key1)) {
					newFormValues = {...newFormValues, [item.key]: data[key].toLowerCase()}
				}
			})
		})
		setFormValues(newFormValues)
	}

	const onChange = (option: any, key: any) => {
		if (key == "personal_information_document") {
			handlePrefieldData(option);
		}
		else if (typeof option == "object") {
			setFormValues({ ...formValues, [key]: option.value });
		} else setFormValues({ ...formValues, [key]: option });
	};
	
	const onSaveClick = () => {
		btnAction(formValues, "single")
	}

	const allMandatoryFieldsFilled = () => {
		for (const field of filterFields) {
			if (
				field &&
				field.validate &&
				field.validate.required &&
				!formValues[field.key]
			) {
				return false;
			}
		}
		return true;
	};

	useEffect(() => {
		if (data) {
			setFormValues(data);
			SetFieldValues(data);
		}
	}, [isUpdate, data]);
	const formdisabled = Object.keys(formerror).length === 0;
	
	return (
		<Form action="" className="d-flex flex-column justify-content-end h-100">
			<div className="live-preview hstack flex-wrap">
				{filterFields && filterFields.length
					? filterFields.map((field: any) => {
						return (
							<Col xl={xl} lg={lg} md={md} sm={sm} xs={xs} className="align-self-start">
								<FormBuilder
									placeholder={field.placeholder}
									name={field.key}
									onChange={(e: any) => onChange(e, field.key)}
									options={(field.values && field.values.reverse()) || []}
									label={field.label}
									type={field.type}
									value={formValues[field.key]}
									defaultValue={formValues[field.key]}
									resetValue={fieldsValues[field.key]}
									isEditState={true}
									actionButtons={isUpdate}
									Editable={true}
									rest={field}
									invalid={true}
									formValues={formValues}
									dataFields={filterFields}
									setdisabled={setdisabled}
									onSaveClick={onSaveClick}
									setError={setError}
									formerror={formerror}
									isTextract={true}
								/>
							</Col>
						);
					})
					: null}
			</div>
			<div className="offcanvas-footer bg-opacity-10 border-top p-3 justify-content-center hstack gap-2">
				<Button
					className={BtnClassName ? `btn btn-success ${BtnClassName}` : "btn btn-success w-100"}
					disabled={isUpdate ? false : !allMandatoryFieldsFilled() || disabled || !formdisabled}
					onClick={() => btnAction(formValues)}
				>
					{btn_text}
				</Button>
			</div>
		</Form>
	);

};

export default DTSFullForm;
