interface Column {
    column_name: string;
    column_type: string;
    is_primary_key: "PRI" | "MUL" | null;
    label: string | null;
    input_type: string | null;
    is_json_field: boolean;
    additional_details?: AdditionalDetails;
  }
  
  interface AdditionalDetails {
    id: string;
    key: string;
    model: string;
  }
  
  interface Table {
    label: string;
    value: string;
    primary?: boolean;
    additional?: {
      table_name: string;
      schema_name: string;
      type: string;
      columns: Column[];
    };
  }
  
  interface Relationship {
    primary_table: string;
    secondary_table: string;
    primary_key: string;
    secondary_key: string;
    join_type: string;
    primary_field_json: boolean;
    secondary_field_json: boolean;
    intermediate_table?: string;
    intermediate_primary_key?: string;
    intermediate_secondary_key?: string;
  }
  
  interface ComplexRelationship {
    main_table: string;
    relationships: Relationship[];
  }
  
  interface PathNode {
    table: string;
    column: string;
    column_type: string;
    is_json_field: boolean;
  }
  
  type Graph = Record<string, PathNode[]>;
  
  const buildRelationships = (tables: Table[]): ComplexRelationship => {
    const tableMap: Record<string, Table> = {};
    tables.forEach((table) => {
      tableMap[table.value] = table;
    });
  
    // Identify the primary table
    const primaryTable = tables.find((table) => table.primary === true);
    if (!primaryTable) {
      throw new Error("No primary table specified.");
    }
    const primaryTableName = primaryTable.value;
  
    // Utility function to find the closest matching table name
    const findClosestTableName = (modelName: string): string => {
      if (tableMap[modelName]) return modelName;
  
      const singular = modelName.endsWith("s") ? modelName.slice(0, -1) : modelName;
      const plural = modelName.endsWith("s") ? modelName : `${modelName}s`;
  
      if (tableMap[singular]) return singular;
      if (tableMap[plural]) return plural;
  
      return modelName;
    };
  
    // Step 1: Create graph structure
    const graph: Graph = {};
    tables.forEach((table) => {
      if (!table.additional) return;
  
      const tableName = table.value;
      const columns = table.additional.columns;
      graph[tableName] = [];
  
      columns.forEach((column) => {
        // Handle JSON field references using dynamic model-to-table matching
        if (column.is_json_field && column.additional_details?.model) {
          const model = column.additional_details.model.toLowerCase();
          const resolvedTable = findClosestTableName(model);
          graph[tableName].push({
            table: resolvedTable,
            column: column.column_name,
            column_type: "json_field",
            is_json_field: true,
          });
        }
  
        // Handle standard foreign key relationships
        if (column.is_primary_key === "MUL") {
          tables.forEach((relatedTable) => {
            if (tableName !== relatedTable.value && relatedTable.additional) {
              relatedTable?.additional?.columns.forEach((relatedColumn) => {
                if (relatedColumn.is_primary_key === "PRI" && relatedColumn.column_name === column.column_name) {
                  graph[tableName].push({
                    table: relatedTable.value,
                    column: column.column_name,
                    column_type: "foreign_key",
                    is_json_field: false,
                  });
                }
              });
            }
          });
        }
  
        // Handle specific foreign key relationships
        if (column.column_name.endsWith("_id")) {
          const relatedTableName = column.column_name.replace("_id", "");
          if (tableMap[relatedTableName]) {
            graph[tableName].push({
              table: relatedTableName,
              column: column.column_name,
              column_type: "foreign_key",
              is_json_field: false,
            });
          }
        }
      });
    });
  
    const relationships: Relationship[] = [];
  
    // Step 2: Direct Relationship Detection
    const directRelationships: Set<string> = new Set();
  
    tables.forEach((primaryTable) => {
      const primaryName = primaryTable.value;
  
      tables.forEach((secondaryTable) => {
        const secondaryName = secondaryTable.value;
        if (primaryName === secondaryName) return;
  
        graph[primaryName]?.forEach((neighbor) => {
          if (neighbor.table === secondaryName) {
            directRelationships.add(`${primaryName}-${secondaryName}`);
            relationships.push({
              primary_table: primaryName,
              secondary_table: secondaryName,
              primary_key: neighbor.column,
              secondary_key: "id",
              join_type: "LEFT",
              primary_field_json: neighbor.is_json_field,
              secondary_field_json: false,
            });
          }
        });
      });
    });
  
    // Step 3: Swap roles if necessary to ensure primary_table is always the main primary table
    const correctedRelationships: Relationship[] = relationships.map((relationship) => {
      if (relationship.secondary_table === primaryTableName) {
        return {
          primary_table: relationship.secondary_table,
          secondary_table: relationship.primary_table,
          primary_key: relationship.secondary_key,
          secondary_key: relationship.primary_key,
          join_type: relationship.join_type,
          primary_field_json: relationship.secondary_field_json,
          secondary_field_json: relationship.primary_field_json,
        };
      }
      return relationship;
    });

    const finalRelationship: ComplexRelationship = {
        main_table: primaryTableName,
        relationships: correctedRelationships
    }
  
    return finalRelationship;
  };
  
  export default buildRelationships;  