import React, { Fragment, useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import { Table, Row, Col, Button, CardBody, Input, Label, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Card } from "reactstrap";
import BreadCrumb from "../BreadCrumb";
import { Link } from "react-router-dom";


interface TableContainerProps {
    columns?: any;
    title?: any;
    tableClass: any;
    theadClass: any;
    thClass: any;
}

const TableContainer = ({
    columns,
    tableClass,
    theadClass,
    thClass,
    title
}: TableContainerProps) => {
    let rows=["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
    let dummycolumns = ["1", "2", "3", "4", "5", "6", "7", "8"]
    let placeholdercolumn = dummycolumns;
    return (
        <React.Fragment>
             {title ?  
            <Row className='hstack justify-content-between'>
                <Col md={6} sm={12} xl={6} xxl={6} lg={6}  className="hstack justify-content-start">
                    <BreadCrumb title={title} pageTitle={title} />
                </Col>
                <Col md={1} sm={5} xl={1} xxl={1} lg={1} className="hstack justify-content-end placeholder-glow">
                    <span className="btn btn-primary btn-sm disabled placeholder placeholder-sm col-12 opacity-25"></span>
                </Col>                            
            </Row>: null} 
            {title ?   
            <Row className='hstack ps-4'>
                <Col className="placeholder-glow col-2 px-0">
                    <Link to="#" className="btn btn-primary disabled placeholder col-12 px-0 rounded-0 opacity-25"></Link>
                </Col>
                <Col className="col-2 px-0">
                    <Link to="#" className="btn btn-dark disabled placeholder col-12 px-0 rounded-0 opacity-25"></Link>
                </Col>     
                <Col className="placeholder-glow col-2 px-0">
                    <Link to="#" className="btn btn-primary disabled placeholder col-12 px-0 rounded-0 opacity-25"></Link>
                </Col>              
            </Row>   : null}       
            <Row>
                <Col md={12} sm={12} xl={12} xxl={12} lg={12}>
                    <Card>
                        <CardBody className="border border-dashed border-end-0 border-start-0 vstack gap-2">
                        <Row className='hstack gap-2'>
                            <Col md={2} sm={12} xl={2} xxl={2} lg={2}>
                                <div className="placeholder-glow col-12 px-0">
                                    <Link to="#" className="btn btn-dark disabled placeholder col-12 opacity-25"></Link>
                                </div> 
                            </Col>
                            <Col className="hstack justify-content-end gap-2">  
                                    <div className="placeholder-glow col-2 px-0">
                                        <Link to="#" className="btn btn-primary disabled placeholder col-12 opacity-25"></Link>
                                    </div>  
                                    <div className="placeholder-glow col-2 px-0">
                                        <Link to="#" className="btn btn-dark disabled placeholder col-12 opacity-25"></Link>
                                    </div>  
                            </Col>                            
                        </Row>
                        <Row>
                            <Col>
                                <div className='hstack gap-2 justify-content-end'>
                                    <div className='vstack gap-2'>                                   
                                        <div className="col-12 px-0">
                                            <Link to="#"  tabIndex={1} className="btn btn-dark disabled placeholder col-12 opacity-25"></Link>
                                        </div> 
                                        <div className="col-12 px-0">
                                            <Link to="#"  tabIndex={1} className="btn btn-dark disabled placeholder col-12 opacity-25"></Link>
                                        </div> 
                                    </div>
                                    <div className='vstack gap-2'>                                   
                                        <div className="col-12 px-0">
                                            <Link to="#"  tabIndex={1} className="btn btn-dark disabled placeholder col-12 opacity-25"></Link>
                                        </div> 
                                        <div className="col-12 px-0">
                                            <Link to="#"  tabIndex={1} className="btn btn-dark disabled placeholder col-12 opacity-25"></Link>
                                        </div> 
                                    </div>
                                    <div className='vstack gap-2'>                                   
                                        <div className="col-12 px-0">
                                            <Link to="#"  tabIndex={1} className="btn btn-dark disabled placeholder col-12 opacity-25"></Link>
                                        </div> 
                                        <div className="col-12 px-0">
                                            <Link to="#"  tabIndex={1} className="btn btn-dark disabled placeholder col-12 opacity-25"></Link>
                                        </div> 
                                    </div>
                                </div>
                            </Col>  
                            <Col className="hstack justify-content-end gap-2">  
                                <div className="col-2 px-0">
                                    <Link to="#"  tabIndex={1} className="btn btn-danger btn-sm disabled placeholder placeholder-sm col-12 opacity-25"></Link>
                                </div>  
                                <div className="col-2 px-0">
                                    <Link to="#"  tabIndex={1} className="btn btn-success btn-sm disabled placeholder placeholder-sm col-12 opacity-25"></Link>
                                </div>  
                            </Col>    
                        </Row>
                        <Row className='students-table'>
                        <Table responsive={true} hover className={tableClass + 'm-0'}>
                            <thead className={theadClass}>
                                <tr>
                                    {placeholdercolumn.map((column: any, index: any) => {
                                        return <th className={thClass + " fw-bold placeholder-glow"}>
                                                <span className="placeholder placeholder-sm col-12"></span>
                                        </th>
                                    })}</tr>
                            </thead>
                            <tbody >
                                {rows.map((item: any,index: any) => {
                                    return <tr>
                                    {placeholdercolumn.map((column: any, index: any) => {
                                        return <td key={index} className="placeholder-glow">
                                            <span className="placeholder  placeholder-xs col-12">{column}</span>
                                        </td>
                                    })}</tr>}
                                )}
                            </tbody>
                        </Table>
                        </Row>                            
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default withTranslation()(TableContainer);