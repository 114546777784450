export interface DocumentsMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface Document {
  createdAt : string,
  createdBy : string,
  deletedAt:string,
  id : string,
  updatedAt : string,
  valuesJson:string
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    last: boolean
  }

export enum DocumentActionTypes {
    GET_DOCUMENTMETADATA_REQUEST = "@@document/GET_DOCUMENTMETADATA_REQUEST",
    GET_DOCUMENTMETADATA_SUCCESS = "@@document/GET_DOCUMENTMETADATA_SUCCESS",
    GET_DOCUMENTMETADATA_ERROR = "@@document/GET_DOCUMENTMETADATA_ERROR",
    GET_DOCUMENT_REQUEST = "@@document/GET_DOCUMENT_REQUEST",
    GET_DOCUMENT_SUCCESS = "@@document/GET_DOCUMENT_SUCCESS",
    GET_DOCUMENT_ERROR = "@@document/GET_DOCUMENT_ERROR",
    GET_DOCUMENTS_REQUEST = "@@document/GET_DOCUMENTS_REQUEST",
    GET_DOCUMENTS_SUCCESS = "@@document/GET_DOCUMENTS_SUCCESS",
    GET_DOCUMENTS_ERROR = "@@document/GET_DOCUMENTS_ERROR",
    PUT_DOCUMENT_REQUEST = "@@document/PUT_DOCUMENT_REQUEST",
    PUT_DOCUMENT_SUCCESS = "@@document/PUT_DOCUMENT_SUCCESS",
    PUT_DOCUMENT_ERROR = "@@document/PUT_DOCUMENT_ERROR",
    DELETE_DOCUMENT_REQUEST = "@@document/DELETE_DOCUMENT_REQUEST",
    DELETE_DOCUMENT_SUCCESS = "@@document/DELETE_DOCUMENT_SUCCESS",
    DELETE_DOCUMENT_ERROR = "@@document/DELETE_DOCUMENT_ERROR",
    DOWNLOAD_DOCUMENT_REQUEST = "@@document/DOWNLOAD_DOCUMENT_REQUEST",
    DOWNLOAD_DOCUMENT_SUCCESS = "@@document/DOWNLOAD_DOCUMENT_SUCCESS",
    DOWNLOAD_DOCUMENT_ERROR = "@@document/DOWNLOAD_DOCUMENT_ERROR",
    POST_DOCUMENT_REQUEST = "@@document/POST_DOCUMENT_REQUEST",
    POST_DOCUMENT_SUCCESS = "@@document/POST_DOCUMENT_SUCCESS",
    POST_DOCUMENT_ERROR = "@@document/POST_DOCUMENT_ERROR",
    POST_PROFILEPICTURE_ERROR = "@@picture/POST_PROFILEPICTURE_ERROR",
    POST_PROFILEPICTURE_SUCCESS = "@@picture/POST_PROFILEPICTURE_SUCCESS",
    POST_PROFILEPICTURE_REQUEST = "@@picture/POST_PROFILEPICTURE_REQUEST",
    DOWNLOAD_STUDENT_BULKENTRY_REQUEST = "@@studentbulkentry/DOWNLOAD_STUDENT_BULKENTRY_REQUEST",
    DOWNLOAD_STUDENT_BULKENTRY_SUCCESS = "@@studentbulkentry/DOWNLOAD_STUDENT_BULKENTRY_SUCCESS",
    DOWNLOAD_STUDENT_BULKENTRY_ERROR = "@@studentbulkentry/DOWNLOAD_STUDENT_BULKENTRY_ERROR",
    DOWNLOAD_APPLICATION_BULKENTRY_REQUEST = "@@applicationbulkentry/DOWNLOAD_APPLICATION_BULKENTRY_REQUEST",
    DOWNLOAD_APPLICATION_BULKENTRY_SUCCESS = "@@applicationbulkentry/DOWNLOAD_APPLICATION_BULKENTRY_SUCCESS",
    DOWNLOAD_APPLICATION_BULKENTRY_ERROR = "@@applicationbulkentry/DOWNLOAD_APPLICATION_BULKENTRY_ERROR",
    DOWNLOAD_BULKENTRY_REQUEST = "@@document/DOWNLOAD_BULKENTRY_REQUEST",
    DOWNLOAD_BULKENTRY_SUCCESS = "@@document/DOWNLOAD_BULKENTRY_SUCCESS",
    DOWNLOAD_BULKENTRY_ERROR = "@@document/DOWNLOAD_BULKENTRY_ERROR"
}

export interface DocumentState {
  readonly list: Document[] | null;
  readonly details: Document | null;
  readonly pagination: Pagination | null;
  readonly metaDataFields: DocumentsMetadata[];
  readonly loading: boolean;
  readonly dataLoading: boolean;
  readonly metaLoading: boolean;
}
