import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import Form from "Components/Common/FormBuilder/UpdateForm";
import { useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { ViewListGetRequest } from "store/views/action";
import { checkTheyOwn, isJson } from "utils";

interface FormField {
    name: string;
    label: string;
    type: string;
    options?: { value: string; label: string }[];
}


const UpdateInfo = ({ fields, details, model, getDetailsAction, updateAction, metaDataFields }: any) => {
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const [statusMetaData, setStatusMetaData] = useState<any>([]);
    const [edit, setEdit] = useState<any>(false);
    const [data, setData] = useState<any>({});
    const [studentId, setStudentId] = useState('')
    const [applicationId, setApplicationId] = useState(null)

    const onUpdate = (keyValue: any, type?: any) => {
        const handleSuccess = (body: any) => {
            dispatch(getDetailsAction(id))
        }
        dispatch(updateAction(id, keyValue, handleSuccess, () => { }))
        
    }
    useEffect(() => {
        dispatch(ViewListGetRequest());
    }, [])

    useEffect(() => {
        handleGetList()
    }, [details])

    const handleGetList = () => {
        if(model == "students") {
            if (details && details.id) {
                setStudentId(details.id)
            }
        }
        else {
            if (details && details.id) {
                setApplicationId(details.id)
                let studentsId = details && details?.valuesJson.student && details?.valuesJson.student.id ? details?.valuesJson.student.id : "";
                setStudentId(studentsId)
            }
        }     
    }

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setEdit(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.[model];
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'edit') {
                            if(item.value == "they_own") {
                                if(details) {
                                    let they_own = checkTheyOwn(details, userProfile?.id)
                                    setEdit(they_own)
                                }
                            }
                            else if (item.name.toLowerCase() == 'edit') {
                                item.value == "" || item.value == "none" ? setEdit(false) : setEdit(true)
                            }
                        }

                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile, details])

    const handleViewAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    }
    const handleEditAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return false;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    };

    useEffect(() => {
        let newstatusMetaData: any = []
        metaDataFields && metaDataFields.map((field: any) => {
            let statusField = field?.valuesJson && isJson(field?.valuesJson) ? JSON.parse(field?.valuesJson) : {}               
            let permissionsJson = field?.permissionsJson && isJson(field?.permissionsJson) ? JSON.parse(field?.permissionsJson) : {}
            let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleViewAccess(permissionsJson) : true;
            let isFieldEditaccess = permissionsJson && permissionsJson.permissions ? handleEditAccess(permissionsJson) : true;
            if (isFieldviewaccess && !statusField?.process_flows) {
                if (isFieldEditaccess) {
                    let newField = { ...field, isEditAccess: true }
                    return newstatusMetaData.push(newField)
                }
                return newstatusMetaData.push(field)
            }
        })
        setStatusMetaData(newstatusMetaData)
    }, [metaDataFields])

    const handleselectValue = (value: any, options: any) => {
        if (options && options.length) {
            const foundItem = options.find((item: any) => item.value === value);
            if (foundItem) {
                return foundItem.label;
            }
        }
    }


    // useEffect(() => {
    //     if(details && details.id) {
    //         let recordDetails = details.valuesJson;
    //         let newData: any = {}
    //         statusMetaData && statusMetaData.map((item: any) => {
    //             let field = item?.valuesJson && isJson(item?.valuesJson) ? JSON.parse(item?.valuesJson) : {}
    //             let rendervalue = recordDetails[field.key];
    //             if (field?.mergeColumns?.columns) {
    //                 let value = field?.mergeColumns?.columns
    //                 let values = value.split(",")
    //                 let newValue = "";
    //                 values?.length && values.map((item: any) => {
    //                     newValue = recordDetails?.[item] ? `${newValue ? newValue : ""} ${recordDetails?.[item]}${field.mergeColumns?.separator || " "}` : ""
    //                 })
    //                 rendervalue = newValue;
    //             }
    //             if (field.type == 'select' || field.type == 'radio' || field.type == 'checboxes') {
    //                 rendervalue = handleselectValue(recordDetails?.[field.key], field.values);
    //             }
    //             else if (field.optionLabel) {
    //                 rendervalue = recordDetails?.[field.key] && recordDetails?.[field.key]?.[field.optionLabel] ? recordDetails?.[field.key]?.[field.optionLabel] : 'NA'
    //             }
    //             newData[field.key]= rendervalue;
    //         })
    //         setData({...newData,id:details.id, studentId:details.id})
    //     }
    // }, [details, statusMetaData])

   return (
        <div>
            <form action="" className="d-flex flex-column justify-content-end h-100">
                <div className="live-preview vstack gap-2">
                    <div className="live-preview vstack gap-2">
                        <SimpleBar autoHide={false} className="simplebar-track-dark pe-2 pb-5" style={{ maxHeight: "500px" }}>
                            <Form dataFields={statusMetaData} onUpdate={onUpdate} showbtn={false} data={{...details, studentId: studentId, applicationId:applicationId}} formfor={[model]} fields={fields} isEditAccess={edit} />
                        </SimpleBar>
                    </div>
                </div>
            </form>
        </div>

    );
};

export default UpdateInfo;
