import BreadCrumb from 'Components/Common/BreadCrumb';
import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    Button,
    Col,
    Container,
    Nav,
    NavItem,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Breadcrumb,
    Card,
    CardBody,
} from 'reactstrap';
import avtarImage2 from '../../../assets/images/users/avatar-1.jpg';
import avtarImage3 from '../../assets/images/users/user-dummy-img.jpg';
import classnames from 'classnames';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Preferences from './Preferences';
import Access from './Access';
import FeatherIcon from "feather-icons-react";
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { userGetRequest } from 'store/user/action';
import { permissionsGetRequest } from 'store/role/action';
import UserProfile from './UserProfile';


interface RolesProps extends WithTranslation {
    // onCloseClick: (e: any) => void;
    // show: boolean;
    userInputs: any;
    setUserInputs: (e: any) => void;
    // id: any;
    // setId?: (e: any) => void;
}
type RolePermission = {
    id: string;
    name: string;
  };
  
  type UserRole = {
    rolePermissions: RolePermission[];
  };
  
  type User = {
    role: UserRole;
  };
const UserDetailsModal = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const user = useSelector((state: ApplicationState) => state.user.user);
    const [passedarrowSteps, setPassedarrowSteps] = useState<number[]>([1]);
    const [userData, setUserData] = useState<any>()
    const [customActiveTab, setcustomActiveTab] = useState<any>(1);
    const toggleCustom = (tab: any) => {
        if (customActiveTab !== tab) {
            const modifiedSteps = [...passedarrowSteps, tab];
            if (tab >= 1 && tab <= 3) {
                setcustomActiveTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    };

    useEffect(() => {
        if(id && user) (dispatch(userGetRequest(id)))
            setUserData(user)
    }, [id])
    const goBack = () => {
		navigate(-1);
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title="Users" pageTitle="User Profile" />
                    <div className='w-100 mx-3'>
                        <Card className='px-3'>

                            <div>
                                <Button color="primary" className="btn-sm m-2 w-auto hstack btn-ghost-primary align-items-center fw-bold fs-16" onClick={() => goBack()}>
                                    <FeatherIcon icon="arrow-left" className="icon-xs" />&nbsp;Back to all User
                                </Button>
                            </div>
                           <UserProfile />
                        </Card>
                    </div>
                    <div>




                    </div>
                </Container>
            </div>
        </>
    );
};

export default withTranslation()(UserDetailsModal);