export interface ContractsMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}
export interface Contracts {
  createdAt: string,
  createdBy: string,
  deletedAt: string,
  id: string,
  updatedAt: string,
  valuesJson: string
}

export interface Pagination {
  size: number,
  page: number,
  pages: number,
  total: number,
  elements: number,
  last: boolean
}

export enum ContractsActionTypes {
  GET_CONTRACTMETADATA_REQUEST = "@@contract/GET_CONTRACTMETADATA_REQUEST",
  GET_CONTRACTMETADATA_SUCCESS = "@@contract/GET_CONTRACTMETADATA_SUCCESS",
  GET_CONTRACTMETADATA_ERROR = "@@contract/GET_CONTRACTMETADATA_ERROR",
  GET_CONTRACT_REQUEST = "@@contract/GET_CONTRACT_REQUEST",
  GET_CONTRACT_SUCCESS = "@@contract/GET_CONTRACT_SUCCESS",
  GET_CONTRACT_ERROR = "@@contract/GET_CONTRACT_ERROR",
  GET_CONTRACTS_REQUEST = "@@contract/GET_CONTRACTS_REQUEST",
  GET_CONTRACTS_SUCCESS = "@@contract/GET_CONTRACTS_SUCCESS",
  GET_CONTRACTS_ERROR = "@@contract/GET_CONTRACTS_ERROR",
  PUT_CONTRACT_REQUEST = "@@contract/PUT_CONTRACT_REQUEST",
  PUT_CONTRACT_SUCCESS = "@@contract/PUT_CONTRACT_SUCCESS",
  PUT_CONTRACT_ERROR = "@@contract/PUT_CONTRACT_ERROR",
  DELETE_CONTRACT_REQUEST = "@@contract/DELETE_CONTRACT_REQUEST",
  DELETE_CONTRACT_SUCCESS = "@@contract/DELETE_CONTRACT_SUCCESS",
  DELETE_CONTRACT_ERROR = "@@contract/DELETE_CONTRACT_ERROR",
  POST_CONTRACT_REQUEST = "@@contract/POST_CONTRACT_REQUEST",
  POST_CONTRACT_SUCCESS = "@@contract/POST_CONTRACT_SUCCESS",
  POST_CONTRACT_ERROR = "@@contract/POST_CONTRACT_ERROR",
}

export interface ContractsState {
  readonly list: Contracts[] | null;
  readonly details: Contracts | null;
  readonly pagination: Pagination | null;
  readonly metaDataFields: ContractsMetadata[];
  readonly loading: boolean;
  readonly dataLoading: boolean;
  readonly metaLoading: boolean;
}
