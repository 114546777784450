import { Reducer } from "redux";
import { CallActionTypes, CallState } from "./types";


export const initialState: CallState =
{
    allCalls: [],
    calls: [],
    call: null,
    pagination: null,
    sentiment: null,
    callloading: false
}
const reducer: Reducer<CallState> = (state: any = initialState, action: any) => {
    switch (action.type) {

        case CallActionTypes.GET_CALL_REQUEST: {
            return { ...state, loading: true };
        }
        case CallActionTypes.GET_CALL_SUCCESS: {
            return {
                ...state,
                loading: false,
                call: action.payload
            };
        }
        case CallActionTypes.GET_CALL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case CallActionTypes.GET_CALLS_REQUEST: {
            return { ...state, callloading: true, calls:[] };
        }
        case CallActionTypes.GET_CALLS_SUCCESS: {
            const page = action.payload.number || 1; // Set page to 1 if undefined
            const total = action.payload.totalPages;
            return {
                ...state,
                callloading: false,
                calls: action.payload.content,
                pagination: {
                    page,
                    total,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    offset: action.payload.pageable.offset

                }
            };
        }
        case CallActionTypes.GET_CALLS_ERROR: {
            return { ...state, callloading: false, errors: action.payload, calls:[] };
        }
        case CallActionTypes.DELETE_CALL_REQUEST: {
            return { ...state, loading: true };
        }
        case CallActionTypes.DELETE_CALL_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case CallActionTypes.DELETE_CALL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        
        case CallActionTypes.GET_ALLCALLS_REQUEST: {
            return { ...state, loading: true };
        }
        case CallActionTypes.GET_ALLCALLS_REQUEST: {
            const page = action.payload.number || 1; // Set page to 1 if undefined
            const total = action.payload.totalPages;
            return {
                ...state,
                loading: false,
                allCalls: action.payload.content,
                pagination: {
                    page,
                    total,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    offset: action.payload.pageable.offset

                }
            };
        }
        case CallActionTypes.GET_ALLCALLS_REQUEST: {
            return { ...state, loading: false, errors: action.payload };
        }
        case CallActionTypes.CALLS_DOWNLOAD_DOCUMENT_REQUEST: {
            return { ...state, loading: true };
        }
        case CallActionTypes.CALLS_DOWNLOAD_DOCUMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case CallActionTypes.CALLS_DOWNLOAD_DOCUMENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        } 
        case CallActionTypes.CALLS_SENTIMENT_REQUEST: {
            return { ...state, loading: true };
        }
        case CallActionTypes.CALLS_SENTIMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                sentiment: action.payload
            };
        }
        case CallActionTypes.CALLS_SENTIMENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }   
        default:
            return state; // Return the current state for unrecognized actions

    }
};

export { reducer as callReducer };
