
const frequencies = [{
    label: "Daily",
    value: "DAY"
}, {
    label: "Weekly",
    value: "WEEK"
}, {
    label: "Monthly",
    value: "MONTH"
}, {
    label: "Quarterly",
    value: "QUARTER"
}, {
    label: "Yearly",
    value: "YEAR"
}] 

export { frequencies };