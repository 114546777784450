import BreadCrumb from 'Components/Common/BreadCrumb';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Button,
    Col,
    Container,
    Nav,
    NavItem,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from 'reactstrap';
import avtarImage2 from '../../assets/images/users/avatar-1.jpg';
import avtarImage3 from '../../assets/images/users/user-dummy-img.jpg';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Preferences from './Preferences';
import Access from './Access';
import FeatherIcon from "feather-icons-react";
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';


interface RolesProps extends WithTranslation {
    onCloseClick: () => void;
    show: boolean;
    userInputs: any;
    setUserInputs: (e: any) => void;
}
const Roles: React.FC<RolesProps> = ({ onCloseClick, show, t, userInputs, setUserInputs }: RolesProps) => {

    const [activeArrowTab, setactiveArrowTab] = useState<number>(1);
    const [passedarrowSteps, setPassedarrowSteps] = useState<number[]>([1]);
    const googleResponse = useSelector((state: ApplicationState) => state.calendarSync.data);
    const [picture, setPicture] = useState<string>("")
    const toggleArrowTab = (tab: number) => {
        if (activeArrowTab !== tab) {
            const modifiedSteps = [...passedarrowSteps, tab];
            if (tab >= 1 && tab <= 3) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    };

    useEffect(() => {
        if(googleResponse) setPicture(googleResponse[0].socialDataDTO.userProfile.picture)
       },[googleResponse])

    return (
        <React.Fragment>
            <Offcanvas
                direction="end"
                isOpen={show}
                id="offcanvasWithBothOptions"
                toggle={onCloseClick}
                className="w-100"
            >
                <OffcanvasBody id="offcanvasWithBothOptions">
                    <Row className="w-100 mb-4 hstack justify-content-center">
                        <Col lg={10} className="justify-content-between">
                            <Button color="primary" className="w-auto hstack btn-ghost-primary align-items-center fw-bold fs-16" onClick={onCloseClick}>                    
                                <FeatherIcon  icon="arrow-left" className="icon-xs" />&nbsp;Back to all User
                            </Button>
                        </Col>
                    </Row>
                    <Row className="w-100 hstack justify-content-center">
                        <Col lg={10} className="hstack justify-content-between">
                            <div className="d-flex gap-4 align-items-center">
                                <div className="flex-shrink-0">
                                    <img src={picture} alt="" className="avatar-md rounded-circle" />
                                </div>
                                <div className="vstack gap-1 align-self-center flex-grow-1">
                                    <h3>{userInputs.first_name} {userInputs.last_name}</h3>
                                    <h6>{userInputs.status} | {userInputs.email}</h6>
                                </div>
                            </div>
                            <div>
                            <Button color="primary" className="w-auto">
                                Actions
                            </Button>
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <div className="hstack step-arrow-nav mt-4 mb-4">
                        <Row className="w-100 hstack justify-content-center">
                            <Col lg={10} className="hstack justify-content-between">
                                <Nav
                                    className="nav-pills"
                                    role="tablist"
                                >
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            id="basic-info"
                                            onClick={() => { toggleArrowTab(1); }}
                                            className={classnames({
                                                active: activeArrowTab === 1
                                            })}
                                        >
                                            {/* {t('roles.role_name')} */}
                                            Access
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            id="field-type"
                                            onClick={() => { toggleArrowTab(2); }}
                                            className={classnames({
                                                active: activeArrowTab === 2
                                            })}
                                        >
                                            Preferences
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </div>
                    <div className="step-arrow-nav mt-4 mb-4">
                        <Row className="w-100 hstack justify-content-center">
                            <Col lg={10} className="justify-content-between">
                                <TabContent activeTab={activeArrowTab}>
                                    <TabPane id="basic-info" tabId={1}>
                                        <Row className="justify-content-start">
                                            <Col lg={12}>
                                                <div className="vstack justify-content-start">
                                                    <Access />
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane id="field-type" tabId={2}>
                                        <Row className="justify-content-center">
                                            <Col lg={12}>
                                                <div className="justify-content-center">
                                                    <Preferences setUserInputs={setUserInputs} userInputs={userInputs} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </div>
                </OffcanvasBody>
            </Offcanvas>
        </React.Fragment>
    );
};

export default withTranslation()(Roles);