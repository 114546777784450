import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Listing from 'Components/Common/Views/Listing';
import { productCreateRequest, productDelete, productGetRequest, productListGetRequest, productsMetadataGetRequest } from 'store/products/action';

const Products = (props: any) => {
    const { viewId, isCreateModal, setCreateOpen } = props;    
    return (
        <Listing 
            viewId={viewId}
            isCreateModal={isCreateModal}
            setCreateOpen={setCreateOpen}
            getListAction={productListGetRequest}
            deleteAction={productDelete}
            detailsGetAction={productGetRequest}
            metaDatagetRequest={productsMetadataGetRequest}
            createAction={productCreateRequest}
            model={"products"}
            reducerState={"products"}
            translater={"product"}
        />
    );
};
export default withTranslation()(Products);


