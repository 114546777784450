import type { Identifier, XYCoord } from 'dnd-core'
import type { FC } from 'react'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Button, Input } from 'reactstrap';

export interface DNDRowProps {
  id: any
  field: any
  selectedFields: any
  index: number
  moveStatus: (dragIndex: number, hoverIndex: number, isDragging: boolean) => void
  markMandatory: (value: any) => void
  removeField: (value: any) => void
}

interface DragItem {
  index: number
  id: string
  type: string
}

export const DNDField: FC<DNDRowProps> = ({ id, field, selectedFields, index, moveStatus, markMandatory, removeField }) => {
  const ref = useRef<HTMLTableRowElement>(null)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'tr',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveStatus(dragIndex, hoverIndex, isDragging)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'tr',
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <div ref={ref} key={index} data-handler-id={handlerId} style={{opacity}} 
        className={index == selectedFields.length - 1 ?  "list-group-item d-flex list-group-fill justify-content-between align-items-center" :
            "list-group-item border-bottom d-flex list-group-fill justify-content-between align-items-center"}>
        <div className="d-flex align-items-center">
            <div className="flex-shrink-0 me-3">
                <div className="task-handle bg-dark-subtle rounded px-2 py-1">: :</div>
            </div>
            <div className="form-check form-switch">
                <Input onChange={() => markMandatory(field)} className="form-check-input" type="checkbox" role="switch" checked={field?.mandatory} />
            </div>
            {field.label}
        </div>
        <Button
            size='sm'
            onClick={() => removeField(field)}
            color="danger"
            className="btn-icon-custom btn-soft-danger"
        >
            <i className="ri-close-line label-icon align-middle fs-5 fw-semibold"></i>
        </Button>
    </div>
  )
}
