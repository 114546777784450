import React, { useState } from "react";
import { TextractClient, AnalyzeDocumentCommand } from "@aws-sdk/client-textract";
import { Container, Row } from "reactstrap";
import { Buffer } from "buffer";

import config from "../../../config";

const { AWS_API } = config.api;

globalThis.Buffer = Buffer;

const Textract = () => {
	const [src, setSrc] = useState("");
	const [data, setData] = useState({});
	const [file, setFile] = useState(null);

	const onSelectFile = (e: any) => {
		if (!e.target.files || e.target.files.length === 0) {
			return;
		}
		const file = e.target.files[0];
		setFile(file)
	};

	const onRunOCR = async () => {
		if (!file) {
		  alert('Please select a file to upload.');
		  return;
		}
	
		const formData = new FormData();
		formData.append('file', file);
	
		try {
		  const response = await fetch(`${AWS_API}/quicksight/upload`, {
			method: 'POST',
			body: formData,
		  });
	
		  if (!response.ok) {
			throw new Error('Failed to upload file.');
		  }
	
		  const data = await response.json();
		  setData(data);
		} catch (error) {
		  console.error('Error uploading file:', error);
		  setData({});
		  alert('Error uploading file. Please try again.');
		}
	  };
	

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<Row>
						<div className="App">
							<div>
								<input
									className="inputfile"
									id="file"
									type="file"
									name="file"
									onChange={onSelectFile}
								/>
							</div>
							<div>
								<button onClick={onRunOCR} style={{ margin: "10px" }}>
									Run OCR
								</button>

								<div style={{ border: "1px" }}>
									{Object.entries(data).map(([key, value]: any, index) => (
										<div key={index}>
											<strong>{key}</strong> {value}
											<br />
										</div>
									))}
								</div>
							</div>
						</div>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Textract;
