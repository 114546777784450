import { useEffect, useState } from 'react';
import { Card, Col, Row, Button, Input } from 'reactstrap';
import _ from 'lodash';
import CoursesPreview from './innerComponent/CoursesPreview';
import { withTranslation } from 'react-i18next';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { useParams } from 'react-router-dom';
import { CreateRecommendations, recommendApplicationList } from 'store/recommendation/action';
import { toast } from 'react-toastify';
import { courseCreateRequest, courseDelete, courseGetRequest, courseListGetRequest, coursesMetadataGetRequest } from 'store/course/action';
import Listing from 'Components/Common/Views/Listing';
import { isJson } from 'utils';


const Courses = (props: any) => {
    const { isSubAgent } = props;

    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const recommendLoading = useSelector((state: ApplicationState) => state.recommend.loading);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const metaData = useSelector((state: ApplicationState) => state.courses.metaDataFields);
    const { viewId, isCreateModal, setCreateOpen } = props;
    const [ create, setCreate] = useState(false);

    const [link, setLink] = useState<any>(null);
    const [previewModal, setpreview] = useState<boolean>(false);
    const [linktrue, setlinktrue] = useState<boolean>(false)
    const [disabled, setdisabled] = useState<boolean>(false)
    const [shortListed, setShortListed] = useState<any>([])
    const [selectedlist, setSelectedList] = useState<any>([]);
    const [checkedRows, setCheckedRows] = useState<any>({});
    const [shortlistFields, setShortlistFields] = useState<any>();

    const handleButtonClick = () => {
        navigator.clipboard.writeText(link).then(() => {
            toast('URL copied to clipboard', { position: 'top-center', hideProgressBar: true, className: 'bg-success text-white' });
            setpreview(false);
            setLink(false)
        }).catch(err => {

        });
    };

    const handleselect = (obj: any, value: boolean) => {
        if (value) {
            let newList = selectedlist;
            newList.push(obj)
            setlinktrue(true)
            setSelectedList(newList)
            let newshortListed = shortListed;
            newshortListed.push(obj.id)
            setShortListed(newshortListed)
            handleCheckboxChange(obj.id, true)
            setdisabled(!newshortListed.length)
        }
        else {
            let newList = selectedlist.filter((item: any) => item.id !== obj.id);
            if (!newList.length) {
                setlinktrue(false)
            }
            setSelectedList(newList)
            let newshortListed = shortListed.filter((item: any) => item !== obj.id);
            setShortListed(newshortListed)
            handleCheckboxChange(obj.id, false)
            setdisabled(!newList.length)
        }
    }

    const handleCheckboxChange = (id: any, value: boolean) => {
        setCheckedRows((prevCheckedRows: any) => ({ ...prevCheckedRows, [id]: value }));
    };


    useEffect(() => {
        let fields: any = [];
        metaData && metaData.map((item: any) => {
            let field = item?.valuesJson && isJson(item?.valuesJson) ? JSON.parse(item?.valuesJson) : item?.valuesJson;
            if(field && field.recommendation) fields.push(field);
        })
        setShortlistFields(fields)
    }, [metaData])

    useEffect(() => {
        if (selectedlist && selectedlist.length > 0) {

            setlinktrue(true)
        }
        else {
            setlinktrue(false)
        }
    }, [selectedlist])

    const createRecommend = () => {
        setdisabled(true)
        const course_name = shortListed.join(",")
        const data = {
            student: id,
            course_name: course_name
        }
        const handleSuccess = (body: any) => {
            // const message = () => toast(`Shortlisted courses sent Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // message();
            setCheckedRows({})
            setShortListed([])
            setSelectedList([])
            setlinktrue(false)
            setLink(body.url)
            dispatch(recommendApplicationList(id));
        }
        const handleError = () => {

        }
        dispatch(CreateRecommendations(data, handleSuccess, handleError))
    }

    const createRecommendSubAgent = () => {
        setdisabled(true)
        const course_name = shortListed.join(",")
        const data = {
            student: id,
            course_name: course_name
        }
        const handleSuccess = (body: any) => {
            // toast.success("Course(s) applied successfully", { autoClose: 3000 });
            // const message = () => toast(`Shortlisted courses sent Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            // message();
            setpreview(false);
            setLink(false)
            // setlinktrue(false)
            // setLink(body.url)
            dispatch(recommendApplicationList(id));
        }
        const handleError = () => {

        }
        dispatch(CreateRecommendations(data, handleSuccess, handleError))
    }

    const shortListBtn = () => {
        if (isSubAgent !== true && linktrue) return <Row>
            <Col md={4} lg={4} xl={4}>
                <Button className="btn-sm" onClick={() => setpreview(true)}>
                    {props.t("student.shortlisted")}
                </Button>
            </Col>
        </Row>
    }

    const applyBtn = () => {
        if (isSubAgent && linktrue) return <Row>
            <Col md={4} lg={4} xl={4}>
                <Button className="btn-sm" onClick={() => setpreview(true)}>
                    {props.t("student.apply_btn")}
                </Button>
            </Col>
        </Row>
    }

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setCreate(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.applications;
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() === 'create') {
                            item.value === "" || item.value === "none" ? setCreate(false) : setCreate(true)
                        }

                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const checkbox = (row: any) => {
        if(create) return <span className="align-content-center" >
                 <Input
                        className="form-check-input form-check-outline border border-primary form-check-primary ms-3 cursor-pointer"
                        type="checkbox"
                        id={"todo" + row?.id} 
                        checked={checkedRows[row.original.id] === undefined ? false : checkedRows[row.original.id]}
                        onChange={(e) => { handleselect(row.original, e.target.checked); }}
                    />
            </span>
        else return false
    }

    useEffect(() => {
        let filterObject = {pageSize: 500}
        dispatch(coursesMetadataGetRequest("courses", filterObject))
    }, [])

    const initializeData = () => {
        setLink(false); 
        setSelectedList([])
         setCheckedRows([])
    }

    return (
        <div>
            <CoursesPreview
                show={previewModal}
                onCloseClick={() => { setpreview(false); setLink(false); }}
                selectedlist={selectedlist}
                setSelectedList={setSelectedList}
                handleselect={handleselect}
                props={props}
                createRecommend={createRecommend}
                link={link}
                handleButtonClick={handleButtonClick}
                onRemove={handleselect}
                disabled={disabled}
                recommendLoading={recommendLoading}
                shortlistFields={shortlistFields}
                isSubagent={isSubAgent}
                createRecommendSubAgent={createRecommendSubAgent}
            />
            <Listing
                viewId={viewId}
                isCreateModal={isCreateModal}
                setCreateOpen={setCreateOpen}
                getListAction={courseListGetRequest}
                deleteAction={courseDelete}
                detailsGetAction={courseGetRequest}
                metaDatagetRequest={coursesMetadataGetRequest}
                createAction={courseCreateRequest}
                shortListBtn={shortListBtn}
                model={"courses"}
                reducerState={"courses"}
                translater={"courses"}
                checkbox={checkbox}
                applyBtn={applyBtn}
                initializeData={initializeData}
            />
        </div>
    );
};
export default withTranslation()(Courses);


