export interface Pricing {
  paymentLink: string | null;
  }

export enum PricingActionTypes {
    GET_PLANLINK_REQUEST = "@@pricing/GET_PLANLINK_REQUEST",
    GET_PLANLINK_SUCCESS = "@@pricing/GET_PLANLINK_SUCCESS",
    GET_PLANLINK_ERROR = "@@pricing/GET_PLANLINK_ERROR"
}

export interface PricingState {
    // readonly loading: boolean;
    readonly paymentLink: string | null;
    readonly loading: boolean;
    readonly errors?: string;
}
