import React, { useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import { Button, Label } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import ReportPanel from "./ReportPanel";
import SaveModel from "./SaveModel";
import EditModel from "./EditModel";
import { Report, Layout } from "store/report/types";
import Dashboard from "./Dashboard";

interface ConfigProps {
    dashboard: any;
    t: (key: string) => string;
}
const Config: React.FC<ConfigProps> = ({dashboard, t}) => {
    const navigate = useNavigate();

    const [dashboardName, setDashboradName] = useState('')
    const [selectedReports, setSelectedReports] = useState<Layout[]>([]);
    const [saveModel, setSaveModel] = useState(false)
    const [edit, setEdit] = useState(false)

    useEffect(() => {
        if(dashboard) {
            setDashboradName(dashboard?.name);
            const selectedReports = dashboard?.dashboard_reports?.map((dashboardReports: any) => {
                return {
                    key: dashboardReports?.layout?.key,
                    title: dashboardReports?.layout?.title,
                    layout: dashboardReports?.layout?.layout,
                    report: dashboardReports?.report,
                }
            });
            setSelectedReports(selectedReports);
            setEdit(true);
        }
    }, [dashboard])

    const onBack =  () => {
        navigate('/dashboard');
    }
    const onAddReport = (report: Report) => {
        const nextIndex = selectedReports.length ? Math.max(...selectedReports.map(o => {
            const key = o.key.split("_");
            return Number(key[1])
        })) + 1 : 0;
        const newReport = {
            key: `${report.id}_${nextIndex}`,
            title: report.name,
            layout: { i: `${report.id}_${nextIndex}`, x: 0, y: Infinity, w: 4, h: 3 },
            report: report
        };
        setSelectedReports([...selectedReports, newReport]);
    }
    const onRemove = (key: string) => {
        const filteredReports = selectedReports.filter(report => report.key !== key);
        setSelectedReports(filteredReports);
    }
    return (
        <React.Fragment>
            <div className="position-fixed top-0 bottom-0 w-100 h-100 bg-light" style={{zIndex: 1003}}>
                <div className="d-flex justify-content-between bg-primary p-2 align-items-center">
                    <div>
                        <Button
                            color="primary"
                            className="btn-label"
                            onClick={() => onBack()}
                        >
                                <i className="ri-arrow-left-line label-icon align-middle fs-16"></i>
                                Back
                        </Button>
                    </div>
                    {dashboardName ? <Label className="text-white m-0 fs-5">{dashboardName}</Label> : ''}
                    <div className="d-flex justify-content-center">
                        <Button
                            color="light"
                            className="btn-label" outline
                            onClick={() => setSaveModel(true)}
                        >
                                <i className="ri-file-excel-2-line label-icon align-middle fs-16"></i>
                                Save Dashboard
                        </Button>
                    </div>
                </div>
                <div className="d-flex h-100">
                    <div className="d-flex h-100 bg-white">
                        <ReportPanel
                            selectedReports={selectedReports}
                            onAddReport={onAddReport}
                        />
                    </div>
                    <div className="d-flex h-100 w-100 px-4 py-2">
                        <Dashboard reports={selectedReports} setSelectedReports={setSelectedReports} onRemove={onRemove} />
                    </div> 
                </div>
                {edit ? <EditModel reports={selectedReports} dashboardName={dashboardName} saveModel={saveModel} setSaveModel={setSaveModel} /> : <SaveModel reports={selectedReports} saveModel={saveModel} setSaveModel={setSaveModel} /> }
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(Config);
