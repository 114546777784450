import React, { useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { importCards } from './importtype';
import { Button, Card, Container } from 'reactstrap';
import ImportFrom from './innerComponent/importFrom';
import { useNavigate } from 'react-router-dom';
import PreviousImport from './RecentImports/PreviousImport';
import { ToastContainer } from 'react-toastify';


const Import = (props: any) => {   
    const navigate = useNavigate(); 
    const [isImportOpen, setImportOpen] = useState<any>(false)
    const [importJson, setImportJson] = useState<any>({})

    const goback = useCallback(() => {
        navigate(-1);
      }, [navigate]);
      
    return (
        <React.Fragment>
            <ToastContainer />
            {isImportOpen && <ImportFrom show={isImportOpen} onCloseClick={() => setImportOpen(false)} importJson={importJson} setImportJson={setImportJson} />}
             <div className="page-content">
                <Container fluid>               
                    <div className='vstack gap-2'>   
                        <h4 className='hstack text-center'>{props.t("import.import_headings")}</h4> 
                        <div>                                                                                         
                            <Button color="primary" className="w-auto btn-sm" onClick={() => goback()}>
                                Back
                            </Button> 
                        </div>  
                        <div className='hstack justify-content-center gap-4'>   
                        {
                            importCards && importCards.length ? importCards.map((item: any) => {
                            return <Card className='p-4 border border border-opacity-25 border-primary align-items-spacearound' style={{ width: 200, height: 320 }} >
                                        <div className='vstack gap-3 text-center w-100  align-items-between'>
                                            <div className='align-self-center'>
                                                <img src={item.svg} height={'auto'} width={80} alt='' />
                                            </div>
                                            <p className='text-center'>{item.description}</p>
                                            <h5 className='text-center'>{item.title}</h5>
                                        </div>
                                        <Button color='primary' size='sm' onClick={() => {setImportOpen(true); setImportJson({...importJson, item})}}>{item.btnText}</Button>
                                      
                                    </Card>
                                }) : null
                            }
                        </div>
                    </div>
                    <div>
                        <PreviousImport />
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Import);
