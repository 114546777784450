import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Badge, Button, Card, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


const ArchiveModal = ({ props, show, onCloseClick, data, record, onDelete }: any) => {
    return (
            <Modal backdrop={"static"} id="myModal" isOpen={show} toggle={() => { onCloseClick(); }} centered>
                <ModalHeader className="modal-title fw-bold" id="myModalLabel" toggle={() => { onCloseClick(); }}>
                    {/* {props.t("properties.delete_the_property")} */}
                    Delete Form
                </ModalHeader>
                <ModalBody className='text-center p-5'>
                    <div className="mt-1">     
                        <i className="ri-delete-bin-5-line display-5 text-danger"></i>                                  
                        <h4 className="mb-2 mt-2">{record.name}</h4>
                        <p className="text-muted fs-15 mb-4">{props.t("properties.delete_disclaimer1")} {record.name}. {props.t("properties.delete_disclaimer2")}</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button className="btn btn-danger" onClick={() => onDelete()}>
                                {props.t("properties.delete")}
                            </Button>
                            <Button color="danger" onClick={() => onCloseClick()} outline>
                                {props.t("properties.cancel")}
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
    ) as unknown as JSX.Element;
};

export default ArchiveModal;