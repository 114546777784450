import React, { useEffect } from 'react';

//import Scss
import './assets/scss/themes.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';
//imoprt Route
import Route from './Routes';
import config from "config";
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";
import 'amazon-connect-streams';
// Fake Backend 
import fakeBackend from "./helpers/AuthType/fakeBackend";
import { CCPProvider } from './CCPContext/CCPContext';

const { google } = config;
// Activating fake backend
fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };
const CLIENT_ID = google.CLIENT_ID
// // init firebase backend
// initFirebaseBackend(firebaseConfig);

function App() {
  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
    {/* <React.Fragment> */}
    <CCPProvider>
      <Route />
      </CCPProvider>
      {/* <div id="ccp-container" style={{ height: '600px', width: '400px' }}></div> */}
    {/* </React.Fragment> */}
    </GoogleOAuthProvider>
    
  );
}

export default App;
