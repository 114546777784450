import React from "react";
import { Handle, Position } from '@xyflow/react';
import { Card, CardBody, Label } from "reactstrap";
import { tintColor, shadeColor} from "helpers/colors";

const Node = ({data}: any) => {
    return (
        <Card className="shadow-lg border border-dark-subtle">
            <CardBody>
                <Handle type="target" position={Position.Top} />
                <div className="d-flex align-items-center gap-2" style={{maxWidth: "340px"}}>
                    <div className="d-flex align-items-center justify-content-center rounded-3" style={{backgroundColor: tintColor(data.color, 70), height: '40px', width: '40px'}}>
                        <i className="ri-git-branch-line fs-3" style={{color: shadeColor(data.color, 20)}}></i>
                    </div>
                    <div className="d-flex flex-column gap-1">
                        <Label className="m-0 fs-6 fw-bold">{data.label}</Label>
                        <p className="m-0 fs-6">{data.description}</p>
                    </div>
                </div>
                <Handle type="source" position={Position.Bottom} id="a" />
            </CardBody>
        </Card>
    )
};

export default Node;