import {AuthActionTypes, Auth} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { responseHandler } from "utils";

const { api } = config;

const DTS_API_DOMAIN = api.DTS_API_DOMAIN
const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const signinRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({
            type: AuthActionTypes.POST_SIGNIN_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/users/signin`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.POST_SIGNIN_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: AuthActionTypes.POST_SIGNIN_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};



export const signoutRequest: AppThunk = () => {
    return async (dispatch: Dispatch): Promise<void> => {        
        localStorage.clear();
        dispatch({
            type: AuthActionTypes.GET_SIGNOUT
        });
    };
};

export const setTokenRequest: AppThunk = (token) => {
    return async (dispatch: Dispatch): Promise<void> => {        
      
        dispatch({
            type: AuthActionTypes.POST_SET_TOKEN,
            payload: token,
        });
    };
};

export const SetInvoceData: AppThunk = (students, channel) => {
    return async (dispatch: Dispatch): Promise<void> => {  
        dispatch({
            type: AuthActionTypes.SET_INVOICE_STUDENTS,
            payload: {students,channel}
        });
    };
};

export const ResetInvoceData: AppThunk = () => {
    return async (dispatch: Dispatch): Promise<void> => {   
        dispatch({
            type: AuthActionTypes.RESET_STUDENTS
        });
    };
};

export const signupRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch): Promise<void> => {    
        dispatch({
            type: AuthActionTypes.POST_SIGNUP_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/users/signup`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.POST_SIGNUP_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: AuthActionTypes.POST_SIGNUP_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};

export const otpVerify: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: AuthActionTypes.POST_VERIFY_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/users/confirm`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                'token': token
            },
            body: JSON.stringify(data)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.POST_VERIFY_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: AuthActionTypes.POST_VERIFY_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};

export const updateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: AuthActionTypes.POST_UPDATE_USER_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/users/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                'token':  token
            },
            body: JSON.stringify(data)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.POST_UPDATE_USER_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: AuthActionTypes.POST_UPDATE_USER_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};

export const joinRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({
            type: AuthActionTypes.POST_JOIN_USER_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/users/join`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.POST_JOIN_USER_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: AuthActionTypes.POST_JOIN_USER_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};

export const forgotRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({
            type: AuthActionTypes.POST_FORGOT_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/users/forgot-password`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.POST_FORGOT_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: AuthActionTypes.POST_FORGOT_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};

export const setResponse: AppThunk = (data) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({
            type: AuthActionTypes.POST_RESPONSE_SUCCESS,
            payload: data
        });
    };
};
export const AllSubLevelPermssion: AppThunk = (data) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({
            type: AuthActionTypes.POST_SUBLEVEL_PERMISSION_SUCCESS,
            payload: data
        });
    };
};

export const userGetRequest: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {        
        const { auth: { token } } = getState();      
        // let token = "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ6aWx0ZXJ1c2VyQHlvcG1haWwuY29tIiwicm9sZSI6IjY3ZjA5ZWE5LWQxNzMtNDNhYy05NjFkLTgzNTc4NWEzM2U1NCIsInRlbmFudF9pZCI6ImJlNzE2ZWEwLTI0YTUtNGE5Yi1iNTRkLTA4YjhmMWVhN2Y2MyIsInVzZXJfaWQiOiI2YjM1MzIzNy02OWY1LTRkZTItOGJiZC0wNjNhZDYzNmRiZjIiLCJjdXJyZW50X3N0YWdlIjoiOCIsImlhdCI6MTcyMjcwMjE0MywiZXhwIjoxNzIyNzAyMjYzfQ.nBaqpVHiC1uMCx1-JLacjRHk9PQmjRD28NT5OyrnW4M"
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: AuthActionTypes.GET_USER_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/users/me`, {
            method: "GET",
            headers: headers
        });                    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.GET_USER_ERROR,
                payload: body_1
            });
            handleError && handleError(body_1)
        } else {
            dispatch({
                type: AuthActionTypes.GET_USER_SUCCESS,
                payload: body_1
            });
            handleSuccess && handleSuccess(body_1)
        }
    };
};

export const userMultipleAssiciationGetRequest: AppThunk = (token, handleSuccess, handleError) => {
    return async (dispatch: Dispatch): Promise<void> => {     
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: AuthActionTypes.GET_MULTIPLE_USER_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/users/me`, {
            method: "GET",
            headers: headers
        });                    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.GET_MULTIPLE_USER_ERROR,
                payload: body_1
            });
            handleError && handleError(body_1)
        } else {
            dispatch({
                type: AuthActionTypes.GET_MULTIPLE_USER_SUCCESS,
                payload: body_1
            });
            handleSuccess && handleSuccess(body_1)
        }
    };
};

export const resetPassword: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: AuthActionTypes.POST_RESETPASSWORD_USER_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/users/resetPassword`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.POST_RESETPASSWORD_USER_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: AuthActionTypes.POST_RESETPASSWORD_USER_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};


export const getUserToken: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {  
        dispatch({
            type: AuthActionTypes.GET_USER_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/users/invite/${id}`, {
            method: "GET",
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.GET_USER_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: AuthActionTypes.GET_USER_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1)
        }
    };
};

export const joinUserRequest: AppThunk = (id, data,token, handleSuccess, handleError) => {
    return async (dispatch: Dispatch): Promise<void> => {   
        dispatch({
            type: AuthActionTypes.POST_UPDATE_USER_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/users/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                'token':  token
            },
            body: JSON.stringify(data)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.POST_UPDATE_USER_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: AuthActionTypes.POST_UPDATE_USER_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};

export const verifyPasswordLink: AppThunk = (token, handleSuccess, handleError) => {
    return async (dispatch: Dispatch): Promise<void> => {     
        dispatch({
            type: AuthActionTypes.GET_VERIFY_PASSWORD_LINK_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/users/verifyPasswordLink`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'resetToken':  token
            },
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.GET_VERIFY_PASSWORD_LINK_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: AuthActionTypes.GET_VERIFY_PASSWORD_LINK_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};

export const verifyJoinLink: AppThunk = (token, handleSuccess, handleError) => {
    return async (dispatch: Dispatch): Promise<void> => {     
        dispatch({
            type: AuthActionTypes.GET_VERIFY_JOIN_LINK_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/users/verifyInvitation`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'token':  token
            },
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.GET_VERIFY_JOIN_LINK_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: AuthActionTypes.GET_VERIFY_JOIN_LINK_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};

export const resendOtp: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: AuthActionTypes.POST_RESEND_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_DOMAIN}/users/resendOtp`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.POST_RESEND_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: AuthActionTypes.POST_RESEND_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

const optionscreateQueryLink = (model: any,filterObject: any) => {
    // let query = `http://13.40.63.214:${port}`
    let query = `${DTS_API_STUDENT}/${model}?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);  
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const getOptions: AppThunk = (model,filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: AuthActionTypes.GET_OPTIONS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = optionscreateQueryLink(model,filterObject);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.GET_OPTIONS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: AuthActionTypes.GET_OPTIONS_SUCCESS,
                payload: body_1
            });
        }
    };
};

const optionsnewcreateQueryLink = (model: any,filterObject: any) => {
    // let query = `http://13.40.63.214:${port}`
    let query = `${DTS_API_STUDENT}/${model}/get?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);  
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const getnewOptions: AppThunk = (model,filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: AuthActionTypes.GET_OPTIONS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = optionsnewcreateQueryLink(model,filterObject);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.GET_OPTIONS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: AuthActionTypes.GET_OPTIONS_SUCCESS,
                payload: body_1
            });
        }
    };
};


const filteroptionscreateQueryLink = (field: any,model: any,filterObject: any) => {
    // let query = `http://13.40.63.214:${port}`
    let query = `${DTS_API_STUDENT}/${model}?filters=${field}&`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);  
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const getFilterOptions: AppThunk = (field,model,filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: AuthActionTypes.GET_OPTIONS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = filteroptionscreateQueryLink(field,model,filterObject);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.GET_OPTIONS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: AuthActionTypes.GET_OPTIONS_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const optionsListGetRequest: AppThunk = (model,data, page, size, filterObject) => {
    let responseType =  filterObject ? `&responseType=${filterObject['responseType']}` : null
    let requestbody = data ? data : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: AuthActionTypes.GET_OPTIONS_REQUEST,
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        const response = await fetch(`${DTS_API_STUDENT}/models/get/${model}?page=${page?page:0}&pageSize=${size?size:500}&${responseType?responseType:""}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestbody)
        }).catch((error) => {
            return false;
        });
        if(typeof response === 'object' && !Array.isArray(response) && response !== null) {
            const body = await response?.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: AuthActionTypes.GET_OPTIONS_ERROR,
                    payload: body_1,
                    model:model
                });
            } else {
                dispatch({
                    type: AuthActionTypes.GET_OPTIONS_SUCCESS,
                    payload: body_1,
                    model:model
                });
            }
        }
    };
};

const createQueryLink = (filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/properties?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const propertyListGetRequest: AppThunk = (model,filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: AuthActionTypes.GET_PROPERTIES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        if (model) {
            headers.append('Properties-Model', model);
        }  
        const searchQuery = createQueryLink(filterObject,pageno);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        await responseHandler(response, dispatch, AuthActionTypes.GET_PROPERTIES_SUCCESS, AuthActionTypes.GET_PROPERTIES_ERROR,null, null, null, "GET");

        // const body = await response.json();
        // const {response: response_1, body: body_1} = {response, body};
        // if (!response_1.ok) {
        //     dispatch({
        //         type: AuthActionTypes.GET_PROPERTIES_ERROR,
        //         payload: body_1
        //     });
        // } else {
        //     dispatch({
        //         type: AuthActionTypes.GET_PROPERTIES_SUCCESS,
        //         payload: body_1
        //     });
        // }
    };
};


export const ModelListGetRequest: AppThunk = () => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: AuthActionTypes.GET_MODELS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }               
        const response = await fetch(`${DTS_API_STUDENT}/models`, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.GET_MODELS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: AuthActionTypes.GET_MODELS_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const postGoogleCalendarSync: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: AuthActionTypes.POST_GOOGLECALENDARSYNC_REQUEST
        });
        const response = await fetch(`${DTS_API_DOMAIN}/accounts`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'token': token
            },
            body: JSON.stringify(data)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: AuthActionTypes.POST_GOOGLECALENDARSYNC_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: AuthActionTypes.POST_GOOGLECALENDARSYNC_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};