import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import CommissionCreate from './CommissionCreate';

const Commission = (props: any) => {    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>   
                    <CommissionCreate />  
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Commission);
