import React from "react";

const CircleBadge: React.FC = () => {
  const circleBadgeStyle: React.CSSProperties = {
    backgroundColor: "rgb(0, 189, 165)",
    borderRadius: "100%",
    display: "inline-block",
    marginRight: "8px",
    width: "10px", // Adjust width based on your preference
    height: "10px", // Adjust height based on your preference
  };

  return <div style={circleBadgeStyle}></div>;
};

export default CircleBadge;
