import BreadCrumb from 'Components/Common/BreadCrumb';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import Reports from './Reports';

const Main = (props: any) => {
    document.title="Reports | Zilter";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={props.t("reports.reports")} pageTitle={props.t("reports.reports")} />
                    <Reports />
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(Main);


