import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from "../../../config";
import SimpleBar from 'simplebar-react';

const { api } = config;

const API_Endpoint = api.AWS_API;

const { QuickSightEmbedding } = require('amazon-quicksight-embedding-sdk');

const EmbedQSearchBar: React.FC = () => {
  const [embedUrl, setEmbedUrl] = useState<string | null>(null);


  const fetchEmbedUrl = async () => {
    try {
        const response: any = await axios.get(`${API_Endpoint}/quicksight/getQEmbedUrl`);
        setEmbedUrl(response.embedUrl);
    } catch (error) {
        console.error("Error fetching embed URL", error);
    }

  };

  useEffect(() => {
    fetchEmbedUrl();
  }, []);

  return <> 
    {embedUrl && 
    <iframe
      width="100%"
      height={"100%"}
      src={embedUrl}>
  </iframe>
}
  </>;
};

export default EmbedQSearchBar;
