import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Listing from 'Components/Common/Views/Listing';
import { templateCreateRequest, templateDelete, templateGetRequest, templateListGetRequest, templatesMetadataGetRequest } from 'store/templates/action';

const Templates = (props: any) => {
    const { viewId, isCreateModal, setCreateOpen } = props;    
    return (
        <Listing 
            viewId={viewId}
            isCreateModal={isCreateModal}
            setCreateOpen={setCreateOpen}
            getListAction={templateListGetRequest}
            deleteAction={templateDelete}
            detailsGetAction={templateGetRequest}
            metaDatagetRequest={templatesMetadataGetRequest}
            createAction={templateCreateRequest}
            model={"templates"}
            reducerState={"templates"}
            translater={"template"}
        />
    );
};
export default withTranslation()(Templates);


