export const importModel = [
    {
        "title": "Students",
        "model": "students",
        "description": "Upload the data for student",
        "svg":"ri-list-check"
    },
    {
        "title": "Applications",
        "model": "applications",
        "description": "Upload the data for applications",
        "svg": "ri-contacts-book-line"
    },
    {
        "title": "Institutes",
        "model": "institutes",
        "description": "Upload the data for Institutes",
        "svg": "ri-building-4-line"
    },
    {
        "title": "Courses",
        "model": "courses",
        "description": "Upload the data for Courses",
        "svg": "ri-book-open-line"
    }
]