
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Card,
	CardBody,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Input,
	Label,
	Row,
	UncontrolledDropdown,
} from "reactstrap";
import dataFields from "./UsersListFields.json";
import _ from "lodash";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import { Link, useNavigate } from "react-router-dom";
import TableContainer from "./TableComponent";
import DeleteModal from "./DeleteModal";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { userDelete, userInviteRequest, usersListGetRequest } from "store/user/action";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { WithTranslation, withTranslation } from "react-i18next";
import InviteUser from "./InviteUser";
import { roleListGetRequest, roleNameListRequest } from "store/role/action";
import Select from "react-select";
import { components } from "react-select";
import AccessDenied from "pages/AccessDenied/Denied";
import ListingLoader from "Components/Common/FormBuilder/DtsTablePlaceholder";
import UserAvatar from "Components/Common/UserAvtar/ListingUserAvatar";
import { checkTheyOwn } from "utils";

interface SortConfig {
	[key: string]: {
		direction: 'asc' | 'desc';
	};
}

const UsersTable = (props: any) => {
	const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
	const userList = useSelector((state: ApplicationState) => state.user.users);
	const pagination = useSelector((state: ApplicationState) => state.user.pagination);
	const roles = useSelector((state: ApplicationState) => state.role.list);
	const me = useSelector((state: ApplicationState) => state.user.user);
	const navigate = useNavigate();
	const [record, setrecord] = useState<any>({});
	const [focusid, setfocused] = useState<number>(-1);
	const [visibleColumns, setvisibleColumns] = useState<any>([]);
	const [columns, setColumns] = useState<any>([])
	const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
	const [editModal, setEditOpen] = useState<boolean>(false);
	const [id, setId] = useState<string>("")
	const [inviteUser, setInviteUser] = useState<boolean>(false);
	const [role, setRole] = useState<any[]>([])
	const [pageSize, setPageSize] = useState<any | null>(10)
	const [confirmationStatus, setConfirmationStatus] = useState<any>("ALL")
	const [roleFilter, setRoleFilter] = useState<any>()
	const [selectedValue, setSelectedValue] = useState<string>('');
	const [roleId, setRoleId] = useState<string>("");
	const [filterSet, setFilterSet] = useState<boolean>(false);
	const [rolefilterSet, setRolefilterSet] = useState<boolean>(false);
	const [sortConfig, setSortConfig] = useState<SortConfig>({});
	const [searchQuery, setSearchQuery] = useState("");
	const [sortBy, setSortBy] = useState<string>("createdAt");
	const [sortOrder, setSortOrder] = useState<string>("desc");
	const [isIconUp, setIsIconUp] = useState(false);
	const [selectedRole, setSelectedRole] = useState(null);
	const [selectedStatus, setSelectedStatus] = useState(null)
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const [statusMenuOpen, setStatusMenuOpen] = useState(false)
	const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
	const authLoading = useSelector((state: ApplicationState) => state.auth.meloading);
	const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
	const [currentPage, setCurrentPage] = useState(0);
	const [usersQueryParams, setUsersQueryParams] = useState('')
	const [viewAccess, setViewAccess] = useState<boolean>(false);
	const [access, setAccess] = useState<any>([]);
	const [create, setCreate] = useState<boolean>(false);
	const [edit, setEdit] = useState<any>(null);
	const [deleteaccess, setDeleteAccess] = useState<any>(null);
	const buttonRef = useRef(null);
	const toggleIcon = (field: string) => {
		setSortConfig((prevState) => {
			const currentDirection = prevState[field]?.direction;
			const newDirection = currentDirection === 'asc' ? 'desc' : 'asc';
			return { ...prevState, [field]: { direction: newDirection } };
		});
	};

	let totalPages = 0;
	let pageNumbers: number[] = [];
	// let recordsSize: number[] = [];
	if (pagination) {
		// recordsSize = Array.from({ length: Math.ceil(pagination.elements / 10) }, (_, index) => (index + 1) * 10);
		totalPages = pagination.total
		// recordsSize = Array.from({ length: totalPages }, (_, index) => (index + 1) * 10);
		pageNumbers = Array.from({ length: totalPages }, (_, index) => index);
	}
	const status = [
		{
			options: [
				{ label: "All", value: "ALL" },
				{ label: "Unconfirmed", value: "UNCONFIRMED" },
				{ label: "Confirmed", value: "CONFIRMED" },
			],
		},
	];

	const colors = [
		"#4285F4", // Blue
		"#0F9D58", // Green
		"#F4B400", // Yellow
		"#DB4437", // Red
		"#AB47BC", // Purple
		"#00ACC1", // Teal
		"#FF7043", // Orange
		"#9E9D24", // Lime
		"#5C6BC0", // Indigo
		"#FFB300", // Amber
		"#F57C00", // Deep Orange
		"#E64A19", // Red
		"#9E9D24", // Olive
		"#FF5722", // Deep Orange
		"#009688", // Teal
		"#8BC34A", // Light Green
		"#3F51B5", // Indigo
		"#FFEB3B", // Yellow
		"#9C27B0", // Purple
		"#E91E63"  // Pink
	];

	const getRandomColor = () => {
		const randomIndex = Math.floor(Math.random() * colors.length);
		return colors[randomIndex];
	};
	useEffect(() => {
		let filter = `status=ALL`
		dispatch(usersListGetRequest(filter));
	}, [])
	useEffect(() => {
		const roleValues: any = [];
		roles && roles.map((item: any) => {
			roleValues.push({ value: item.id, label: item.title });
		});
		setRole(roleValues);
		if (roles && roles.length > 0) {
			// Assuming the roleId is the first role in the list
			setRoleId(roles[0].id);
		}
	}, [roles]);

	const handleselected = (option: any, name: string) => {
		let queryParams: any = new URLSearchParams({});
		switch (name) {
			case 'next-page':
				setPageSize(pageSize)
				setCurrentPage(currentPage + 1)
				if (sortOrder) queryParams.set('sortOrder', sortOrder)
				if (sortBy) queryParams.set('sortBy', sortBy);
				queryParams.set('page', currentPage + 1);
				if (searchQuery) queryParams.set('search', searchQuery);
				if (pageSize) queryParams.set('pageSize', pageSize);
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				break;
			case 'previous-page':
				setPageSize(pageSize)
				setCurrentPage(currentPage - 1)
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				if (sortOrder) queryParams.set('sortOrder', sortOrder)
				if (sortBy) queryParams.set('sortBy', sortBy);
				queryParams.set('page', currentPage - 1);
				if (searchQuery) queryParams.set('search', searchQuery);
				if (pageSize) queryParams.set('pageSize', pageSize);
				break;
			case 'page-number':
				setPageSize(pageSize)
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				if (sortOrder) queryParams.set('sortOrder', sortOrder)
				if (sortBy) queryParams.set('sortBy', sortBy);
				if (pageSize) queryParams.set('pageSize', pageSize)
				queryParams.set('page', option);
				break;
			case 'page-size':
				let page = currentPage;
				page = 0
				if (sortOrder) queryParams.set('sortOrder', sortOrder)
				sortBy && queryParams.set('sortBy', sortBy);
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				queryParams.set('page', page);
				queryParams.set('pageSize', option);
				break;
			case 'search':
				queryParams.set('search', option);
				if (sortBy) queryParams.set('sortBy', sortBy);
				if (pageSize) queryParams.set('pageSize', pageSize)
				queryParams.set('page', 0);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				break;
			case 'clear-search':
				setSearchQuery('')
				if (sortBy) queryParams.set('sortBy', sortBy);
				if (pageSize) queryParams.set('pageSize', pageSize)
				queryParams.set('page', 0);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				break;
			// case 'all-status':
			//   setRoleFilter(roleFilter);
			//   setFilterSet(true);
			//   currentPage && queryParams.set('page', 0)
			//   if (pageSize) queryParams.set('pageSize', pageSize)
			//   roleFilter && queryParams.set('roleId', roleFilter.value);
			//   break;
			case 'confirmation-status':
				if (option == "ALL") {
					setRoleFilter(roleFilter);
					setFilterSet(true);
					currentPage && queryParams.set('page', 0)
					if (pageSize) queryParams.set('pageSize', pageSize)
					roleFilter && queryParams.set('roleId', roleFilter?.value);
					queryParams.set('status', `ALL`)
				}
				else {
					setRoleFilter(roleFilter);
					setFilterSet(true);
					currentPage && queryParams.set('page', 0)
					if (pageSize) queryParams.set('pageSize', pageSize)
					queryParams.set('status', option);
					roleFilter && queryParams.set('roleId', roleFilter?.value);
				}
				break;
			case 'role-filter':
				setConfirmationStatus(confirmationStatus);
				setRolefilterSet(true);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				currentPage && queryParams.set('page', 0)
				if (pageSize) queryParams.set('pageSize', pageSize)
				queryParams.set('roleId', option);
				break;
			case 'column': //column-phone
				setSortBy(option);
				if (sortBy === option) {
					setSortOrder(sortOrder === "asc" ? "desc" : "asc");
				} else {
					setSortOrder("asc");
				}
				confirmationStatus && queryParams.set('status', confirmationStatus);
				currentPage && queryParams.set('page', 0)
				if (pageSize) queryParams.set('pageSize', pageSize)
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				searchQuery && queryParams.set('search', searchQuery);
				queryParams.set('sortBy', option);
				queryParams.set('sortOrder', sortOrder === "asc" ? "desc" : "asc");
				break;

			default:
				// handle default case if needed  column-confirmation
				break;
		}
		setUsersQueryParams(queryParams.toString())
		// let page = currentPage == 0 ? 0 : 
		dispatch(usersListGetRequest(queryParams.toString()));
	};

	// const handleClearSearch = () => {
	// 	setSearchQuery('')
	// 	dispatch(usersListGetRequest(usersQueryParams.toString()));
	// }

	const handleRoleSearch = (option: any, name: string) => {
		let queryParams: any = new URLSearchParams({});
		switch (name) {
			case 'searchRole':
				currentPage && queryParams.set('page', 0)
				if (pageSize) queryParams.set('pageSize', pageSize)
				if (option) {
					queryParams.set('search', option.toString());
				}
				break;
			case 'searchStatus':
				currentPage && queryParams.set('page', 0)
				if (pageSize) queryParams.set('pageSize', pageSize)
				if (option) {
					queryParams.set('search', option.toString());
				}
				break;
		}
		dispatch(roleNameListRequest(queryParams.toString()));
	}
	const onClickrow = (data: any, index: any) => {
		setrecord(data);
		// setInputs(data);
		setfocused(index);
	};
	const handleLeadClick = (arg: any) => {
		const lead = arg;
	};
	const onDelete = (userId: any) => {
		let queryParams: any = new URLSearchParams({});
		const handleSuccess = (body: any): void => {
			pageSize && queryParams.set('pageSize', pageSize)
			currentPage && queryParams.set('page', currentPage)
			dispatch(usersListGetRequest(queryParams.toString()));

			setisDeleteOpen(false);
			const message = () => toast(`${body?.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
			message();
		}
		const handleError = (body: any): void => {
			const message = () => toast(`${body?.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
			message();
		};
		dispatch(userDelete(record.id, handleSuccess, handleError));
	}
	const clearFilters = async () => {
		let filter = `status=ALL`
		setSelectedRole(null)
		setSelectedStatus(null)
		setCurrentPage(0)
		setPageSize(10)
		setSearchQuery('');
		setConfirmationStatus('ALL');
		setRoleFilter('');
		setFilterSet(false);
		setRolefilterSet(false);

		await dispatch(usersListGetRequest(filter));

	};
	const handleClearAll = () => {
		clearFilters();
	}
	const handleEditClick = (id: string) => {
		navigate(`/users/${id}`);
	};
	const handleSendInvite = () => {
		setInviteUser(true);
	}
	const handleResendInvite = (id: any, email: string, name: any) => {
		const data = {
			"attributes": [
				{
					"key": "email",
					"value": email
				},
				{
					"key": "role_Id",
					"value": id
				},
				{
					"key": "name",
					"value": name
				}
			]
		};
		const handleSuccess = (body: any): void => {
			const message = () => toast(`Invite resent successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
			message();
		}
		const handleError = (body: any): void => {
			const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
			message();
		}
		dispatch(userInviteRequest(data, handleSuccess, handleError));
	}
	const handleInputChange = (inputValue: any) => {
		handleRoleSearch(inputValue, "searchRole");
		handleRoleSearch(inputValue, "searchStatus");
	};

	const handleRoleSelect = (sortBy: any) => {
		setRoleFilter(sortBy);
		setSelectedRole(sortBy);
		setMenuIsOpen(false); // Close the menu when an option is selected

		handleselected(sortBy.value, "role-filter");
	};
	const handleStatusSelect = (sortBy: any) => {
		handleselected(sortBy?.value, "confirmation-status");
		setConfirmationStatus(sortBy?.value);
		setSelectedStatus(sortBy);
		setStatusMenuOpen(false)
	};



	useEffect(() => {
		let columns: any = [];
		if (userList && userList.length > 0) {
			const keys = Object.keys(userList[0])
			const fieldsCols: any =
				keys.map((fields: any, index: any) => {
					const isSorted = sortConfig[fields]?.direction;
					const isIconUp = isSorted === 'asc';
					if (fields == "id")
						return {
							Header: _.startCase(fields),
							accessor: fields,
							filterable: true,
							isSortable: false,
							className: "hoverable",
							disableFilters: true,
							show: false,
						};
					else if (fields == "name")
						return {
							Header: (
								<div className="cursor-pointer text-primary d-flex align-items-center" key={columns.id}
									onClick={(e) => {
										setCurrentPage(0);
										handleselected(fields, "column")
										e.stopPropagation();
										toggleIcon(fields);
									}}
									{...columns.isSortable ? columns.getSortByToggleProps() : {}}>
									{_.startCase(fields)}
									<span>
										<i
											className={`lh-base ri-arrow-${isIconUp ? 'up' : 'down'}-s-fill`}
										></i>
									</span>
								</div>
							),
							keys: { columns },
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							show: true,
							Cell: (cellProps: any) => {
								let they_own = checkTheyOwn(cellProps.row.original, userProfile?.id)
								const { profilePic, name, email } = cellProps.row.original;
								return (
									<div style={{ minWidth: 400 }} className="d-flex gap-5 align-items-center justify-content-between">
										<div className="d-flex align-items-center gap-2">
											<div className="flex-shrink-0">
												{profilePic ? (
													<img
														src={profilePic}
														alt=""
														className="avatar-sm img-thumbnail rounded-circle"
													/>
												) : (
													<UserAvatar img={profilePic} firstName={name} />
												)}
											</div>
											<div className="flex-grow-1 ms-3">
												<span
													className="cursor-pointer text-decoration-none text-ellipsis"
													onClick={() => {
														handleEditClick(cellProps.row.original.id);
													}}
												>
													{name || email}
												</span>
												<p className="text-muted fs-12 fw-light mb-0 text-decoration-none text-ellipsis">
													{email}
												</p>
											</div>
										</div>
										<div className="me-2">
											<ButtonGroup>
												<UncontrolledDropdown>
													<DropdownToggle
														color="primary"
														size="sm"
														title="Manage User"
														className="btn-primary justfy-self-end hoverbutton ms-1"
													>
														<i className="ri-settings-2-fill label-icon align-middle"></i>
													</DropdownToggle>
													<DropdownMenu>
														{edit && (edit?.toLowerCase() == "all" || (edit?.toLowerCase() == "they_own" && they_own)) ? (
															<DropdownItem
																onClick={() => {
																	onClickrow(cellProps.row.original.id, index);
																	setEditOpen(true);
																	handleEditClick(cellProps.row.original.id);
																	setId(cellProps.row.original.id);
																}}
															>
																Edit
															</DropdownItem>
														) : null}
														{deleteaccess && (deleteaccess?.toLowerCase() == "all" || (deleteaccess?.toLowerCase() == "they_own" && they_own)) ? (
															<DropdownItem
																disabled={
																	cellProps.row.original.id === me?.id ||
																	cellProps.row.original.role?.title === "Owner"
																}
																onClick={() => {
																	setPageSize(pageSize);
																	setCurrentPage(currentPage);
																	onClickrow(cellProps.row.original, index);
																	setisDeleteOpen(true);
																}}
															>
																Delete
															</DropdownItem>
														) : null}
														{create ? (
															<DropdownItem
																disabled={
																	cellProps.row.original.id === me?.id ||
																	cellProps.row.original.confirmationStatus === "CONFIRMED"
																}
																onClick={() => {
																	handleResendInvite(
																		cellProps.row.original?.role?.id,
																		cellProps.row.original?.email,
																		cellProps.row.original?.name
																	);
																}}
															>
																Resend Invite
															</DropdownItem>
														) : null}
													</DropdownMenu>
												</UncontrolledDropdown>
											</ButtonGroup>
										</div>
									</div>
								);
							},
						};
					else if (fields == "confirmationStatus")
						return {
							Header: (<div className="cursor-pointer text-primary"
								onClick={(e) => {
									setCurrentPage(0);
									setSortBy(fields);
									handleselected(fields, "column")
									e.stopPropagation();
									toggleIcon(fields);
								}}
								{...columns.isSortable ? columns.getSortByToggleProps() : {}}> {_.startCase(fields)} <span>
									<i
										className={`lh-base ri-arrow-${isIconUp ? 'up' : 'down'}-s-fill`}

									></i>
								</span></div>),
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							show: true,
							Cell: (cellProps: any) => {
								return <div className="">{cellProps.row.original.confirmationStatus}</div>;
							},
						};
					else if (fields == "phoneNumber")
						return {
							Header: (<div className="cursor-pointer text-primary"
								onClick={(e) => {
									setCurrentPage(0);
									setSortBy(fields);
									handleselected(fields, "column");
									e.stopPropagation();
									toggleIcon(fields);
								}}
								{...columns.isSortable ? columns.getSortByToggleProps() : {}}> {_.startCase(fields)}<span>
									<i
										className={`lh-base ri-arrow-${isIconUp ? 'up' : 'down'}-s-fill`}

									></i>
								</span></div>),
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							show: true,
							Cell: (cellProps: any) => {
								return <div>{cellProps.row.original.phoneNumber}</div>;
							},
						};
					else if (fields === "role") {
						return {
							Header: (
								<div
									className="cursor-pointer text-primary"
									onClick={(e) => {
										setCurrentPage(0);
										setSortBy(fields);
										handleselected(fields, "column");
										e.stopPropagation();
										toggleIcon(fields);
									}}
									{...(columns.isSortable ? columns.getSortByToggleProps() : {})}
								>
									{_.startCase(fields)}
									<span>
										<i
											className={`lh-base ri-arrow-${isIconUp ? 'up' : 'down'}-s-fill`}

										></i>
									</span>
								</div>
							),
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							show: true,
							Cell: (cellProps: any) => {
								const role = cellProps.row.original?.role;
								return (
									<div>
										{role?.title}
										{role?.deletedAt !== null && <span className="text-muted"> (deactivated)</span>}
									</div>
								);
							},
						};
					}
					else if (fields == "tenant")
						return {
							Header: _.startCase(fields),
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							show: true,
							Cell: (cellProps: any) => {
								return <div>{cellProps.row.original.tenant.companyName}</div>;
							},
						};
					else if (fields == "createdAt")
						return {
							Header: (<div className="cursor-pointer text-primary"
								onClick={(e) => {
									handleselected(fields, "column")
									e.stopPropagation();
									toggleIcon(fields);
									setCurrentPage(0);
									setSortBy(fields);
								}}
								{...columns.isSortable ? columns.getSortByToggleProps() : {}}> {_.startCase(fields)}<span>
									<i
										className={`lh-base ri-arrow-${isIconUp ? 'up' : 'down'}-s-fill`}

									></i>
								</span></div>),
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							show: true,
							Cell: (cellProps: any) => {
								return <div>{moment(cellProps.row.original.createdAt).format('lll')}</div>;
							},
						};
					else if (fields == "updatedAt")
						return {
							Header: (<div className="cursor-pointer text-primary"
								onClick={(e) => {
									setCurrentPage(0);
									setSortBy(fields);
									handleselected(fields, "column")
									e.stopPropagation();
									toggleIcon(fields);
								}}
								{...columns.isSortable ? columns.getSortByToggleProps() : {}}> {_.startCase(fields)}<span>
									<i
										className={`lh-base ri-arrow-${isIconUp ? 'up' : 'down'}-s-fill`}

									></i>
								</span></div>),
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							show: true,
							Cell: (cellProps: any) => {
								return <div>{moment(cellProps.row.original.updatedAt).format('lll')}</div>;
							},
						};
					else
						return {
							Header: _.startCase(fields),
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							className: "hoverable",
							show: true,
							Cell: (cellProps: any) => {
								switch (fields.type) {
									case "text":
									case "email":
									case "password":
									case "number":
									case "date":
										return (
											<div className="d-flex align-items-center text-primary" key={index}>
												<Label
													className="hstack py-1 form-label"
													htmlFor={cellProps.row.original.name}
												>
													{cellProps.row.original.label}
												</Label>
											</div>
										);
									case "textarea":
										return (
											<div key={index}>
												<Label
													className="hstack py-1 form-label"
													htmlFor={cellProps.row.original.name}
												>
													{cellProps.row.original.label}
												</Label>
												<textarea
													className="form-control"
													onClick={() => {
														onClickrow(cellProps.row.original, index);
													}}
													id={cellProps.row.original.name}
													name={cellProps.row.original.name}
													value={cellProps.cell.value || ""}
												></textarea>
											</div>
										);
									default:
										return <div key={index}>--</div>;
								}
							},
						};
				})
			setvisibleColumns(fieldsCols);
			columns.push.apply(columns, fieldsCols);
			setColumns(columns.filter((x: any) => x.Header !== "Id" && x.Header !== "Email" && x.Header !== "Tenant" && x.Header !== "Current Stage" && x.Header !== "Sub Agent" && x.Header !== "Error Message" && x.Header !== "Profile Pic" && x.Header !== "Values Json"));
		}
	}, [create,  deleteaccess, edit, userList]);

	useEffect(() => {

		let userRole = userProfile?.role?.title;
		if (userRole === "Owner") {
			setViewAccess(true)
			setCreate(true)
			setEdit("all")
			setDeleteAccess("all")
		}
		else if (subLevelPermissionsList && subLevelPermissionsList.users) {
			let access = subLevelPermissionsList.users;
			if (access && access?.length) {
				setAccess(access)
				if (access && access.length) {
					access.map((item: any, index: any) => {
						if (item.name.toLowerCase() == 'view') {
							item.value == "" || item.value == "none" ? setViewAccess(false) : setViewAccess(true)
						}
						if (item.name.toLowerCase() == 'invite') {
							item.value == "" || item.value == "none" ? setCreate(false) : setCreate(true)
						}
						if (item.name.toLowerCase() == 'edit') {
							item.value == "" || item.value == "none" ? setEdit(null) : setEdit(item.name)
						}
						if (item.name.toLowerCase() == 'delete') {
							item.value == "" || item.value == "none" ? setDeleteAccess(null) : setDeleteAccess(item.name)
						}
					})
				}
			}
			else {
				setViewAccess(false)
			}
		}
	}, [subLevelPermissionsList, userProfile])

	return (
		<> {!authLoading ?
			viewAccess ? <div>
				<ToastContainer />
				<div className="d-flex justify-content-between">
					<div className="d-flex align-items-center gap-2 justify-content-start mb-3">
						<div className="d-flex gap-2">
							<div className="search-box">
								<Input
									type="text"
									size={30}
									className="search"
									placeholder="Search for Name or Phone Number"
									onChange={(e) => {
										setSearchQuery(e.target.value);
										handleselected(e.target.value, "search");
									}}
									value={searchQuery || ""}
								/>
								{searchQuery == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleselected('', 'clear-search')}></i>}

							</div>
							<div className="w-xl select-element">
								<Select
									placeholder="Filter by Status"
									value={selectedStatus}
									onInputChange={handleInputChange}
									onChange={handleStatusSelect}
									options={status}
									classNamePrefix="js-example-data-array"
									isLoading={false}
									menuIsOpen={statusMenuOpen}
									onMenuOpen={() => setStatusMenuOpen(true)}
									onMenuClose={() => setStatusMenuOpen(false)}
								/>
							</div>
							<div className="w-xl select-element">
								<Select
									placeholder="Filter by Role"
									value={selectedRole}
									onInputChange={handleInputChange}
									onChange={handleRoleSelect}
									options={role}
									classNamePrefix="js-example-data-array"
									isLoading={false}
									menuIsOpen={menuIsOpen}
									onMenuOpen={() => setMenuIsOpen(true)}
									onMenuClose={() => setMenuIsOpen(false)}
								/>

							</div>
						</div>
						<div className="">
							<Button
								size="sm"
								color="danger"
								className="btn-label btn-soft-danger"
								onClick={() => {
									clearFilters()
								}}
							>
								<i className="ri-close-circle-fill label-icon align-middle fs-16 me-2"></i>
								{props.t("components.filter.quick.btn_clear_all")}
							</Button>
						</div>


					</div>
					<div className="align-items-center me-1">
						<div>
							{
								create ?
									<Button
										onClick={() => handleSendInvite()}
										color="primary"
										className="btn-label">
										<i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
										Invite User
									</Button>
									:
									<Button
										color="primary"
										disabled
										className="btn-label">
										<i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
										Invite User
									</Button>}
						</div>
						<InviteUser
							usersQueryParams={usersQueryParams}
							inviteUser={inviteUser}
							setInviteUser={setInviteUser}
						/>
					</div>
				</div>
				<DeleteModal
					show={isDeleteOpen}
					onCloseClick={() => setisDeleteOpen(false)}
					onDelete={onDelete}
					record={record}
					props={props}
				/>
				<div className="students-table ms-4">
					<div className="table-card gridjs-border-none pb-2 mt-n2">
						<TableContainer
							columns={columns}
							data={userList || []}
							isGlobalFilter={true}
							divClass="table-responsive table-card"
							tableClass="align-middle table-nowrap"
							theadClass="table-light"
							SearchPlaceholder="Search..."
							isPageSizeChange={true}
							isBordered={true}
						/>
					</div>

				</div>
				<div>
					<Row className="align-items-center mt-2 g-3 text-center text-sm-start mx-4">
						<div className="col-sm">
							<div className="text-muted">Showing<span className="fw-semibold ms-1">{userList?.length}</span> of <span className="fw-semibold">{pagination?.elements}</span> Results
							</div>
						</div>
						<div className="col-sm-auto">
							<ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
								<li className={currentPage <= 0 ? "page-item disabled" : "page-item"}>
									<Link to="#" className="page-link" onClick={() => {
										setCurrentPage(currentPage - 1);
										handleselected(currentPage, 'previous-page');
									}}>Previous</Link>
								</li>
								{pageNumbers.length > 5 ? (
									<>
										{pageNumbers.slice(0, 3).map((item: any, key: number) => (
											<li className="page-item" key={key}>
												<Link to="#" className={currentPage === item ? "page-link active" : "page-link"} onClick={() => {
													setCurrentPage(item);
													handleselected(item, 'page-number');
												}}>{item + 1}</Link>
											</li>
										))}
										<li className="page-item" aria-disabled>
											<div className="page-link">...</div>
										</li>
										<li className="page-item">
											<Link to="#" className={currentPage === pageNumbers[pageNumbers.length - 1] ? "page-link active" : "page-link"} onClick={() => {
												setCurrentPage(pageNumbers[pageNumbers.length - 1]);
												handleselected(pageNumbers[pageNumbers.length - 1], 'page-number');
											}}>{pageNumbers[pageNumbers.length - 1] + 1}</Link>
										</li>
									</>
								) : (
									<>
										{pageNumbers.map((item: any, key: number) => (
											<li className="page-item" key={key}>
												<Link to="#" className={currentPage === item ? "page-link active" : "page-link"} onClick={() => {
													setCurrentPage(item);
													handleselected(item, 'page-number');
												}}>{item + 1}</Link>
											</li>
										))}
									</>
								)}


								<li className={currentPage == totalPages - 1 ? "page-item disabled" : "page-item"}>
									<Link to="#" className="page-link" onClick={() => {
										setCurrentPage(currentPage + 1);
										handleselected(currentPage, 'next-page');
									}}>Next</Link>
								</li>
								<select
									className="mx-1 w-50 border border-2 border-light form-select-sm"
									value={pageSize}
									onChange={(e) => {
										const selectedValue = parseInt(e.target.value);
										setCurrentPage(0)
										setPageSize(e.target.value)
										handleselected(e.target.value, 'page-size');
									}}
								>
									{[10, 20, 30, 40, 50].map((size) => (
										<option key={size} value={size}>
											Show {size}
										</option>
									))}
								</select>
							</ul>
						</div>
					</Row>
				</div></div>
				: <AccessDenied />
			: <ListingLoader
				columns={columns}
				tableClass="align-middle table-nowrap"
				theadClass="table-light text-muted"
				thClass="border-bottom-1 table-soft-primary" />
		}
		</>
	);
};
export default withTranslation()(UsersTable);
