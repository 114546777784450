import {PricingActionTypes, Pricing} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { responseHandler } from "utils";

const { api } = config;

const DTS_API_DOMAIN = api.DTS_API_DOMAIN

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const getPricingLink: AppThunk = (pricePlanId, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {  
        const { auth: { token } } = getState();
        
        dispatch({
            type: PricingActionTypes.GET_PLANLINK_REQUEST
        });
        
        try {
            const response = await fetch(`${DTS_API_DOMAIN}/stripe/payment-url/${pricePlanId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "token": token
                },
            });

            // Check if the response is JSON or plain text
            const contentType = response.headers.get("Content-Type");
            const body = contentType && contentType.includes("application/json")
                ? await response.json()
                : await response.text();

            if (!response.ok) {
                dispatch({
                    type: PricingActionTypes.GET_PLANLINK_ERROR,
                    payload: { error: body } // Store error message or URL in error payload
                });
                handleError(body);
            } else {
                dispatch({
                    type: PricingActionTypes.GET_PLANLINK_SUCCESS,
                    payload: { url: body } // Store URL in payload as a string
                });
                handleSuccess(body);
            }

        } catch (error) {
            console.error("Error fetching pricing link:", error);
            dispatch({
                type: PricingActionTypes.GET_PLANLINK_ERROR,
                // payload: { error: error.message }
            });
            // handleError(error.message);
        }
    };
};
