import React, { useEffect, useState } from "react";
import { Badge, Button, Card, CardBody, Col, Label, Row, UncontrolledButtonDropdown, UncontrolledCollapse } from "reactstrap";

import { Link, useParams } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
//import Images
import avatar7 from "../../../assets/images/users/avatar-7.jpg";
import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";
import { withTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch, useSelector } from "react-redux";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { ApplicationState } from "store";
import { SendEmail, getStudentsActivity, getStudentsEmails, studentGetRequest } from "store/student/action";
import moment from "moment";
import SimpleBar from "simplebar-react";
import { noteAddRequest, notesListGetRequest } from "store/note/action";
import { toast } from "react-toastify";
import EditTasks from "./EditTask";
import Select from "react-select";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const editorConfig = {
    removePlugins: ['Table', 'TableToolbar', 'TableTools', 'InsertTable', 'about'],
  };

const CALLSOUTCOMES = [
    { label: "Answered", value: "ANSWERED", color: "success" },
    { label: "Missed", value: "MISSED", color: "danger" },
    { label: "No Answer", value: "NO_ANSWER", color: "warning" },
    { label: "Voicemail Left", value: "VOICEMAIL_LEFT", color: "info" },
    { label: "Busy", value: "BUSY", color: "warning" },
    { label: "Wrong Number", value: "WRONG_NUMBER", color: "danger" },
    { label: "Declined", value: "DECLINED", color: "danger" },
    { label: "Unknown", value: "UNKNOWN", color: "info" },
]
const CALLSDIRECTIONS = [
    { label: "Inbound", value: "INBOUND", color: "primary" },
    { label: "Outbound", value: "OUTBOUND", color: "info" },
    { label: "Unknown", value: "UNKNOWN", color: "warning" },
]

const Activities = (props: any) => {
    const { details, model, isSubAgent } = props;
    let { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [selectedNoteType, setSelectedNoteType] = useState(null);
    const loading = useSelector((state: ApplicationState) => state.student.loading);
    const [replyNoteId, setReplyNoteId] = useState<string | null>(null);
    const [taskDetails, setTaskDetails] = useState({})
    const [replyNote, setReplyNote] = useState("")
    const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
    const [studentId, setStudentId] = useState(null);
    const [applicationId, setApplicationId] = useState<any>(null);
    const [newNoteId, setNewNoteId] = useState<string | null>(null)
    const [page, setPage] = useState(0);
    const [transcribing, setTranscribing] = useState("child")
    const [shortNoteValue, setShortNoteValue] = useState("");
    const [items, setItems] = useState<any>([]);
    const [hasMore, setHasMore] = useState(true);

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition({ transcribing: transcribing == "child" });

    useEffect(() => {
        const handleSuccess = (data: any) => {
            setItems([...items, ...data.content]);
            setHasMore(!data.last);
        }
        handleGetList({ PageSize: 50 }, handleSuccess)
    }, [details])

    useEffect(() => {
        setReplyNote(transcript)
    }, [transcript])

    const noteType = [
        {
            options: [
                { label: "Counselling Session Summary", value: "COUNSELLING SESSION SUMMARY" },
                { label: "Follow-up call", value: "FOLLOW-UP CALL" },
                { label: "Incoming Call", value: "INCOMING CALL" },
                { label: "Attempt to book", value: "ATTEMPT TO BOOK" },
                { label: "Proof of Funds Details", value: "PROOF OF FUNDS DETAILS" },
                { label: "Course Notes", value: "COURSE NOTES" },
                { label: "Finance Notes", value: "FINANCE NOTES" },
                { label: "Others", value: "OTHERS" }
            ],
        },
    ];

    const handleGetList = (filterObject?: any, handleSuccess?: any) => {
        if (model == "students") {
            if (details && details.id) {
                setStudentId(details.id)
                dispatch(getStudentsEmails(details.id, filterObject))
                dispatch(getStudentsActivity(details.id, filterObject, handleSuccess))
            }
        }
        else {
            if (details && details.id) {
                setApplicationId(details.id)
                let studentsId = details?.valuesJson?.student?.id;
                setStudentId(studentsId)
                let filters = { ...filterObject, applicationId: details.id }
                dispatch(getStudentsEmails(studentsId, filterObject))
                dispatch(getStudentsActivity(studentsId, filters, handleSuccess))
            }
        }
    }

    const handleNoteTypeChange = (selectedOption: any) => {
        setSelectedNoteType(selectedOption);
        setShortNoteValue(selectedOption.label)
        // You can also update the formData here if needed
    };
  
    const handleReplyNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReplyNote(event.target.value);
        setNewNoteId("");
        setReplyNoteId(replyNoteId);
    };

    const handleReplyNote = () => {
        const data =
        {
            // studentId: studentId,
            applicationId: applicationId,
            parentId: replyNoteId,
            note: replyNote
        }
        const handleSuccess = (body: any): void => {
            setReplyNote("");
            setSelectedNoteType(null)
            const handleSuccess = (data: any) => {
                setItems(data.content);
                setHasMore(!data.last);
                // setLoading(false);
                setPage(page + 1)
            }
            handleGetList({ PageSize: 50 }, handleSuccess)
        };

        const handleError = (body: any): void => {
            const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            message();
        };

        dispatch(noteAddRequest(data, handleSuccess, handleError));
    };

    const toggleReply = (noteId: string) => {
        if (replyNoteId === noteId) {
            setReplyNoteId(null);
        } else {
            setReplyNoteId(noteId);
        }
    };
    const [emailOpen, setEmailOpen] = useState<any>(false);
    const [emailinfo, setEmailinfo] = useState<any>([]);
    const [showReplies, setShowReplies] = useState(false);
    const [emailData, setEmailData] = useState<any>({
        studentId: id
    })
    const toggleReplies = () => {
        setShowReplies(!showReplies);
    };


    function calculateTimeDifference(targetTime: any) {
        const currentTime: any = new Date();
        const timeDifference = Math.abs(targetTime - currentTime);
        const seconds = Math.floor(timeDifference / 1000) % 60;
        const minutes = Math.floor(timeDifference / (1000 * 60)) % 60;
        const hours = Math.floor(timeDifference / (1000 * 60 * 60)) % 24;
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        return {
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        };
    }
    const Status = ({ status }: any) => {
        switch (status) {
            case "NEW":
                return <span className="badge bg-info-subtle  text-info text-uppercase">{status}</span>;
            case "PENDING":
                return <span className="badge bg-warning-subtle  text-warning text-uppercase">{status}</span>;
            case "INPROGRESS":
                return <span className="badge bg-secondary-subtle text-secondary  text-uppercase">{status}</span>;
            case "COMPLETED":
                return <span className="badge bg-success-subtle text-success text-uppercase">{status}</span>;
            default:
                return <span className="badge bg-success-subtle text-success text-uppercase">{status}</span>;
        }
    };

    const Priority = ({ priority }: any) => {
        switch (priority) {
            case "HIGH":
                return <span className="badge bg-danger text-uppercase">{priority}</span>;
            case "MEDIUM":
                return <span className="badge bg-warning text-uppercase">{priority}</span>;
            case "LOW":
                return <span className="badge bg-success text-uppercase">{priority}</span>;
            default:
                return <span className="badge bg-success text-uppercase">{priority}</span>;
        }
    };

    const rendertime = (time: any) => {
        if (time.days > 0) return <small className="mb-0 text-muted">{time.days} {props.t("student.days")} {props.t("student.ago")}</small>
        else if (time.hours > 0) return <small className="mb-0 text-muted">{time.hours} {props.t("student.hours")} {props.t("student.ago")}</small>
        else if (time.minutes > 0) return <small className="mb-0 text-muted">{time.minutes} {props.t("student.minutes")} {props.t("student.ago")}</small>
        else if (time.seconds > 0) return <small className="mb-0 text-muted">{time.seconds} {props.t("student.seconds")} {props.t("student.ago")}</small>
    }

    useEffect(() => {
        let element: any = document.getElementById('actiivtytab');
        if (element) {
            emailOpen ? element.classList.add("email-detail-show") : element.classList.remove("email-detail-show")
        }
    }, [emailOpen])

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    const startListening = () => {
        setTranscribing("child");
        SpeechRecognition.startListening({ continuous: true });
    };

    const stopListening = () => {
        SpeechRecognition.stopListening();
    };

    const rightOpenState = (e: any, emailinfo: any) => {
        e.preventDefault()
        setEmailinfo(emailinfo)
        setEmailOpen(!emailOpen);
    }
    
    const renderComments = (comment: any, key: any) => {
        const targetTime = new Date(comment?.createdAt).getTime();
        let time = calculateTimeDifference(targetTime)
        let collapse = comment.replies && comment.replies.length ? true : false;
        return (
            <React.Fragment>
                {
                    collapse ? <div className="accordion accordion-flush mb-3 cursor-pointer w-100">
                        <div className="hstack gap-2  justify-content-between">
                            <div>
                                <div className="d-flex align-items-center" id={`note-collapse1-${key}`}>
                                    <div className="flex-shrink-0">
                                        <img src={avatar3} alt="" className="avatar-xs rounded-circle acitivity-avatar" />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <div className="hstack gap-1">
                                            <h5 className="fs-15 mb-1">
                                                <Link to="/pages-profile">{comment?.createdBy?.name}</Link>{" "}      
                                            </h5>
                                            <small className="text-muted">{moment(comment?.createdAt).format('MMMM DD, YYYY hh:mm A')}</small>
                                            <div>
                                                <span className="badge rounded-pill bg-info-subtle text-info">{comment?.shortNote}</span>
                                            </div>
                                        </div>
                                        <p className="text-muted mb-0">{comment?.detailedNote}</p>
                                    </div>
                                </div>
                                <div className="flex-grow-1 ms-5">
                                    {replyNoteId === comment.id ? (
                                        <div>
                                            <textarea className="form-control bg-light border-light" value={replyNote} onChange={handleReplyNoteChange} rows={3} placeholder={props.t("student.reply_to_this_note")}></textarea>
                                            <div className="d-flex gap-3">
                                                <button onClick={(e) => {
                                                    handleReplyNote();
                                                    e.preventDefault();
                                                    toggleReply(comment.id);
                                                }} disabled={!replyNote} className="btn btn-sm btn-success mt-1">{props.t("student.reply")}</button>
                                                <button onClick={() => toggleReply(comment.id)} className="btn btn-sm btn-danger mt-1">{props.t("student.cancel")}</button>
                                            </div>
                                        </div>
                                    ) :
                                        <div className="vstack align-items-start">
                                            <Link to="#" className="badge text-muted bg-light w-auto" onClick={() => {
                                                // e.preventDefault(); 
                                                toggleReply(comment.id)
                                                setReplyNoteId(comment.id)
                                            }}>
                                                <i className="mdi mdi-reply"></i> {props.t("student.reply")}
                                            </Link>
                                            <small className="ms-5 fs-14 text-muted">{rendertime(time)}</small>
                                        </div>
                                    }</div>
                            </div>
                            <div className="align-selt-start" id={`note-collapse1-${key}`}>
                                <i className="ri-arrow-down-s-line fs-24"></i>
                            </div>
                        </div>
                        <UncontrolledCollapse toggler={`#note-collapse1-${key}`}>
                            <div className="accordion-body text-body px-0">
                                {comment.replies && comment.replies.length > 0 && (
                                    comment.replies.map((item: any, index: any) => {
                                        return <div className="ms-5" key={index}>
                                            {renderComments(item, item.id)}
                                        </div>
                                    }))}
                            </div>
                        </UncontrolledCollapse>
                    </div>
                        : <div className="vstack mb-3">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                    <img src={avatar3} alt="" className="avatar-xs rounded-circle acitivity-avatar" />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <div className="hstack gap-1">
                                        <h5 className="fs-15 mb-1">
                                            <Link to="/pages-profile">{comment?.createdBy?.name}</Link>{" "}      
                                        </h5>
                                        <small className="text-muted">{moment(comment?.createdAt).format('MMMM DD, YYYY hh:mm A')}</small>
                                        <div>
                                            <span className="badge rounded-pill bg-info-subtle text-info">{comment?.shortNote}</span>
                                        </div>
                                    </div>
                                    <p className="text-muted mb-0">{comment?.detailedNote}</p>
                                </div>
                            </div>
                            <div className="ms-5 mb-2">
                                {replyNoteId === comment.id ? (
                                    <div>
                                        <textarea className="form-control bg-light border-light" value={replyNote} onChange={handleReplyNoteChange} rows={3} placeholder="Reply to this note"></textarea>
                                        <div className="d-flex gap-3">
                                        <Select
                                                placeholder="Select Note Type"
                                                classNamePrefix="js-example-data-array"
                                                isLoading={false}
                                                options={noteType}
                                                value={selectedNoteType}
                                                onChange={handleNoteTypeChange}
                                                menuPlacement="auto" // You can also try "bottom" or "top"
                                                menuPosition="fixed"
                                            />
                                            {listening ? (
                                                <>
                                                    <Button color="danger" className="ms-2 btn-icon" outline type="button" onClick={stopListening}> <i className="ri-mic-off-line"></i> </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Button color="primary" className="ms-2 btn-icon" outline type="button" onClick={startListening}> <i className="ri-mic-line"></i> </Button>
                                                </>
                                            )}
                                            <button onClick={(e) => {
                                                handleReplyNote();
                                                e.preventDefault();
                                                toggleReply(comment.id);
                                            }} 
                                            disabled={(!replyNote || !selectedNoteType) && (!transcript || !selectedNoteType)}
                                            className="btn btn-sm btn-success mt-1">{props.t("student.reply")}</button>
                                            <button onClick={() => toggleReply(comment.id)} className="btn btn-sm btn-danger mt-1">{props.t("student.cancel")}</button>
                                        </div>
                                    </div>
                                ) :
                                    <>
                                        <Link to="#" className="badge text-muted bg-light" onClick={() => {
                                            // e.preventDefault(); 
                                            toggleReply(comment.id)
                                            setReplyNoteId(comment.id)
                                        }}>
                                            <i className="mdi mdi-reply"></i> {props.t("student.reply")}
                                        </Link>
                                    </>
                                }
                            </div>
                            <small className="ms-5 fs-14 text-muted">{rendertime(time)}</small>
                        </div>
                }
            </React.Fragment>
        );
    };

    const rendertask = (task: any, key: any) => {
        const targetTime = new Date(task?.taskAssignments[0]?.assignedAt).getTime();
        let time = calculateTimeDifference(targetTime)
        return (
            <React.Fragment>
                {
                    <div className="accordion accordion-flush mb-3">
                        <div className="d-flex align-items-center" id={`note-collapse1-${key}`}>
                            <div className="flex-shrink-0">
                                <img src={avatar7} alt="" className="avatar-xs rounded-circle acitivity-avatar" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h5 className="fs-15 mb-1">
                                    <span className="text-primary cursor-pointer" onClick={() => { setTaskDetails(task); setIsEditOpen(true) }}>{task?.title}</span>{" "}
                                    <Status status={task.status.toUpperCase()} />{" "}
                                    <Priority priority={task.priority.toUpperCase()} />
                                </h5>
                                <div className="avatar-group">
                                    {
                                        task?.taskAssignments[0]?.assignedTo ?
                                            <Link to="#" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-placement="top" title={task?.taskAssignments[0]?.assignedTo?.name} data-bs-original-title="Christi">
                                                <img src={avatar4} alt="" className="rounded-circle avatar-xs" />
                                            </Link> : null}

                                </div>
                            </div>
                        </div>
                        <small className="ms-5 fs-14 text-muted">{rendertime(time)}</small>
                    </div>
                }
            </React.Fragment>
        )
    }

    const renderEmails = (emailinfo: any, key: any) => {
        const targetTime = new Date(emailinfo?.createdAt).getTime();
        let time = calculateTimeDifference(targetTime)
        let innerHtml = emailinfo.body ? emailinfo.body.replace(/<[^>]+>/g, '') : "";
        let teaser = innerHtml ? innerHtml.substring(0, 256) + '...' : ""
        
        return (
            <React.Fragment>
                <div className="accordion accordion-flush mb-3">
                    <div className="accordion-item border-dashed left">
                        <div className="accordion-header">
                            <a className="btn w-100 text-start px-0 bg-transparent shadow-none collapsed" id={`email-collapse1-parent-${key}`}>
                                <div className="d-flex align-items-center text-muted">
                                    <div className="flex-shrink-0 avatar-xs rounded-circle acitivity-avatar me-3">
                                        <img src={avatar1} alt="" className="img-fluid rounded-circle" />
                                    </div>
                                    <div className="flex-grow-1 overflow-hidden">
                                        <h5 className="fs-15 mb-1">
                                            <Link to={""}>{emailinfo?.subject}</Link>{" "}
                                            <small className="text-muted">{moment(emailinfo?.createdAt).format('MMMM DD, YYYY hh:mm A')}</small>
                                        </h5>
                                        <span onClick={(e) => e.preventDefault()} className="teaser">{<div dangerouslySetInnerHTML={{ __html:teaser }} />}</span><span className="text-primary" onClick={(e) => rightOpenState(e, emailinfo)}>{props.t("student.more")}</span>

                                    </div>{" "}
                                </div>
                            </a>
                        </div>
                        <small className="ms-5 fs-14 text-muted">{rendertime(time)}</small>
                    </div>
                </div>
            </React.Fragment> 
        );
    };

    const handleComponent = (type: any, item: any, index: any) => {
        switch (type) {
            case "Note": return renderComments(item, index);
            case "Task": return rendertask(item, index);
            case "Email": return renderEmails(item, index);
            case "Calls": return renderCalls(item, index);
        }
    }

    const renderCalls = (callsinfo: any, key: any) => {
        const createdAt = "12-0ct-2024";
        const startTime = "12-0ct-2024";
        const endTime = "12-0ct-2024";
        const duration = moment(endTime)?.diff(moment(startTime));
        const formattedDuration = moment.utc(duration).format('HH:mm:ss');
        const filteredOutcome = CALLSOUTCOMES.find((outcome => outcome.value === callsinfo?.status))
        const filteredDirection = CALLSDIRECTIONS.find((direction => direction.value === callsinfo?.priority))
        return (
            <React.Fragment>
            <div>
                <div className='d-flex justify-content-between mb-0'>
                    <div className='d-flex gap-2 align-items-baseline'>
                        <Label className='fs-15'>Logged call</Label>
                        <div className='fs-14 text-muted'> by {callsinfo.createdBy?.name ? callsinfo.createdBy?.name : details.name}</div>
                        {/* <div className='fs-14 text-muted'> by {"Zilter User"}</div> */}
                        {filteredDirection ? <Badge className='' color={filteredDirection?.color} pill>
                            {filteredDirection?.label}
                        </Badge> : 
                        <Badge className='' color="danger" pill>Unknown</Badge>
                         } 
                    </div>
                    <div>
                        {/* <small className='text-muted'>{moment(calls.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</small> */}
                        <small className='text-muted'>{createdAt ? moment(createdAt).format('MMMM Do YYYY, h:mm:ss a') : moment(startTime).format('MMMM Do YYYY, h:mm:ss a')}</small>
                    </div>
                </div>
                <div className='mt-0 d-flex gap-2 justify-content-between align-items-baseline'>
                    <div>
                        <div className='d-flex gap-2 align-items-baseline'>
                            <div className='fs-14 text-muted'> with {details.valuesJson.name}</div> {/* Assuming info is defined */}
                            <Badge className='' color={filteredOutcome?.color} pill>{filteredOutcome?.label}</Badge>
                        </div>
                    </div>
                    <div>
                        <Label>Call Duration: <span><small className='text-muted'>{formattedDuration ? formattedDuration : "Not Provided"}</small></span></Label>
                    </div>
                </div>
                <div className='d-flex justify-content-between'>
                    <div>
                        {/* <Label className='mt-2 fs-15'>{calls.title ? calls.title : "Untitled"}</Label> */}
                        <Label className='mt-2 fs-15'>{"Untitled"}</Label>
                    </div>
                    <div className='d-flex gap-3'>
                        {/* {calls.provider && calls.provider === "aws" ? (
                            <div className='d-flex gap-2'>
                                <Button
                                    onClick={() => {
                                        handleShow();
                                        onClickSentiment(calls.id)
                                    }}
                                    color="success"
                                    className="btn btn-ghost-success waves-effect waves-light">
                                    Sentiments
                                </Button>
                                <Recording id={calls.id} />
                            </div>
                        ) : (
                            null
                        )} */}


                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }
    const onReply = () => {
        let toemail = emailinfo && emailinfo.to ? emailinfo.to : "";
        const data = {
            parentId: emailinfo.id,            
            studentId: studentId,
            applicationId: applicationId,
            to: toemail,
            subject: emailinfo.subject,
            body: emailData.body,
            bcc: emailinfo?.bcc ? [...emailinfo?.bcc?.split(",")] : null,
            cc: emailinfo?.cc ? [...emailinfo?.cc?.split(",")] : null
        }
        const handleSuccess = () => {
            setEmailData({ ...emailData, body: "" })
            const handleSuccess = (data: any) => {
                setItems(data.content);
                setHasMore(!data.last);
                // setLoading(false);
                setPage(page + 1)
            }
            handleGetList({ PageSize: 50 }, handleSuccess)
            setEmailOpen(false)
        }
        const handleFailure = () => { }
        dispatch(SendEmail(data, handleSuccess, handleFailure))
    }

    const fetchData = async () => {
        // setLoading(true);
        try {
            const handleSuccess = (data: any) => {
                setItems([...items, ...data.content]);
                setHasMore(!data.last);
                // setLoading(false);
                setPage(page + 1)
            }
            handleGetList({ page: page + 1, PageSize: 50 }, handleSuccess)
        } catch (error) {
        }
    };

    const handleTaskUpdateSuccess = (data: any) => {
        setItems(data.content);
        setHasMore(!data.last);
        // setLoading(false);
        setPage(page + 1)
    }
console.log("details act",details)
    return (
        <React.Fragment>
            {isEditOpen && <EditTasks setTaskDetails={setTaskDetails} taskDetails={taskDetails} isEditOpen={isEditOpen} setIsEditOpen={setIsEditOpen} handleActivitiesList={handleGetList} handleTaskUpdateSuccess={handleTaskUpdateSuccess} />}

            <Card  id={"actiivtytab"}>
                <CardBody>
                    <div className="acitivity-timeline">                        
                        {items && items.length ? items.map((item: any, index: any) => {
                            return <div className="acitivity-item d-flex gap-1">
                                {handleComponent(item.type, item.activity, index)}
                            </div>
                        }) : null
                        }
                        {!loading && items.length == 0 ? <small className="text-muted">{props.t("student.no_activity")}</small> : null}
                        {hasMore && !loading ? <div className="acitivity-item d-flex gap-1 cursor-pointer p-1 m-1 text-primary" onClick={() => fetchData()}>Load more</div> : null}
                        {loading ? <div className="acitivity-item d-flex gap-1 p-1 m-1 text-primary">Loading...</div> : null}
                    </div>
                    <div id={"emailtab"} className="email-detail-content email-detail-content-scroll h-100">
                        <div className="p-4 d-flex flex-column">
                            <div className="pb-4 border-bottom border-bottom-dashed">
                                <Row>
                                    <Col className="col">
                                        <div className="">
                                            <button type="button" className="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" onClick={() => setEmailOpen(false)}>
                                                <i className="ri-close-fill align-bottom"></i>
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <SimpleBar className="mx-n4 px-4 email-detail-content-scroll">
                                <div className="mt-4 mb-3">
                                    <h5 className="fw-bold email-subject-title">{emailinfo.subject}</h5>
                                </div>
                                <div className="accordion accordion-flush">
                                    {emailinfo && <div className="accordion-item border-dashed left">
                                        <div className="accordion-header">
                                            <a role="button" href='/#' className="btn w-100 text-start px-0 bg-transparent shadow-none collapsed" id={`email-collapse1-parent`}>
                                                <div className="d-flex align-items-center text-muted">
                                                    <div className="flex-shrink-0 avatar-xs me-3">
                                                        <img src={avatar1} alt="" className="img-fluid rounded-circle" />
                                                    </div>
                                                    <div className="flex-grow-1 overflow-hidden">
                                                        <h5 className="fs-14 text-truncate mb-0 email-user-name">{emailinfo?.createdBy?.name}</h5>
                                                        <div className="text-truncate fs-12">To: {emailinfo?.to}</div>
                                                        <div className="text-truncate fs-12">{emailinfo?.cc ? `CC: ${emailinfo?.cc} ` : ""}</div>
                                                        <div className="text-truncate fs-12">{emailinfo?.bcc ? `BCC: ${emailinfo?.bcc} ` : ""}</div>
                                                    </div>
                                                    <div className="flex-shrink-0 align-self-start">
                                                        <div className="text-muted fs-12">{moment(emailinfo?.createdAt).format('DD MMM YYYY, HH:mm A')}</div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <UncontrolledCollapse toggler={`#email-collapse1-parent`}>
                                            <div className="accordion-body text-body px-0">
                                                <div dangerouslySetInnerHTML={{ __html: emailinfo?.body }} />
                                            </div>
                                        </UncontrolledCollapse>
                                    </div>}

                                    {emailinfo && emailinfo.replies && emailinfo.replies.length ? emailinfo.replies.map((item: any, key: any) => {
                                        return <div className="accordion-item border-dashed left">
                                            <div className="accordion-header">
                                                <a role="button" href='/#' className="btn w-100 text-start px-0 bg-transparent shadow-none collapsed" id={`email-collapse1-${key}`}>
                                                    <div className="d-flex align-items-center text-muted">
                                                        <div className="flex-shrink-0 avatar-xs me-3">
                                                            <img src={avatar1} alt="" className="img-fluid rounded-circle" />
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <h5 className="fs-14 text-truncate mb-0 email-user-name">{item?.createdBy?.name}</h5>
                                                            <div className="text-truncate fs-12">to:{item?.to}</div>
                                                        </div>
                                                        <div className="flex-shrink-0 align-self-start">
                                                            <div className="text-muted fs-12">{moment(item?.createdAt).format('DD MMM YYYY, HH:mm A')}</div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <UncontrolledCollapse toggler={`#email-collapse1-${key}`}>
                                                <div className="accordion-body text-body px-0">
                                                    <div dangerouslySetInnerHTML={{ __html: item?.body }} />
                                                </div>
                                            </UncontrolledCollapse>
                                        </div>
                                    }) : null}
                                </div>
                                <div className="mt-auto email-detail-content-scroll">
                                    <form className="mt-2">
                                        <div>
                                            <label htmlFor="exampleFormControlTextarea1" className="form-label">{props.t("student.reply")} :</label>
                                            <div className="ck-editor-reverse">
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    config={editorConfig}
                                                    data={emailData.body}
                                                    onReady={(editor) => { }}
                                                    onChange={(event: any, editor: any) => {
                                                        const data = editor.getData();
                                                        setEmailData({ ...emailData, body: data })
                                                    }}
                                                />
                                            </div>
                                            <div className="bg-light px-2 py-1 rouned-bottom border">
                                                <Row>
                                                    <Col className="col-auto">
                                                        <UncontrolledButtonDropdown>
                                                            <Button color="success" className="btn-sm" onClick={(e: any) => { e.preventDefault(); onReply() }}><i className="ri-send-plane-2-fill align-bottom" /></Button>

                                                        </UncontrolledButtonDropdown>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </SimpleBar>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(Activities);
