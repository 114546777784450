import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Card, CardBody, Nav, NavItem, NavLink, Offcanvas, OffcanvasBody, OffcanvasHeader, TabContent, TabPane } from "reactstrap";
import BasicInfo from "../steps/BasicInfo";
import FieldType from "../steps/FieldType";
import Rules from "../steps/Rules";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { propertyCreateRequest, propertyListGetRequest } from '../../../store/properties/action';
import { useDispatch } from "react-redux";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { ToastContainer, toast } from "react-toastify";
import Conditional from "../steps/Conditional";

const CreatePropertyModal = ({ props, setCreateInputs, model, createInputs, show, onCloseClick, columns, searchData, pageSize, setIsMultiDeleteButton, setselectedcount, addoption, inputs }: any) => {

    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [activeArrowTab, setactiveArrowTab] = useState(1);
    const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
    const [disabled, setdisabled] = useState<boolean>(false);
    const [fieldJson, setFieldJson] = useState<any>(null);

    const toggleArrowTab = (tab: any) => {
        if (activeArrowTab !== tab) {
            var modifiedSteps = [...passedarrowSteps, tab];

            if (tab >= 1 && tab <= 4) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    }
    const nextClicked = () => {
        toggleArrowTab(activeArrowTab + 1)
    }
    const backClicked = () => {
        toggleArrowTab(activeArrowTab - 1)
    }


    const createProperty = () => {
        let newfieldJson = { ...fieldJson, label: createInputs.label }
        const data = {
            "valuesJson": JSON.stringify(newfieldJson),
            "model": createInputs.objectType,
            "groupId": createInputs.groupId
        }
        const handleSuccess = () => {
            if (createInputs.objectType == model) dispatch(propertyListGetRequest(createInputs.objectType))
            toggleArrowTab(1)
            onCloseClick()
            setCreateInputs({})
        }
        const handleFailure = () => {

        }
        dispatch(propertyCreateRequest(data, handleSuccess, handleFailure))
    }

    useEffect(() => {
        switch (activeArrowTab) {
            case 1: setdisabled(!createInputs.label || !createInputs.objectType || !createInputs.groupId)
                break;
            case 2: setdisabled(!createInputs.fieldtype)
                break;
        }
    }, [createInputs]);
    useEffect(() => {
        switch (activeArrowTab) {
            case 1: setdisabled(!createInputs.label || !createInputs.objectType || !createInputs.groupId)
                break;
            case 2: setdisabled(!createInputs.fieldtype)
                break;
        }
    }, [activeArrowTab]);

    return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasWithBothOptions"
            backdrop={false}
            toggle={onCloseClick}
            className={createInputs && (createInputs.fieldtype == "multi_checkboxes" || createInputs.fieldtype == "dropdown_select" || createInputs == "radio_select") ? "w-75" : "w-50"}
        ><ToastContainer />
            <OffcanvasHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" id="offcanvasWithBothOptions" toggle={() => { onCloseClick(); toggleArrowTab(1); setCreateInputs({}) }}>
                <span className="m-0 me-2 text-white">{props.t("properties.create_a_new_property")}</span>
            </OffcanvasHeader>
            <OffcanvasBody id="offcanvasWithBothOptions">
                <Card>
                    <CardBody>
                        <div className="step-arrow-nav mb-4">
                            <Nav
                                className="nav-pills custom-nav nav-justified"
                                role="tablist"
                            >
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        id="basic-primary"
                                        className={classnames({
                                            active: activeArrowTab === 1,
                                            done: activeArrowTab <= 3 && activeArrowTab > 1,
                                        })}
                                    >
                                        Basic info
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        id="field-type"
                                        className={classnames({
                                            active: activeArrowTab === 2,
                                            done: activeArrowTab <= 4 && activeArrowTab > 2,
                                        })}
                                    >
                                        Field Type
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        id="rules"
                                        className={classnames({
                                            active: activeArrowTab === 3,
                                            done: activeArrowTab <= 5 && activeArrowTab > 3,
                                        })}
                                    >
                                        Rules
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        id="conditional"
                                        className={classnames({
                                            active: activeArrowTab === 4,
                                            done: activeArrowTab <= 6 && activeArrowTab > 4,
                                        })}
                                    >
                                        Conditional
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                        <TabContent activeTab={activeArrowTab} className="mt-4">
                            <TabPane id="basic-info" tabId={1}>
                                <BasicInfo
                                    setCreateInputs={setCreateInputs}
                                    createInputs={createInputs}
                                    model={model}
                                />
                            </TabPane>
                            <TabPane id="field-type" tabId={2}>
                                <FieldType
                                    setCreateInputs={setCreateInputs}
                                    createInputs={createInputs}
                                    fieldJson={fieldJson}
                                    setFieldJson={setFieldJson}
                                />
                            </TabPane>
                            <TabPane id="rules" tabId={3}>
                                <Rules
                                    setCreateInputs={setCreateInputs}
                                    createInputs={createInputs}
                                    fieldJson={fieldJson}
                                    setFieldJson={setFieldJson}
                                />
                            </TabPane>
                            <TabPane id="Condition" tabId={4}>
                                <Conditional
                                    setCreateInputs={setCreateInputs}
                                    createInputs={createInputs}
                                    fieldJson={fieldJson}
                                    setFieldJson={setFieldJson}
                                    model={model}
                                />
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </OffcanvasBody>
            <div className="offcanvas-footer bg-primary bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                <div className="hstack gap-2">
                    {activeArrowTab > 1 && activeArrowTab < 5 ?
                        <Button
                            color="primary"
                            className="w-auto"
                            onClick={() => backClicked()}
                            outline={true}
                        >
                            {props.t("properties.back")}
                        </Button> : null
                    }
                    <Link
                        to="#"
                        className="text-danger fw-bold w-auto"
                        onClick={() => { onCloseClick(); toggleArrowTab(1); setCreateInputs({}) }}
                    >
                        {props.t("properties.cancel")}
                    </Link>
                </div>
                <Button
                    color="primary"
                    className="btn btn-primary w-auto"
                    disabled={disabled}
                    onClick={activeArrowTab == 4 ? () => createProperty() : () => nextClicked()}
                >
                    {activeArrowTab == 4 ? props.t("properties.create") : props.t("properties.next")}
                </Button>
            </div>
        </Offcanvas>
    );
};

export default withTranslation()(CreatePropertyModal);
