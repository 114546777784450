import React from "react";
import { withTranslation } from "react-i18next";

interface ColumnsProps {
    message: string;
    t: (key: string) => string;
}

const Empty: React.FC<ColumnsProps> = ({ t, message }) => {
    return (
        <div className="d-flex flex-column gap-2 overflow-y-scroll">
            <div className="position-relative accordion-item border border-dark-subtle rounded-2 bg-light border-dashed">
                <div className="w-100 p-3 m-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className='d-flex flex-column gap-1'>
                            <div className="fw-semibold text-muted">{message}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default withTranslation()(Empty);
