import { CourseActionTypes, Course } from "./types";

import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../index";
import config from "../../config";
import { createQueryLink, responseHandler } from "utils";

const { api } = config;

const DTS_API_DOMAIN = api.DTS_API_DOMAIN
const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const courseGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        dispatch({
            type: CourseActionTypes.GET_COURSE_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/models/courses/${id}`, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, CourseActionTypes.GET_COURSE_SUCCESS, CourseActionTypes.GET_COURSE_ERROR, null, null, null, "GET");   
            
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const coursesMetadataGetRequest: AppThunk = (model, filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CourseActionTypes.GET_COURSEMETADATA_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        if (model) {
            headers.append('Properties-Model', model);
        }
        const searchQuery = createQueryLink(filterObject)
        try {
            const response = await fetch(searchQuery, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, CourseActionTypes.GET_COURSEMETADATA_SUCCESS, CourseActionTypes.GET_COURSEMETADATA_ERROR, null, null, null, "GET");

        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const courseUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CourseActionTypes.PUT_COURSE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        const response = await fetch(
            `${DTS_API_STUDENT}/models/courses/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const error = {
            204: {
                "message": "Course updated Successfully",
                "success": true
            },
            200: {
                "message": "Course updated Successfully",
                "success": true
            }
        }
        await responseHandler(response, dispatch, CourseActionTypes.PUT_COURSE_SUCCESS, CourseActionTypes.PUT_COURSE_ERROR, error, handleSuccess, handleError);
    };
};

export const courseDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CourseActionTypes.DELETE_COURSE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        const response = await fetch(`${DTS_API_STUDENT}/models/courses/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const error = {
                204: {
                    "message": "Course deleted Successfully",
                    "success": true
                },
                200: {
                    "message": "Course deleted Successfully",
                    "success": true
                }
            }
            await responseHandler(response, dispatch, CourseActionTypes.DELETE_COURSE_SUCCESS, CourseActionTypes.DELETE_COURSE_ERROR, error, handleSuccess, handleError);

        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: CourseActionTypes.DELETE_COURSE_ERROR
            });
            handleSuccess();
        }
    };
};

export const courseListGetRequest: AppThunk = (filters,pageno, pageSize, signal) => {
    let requestbody = filters ? filters : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CourseActionTypes.GET_COURSES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }              
        const response = await fetch(`${DTS_API_STUDENT}/models/get/courses?page=${pageno?pageno:0}&pageSize=${pageSize?pageSize:10}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestbody),
            signal
        }).catch((error) => {
            return false;
        });
        try {
            if(typeof response === 'object' && !Array.isArray(response) && response !== null) {
                const body = await response.json();
                const {response: response_1, body: body_1} = {response, body};
                if (!response_1.ok) {
                    dispatch({
                        type: CourseActionTypes.GET_COURSES_ERROR,
                        payload: body_1
                    });
                } else {
                    dispatch({
                        type: CourseActionTypes.GET_COURSES_SUCCESS,
                        payload: body_1
                    });
                }
            }
        }
        catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: CourseActionTypes.GET_COURSES_ERROR
            });
        }
    };
};

export const optionsListGetRequest: AppThunk = (model,data, page, size, filterObject, signal) => {
    let responseType =  filterObject ? `&responseType=${filterObject['responseType']}` : null
    let requestbody = data ? data : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CourseActionTypes.GET_OPTIONS_REQUEST,
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        const response = await fetch(`${DTS_API_STUDENT}/models/get/${model}?page=${page?page:0}&pageSize=${size?size:500}&${responseType?responseType:""}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestbody),
            signal
        }).catch((error) => {
            return false;
        });
        if(typeof response === 'object' && !Array.isArray(response) && response !== null) {
            const body = await response?.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: CourseActionTypes.GET_OPTIONS_ERROR,
                    payload: body_1
                });
            } else {
                dispatch({
                    type: CourseActionTypes.GET_OPTIONS_SUCCESS,
                    payload: body_1
                });
            }
        }
    };
};

export const courseCreateRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CourseActionTypes.POST_COURSE_REQUEST
        });
        try {
            const response = await fetch(
                `${DTS_API_STUDENT}/models/courses`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "token": token
                    },
                    body: JSON.stringify(data)
                }
            );

            const error = {
                208: {
                    "message": "Course already exist",
                    "success": false
                },
                201: {
                    "message": "Course created Successfully",
                    "success": true
                },        
                200: {
                    "message": "Course created Successfully",
                    "success": true
                }
            }
            await responseHandler(response, dispatch, CourseActionTypes.POST_COURSE_SUCCESS, CourseActionTypes.POST_COURSE_ERROR, error, handleSuccess, handleError);

        } catch (error) {
            dispatch({ type: CourseActionTypes.POST_COURSE_ERROR, payload: error });
            handleError();
        }
    };
};

export const CoursesGetRequest: AppThunk = (data, page, size, signal) => {
    let requestbody = data ? data : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CourseActionTypes.GET_FILTER_COURSES_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        const response = await fetch(`${DTS_API_DOMAIN}/courses/get?page=${page}&pageSize=${size}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestbody),
            signal
        }).catch((error) => {
            return false;
        });
        if (typeof response === 'object' && !Array.isArray(response) && response !== null) {
            const body = await response?.json();
            const { response: response_1, body: body_1 } = { response, body };
            if (!response_1.ok) {
                dispatch({
                    type: CourseActionTypes.GET_FILTER_COURSES_ERROR,
                    payload: body_1
                });
            } else {
                dispatch({
                    type: CourseActionTypes.GET_FILTER_COURSES_SUCCESS,
                    payload: body_1
                });
            }
        }
    };
};



