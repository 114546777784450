import React, { useEffect, useState } from "react";
import {
    Label,
    Input,
    Form,
    FormGroup,
    FormFeedback,
} from "reactstrap";

interface DtsCheckboxProps {
    label: string;
    value: any;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    checked?: boolean;
    name?: any;
    rest?: any;
    actionButtons?: any;
    setdisabled?: any;
    setError?: any;
    formerror?: any;
    resetvalue?: any;
}

const DtsCheckbox: React.FC<DtsCheckboxProps> = ({ setdisabled, rest, label, value, onChange, name }) => {
    const [errormsg, setErrormsg] = useState<any>(null);
    const [selectedcheckbox, setSelectedcheckbox] = useState<any>(null);
    const [formoptions, setOptions] = useState<any>(false)
        const onhandleChange = (newOption: any) => {
            let newvalue = value ? value : [];
            const nameColumnIndex = newvalue && newvalue.length ? newvalue.findIndex((x: any) => x == newOption.value) : -1;           
            if(nameColumnIndex !== -1) {
                let x = newvalue.splice(nameColumnIndex, 1)[0]; 
            }
            else {
                if(newvalue) newvalue.push(newOption.value) 
                else newvalue[0] = newOption.value;
            }            
        onChange(newvalue)
        validateInput(newvalue);
        setSelectedcheckbox(newvalue)
    }
    const validateInput = (value: any) => { 
        if(rest && rest.validate && rest.validate.required) {
            if(value === "" || !value?.length ) {
               let error: any =  `Please enter ${label}`;
                setErrormsg(error)
                return false
            }
        }
        setErrormsg(undefined)
        return true
    }
    useEffect(() => {
        if(rest && rest.validate) {           
            if(errormsg)
            { 
                if (typeof setdisabled === 'function') {
                    setdisabled(true)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if(inputdiv) inputdiv.classList.add('d-block');
            } 
            else {
                if (typeof setdisabled === 'function') {
                    setdisabled(false)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if(inputdiv) inputdiv.classList.remove('d-block');
            }            
        }
        else {
            setErrormsg(undefined)
        }
    },[value])
    useEffect(() => {
        if (rest && rest.validate && rest.validate.required) {
            if (value === false) {
                const inputdiv: any = document.getElementById(`${name}input`);
                if (inputdiv) inputdiv.classList.add('d-block');
            }
            else {
                const inputdiv: any = document.getElementById(`${name}input`);
                if (inputdiv) inputdiv.classList.remove('d-block');
            }
        }
    }, [value])

    useEffect(() => {
        if (rest && rest.validate && rest.validate.required) {
            const feedback: any = document.getElementById(`${name}`);
            if (feedback) feedback.classList.remove('d-none');
        }
    }, [rest])
    useEffect(() => {
        if (rest && rest.values && rest.values.length) {
            let newoptions = rest.values.sort((a: any, b: any) => a.id - b.id);
            let tempOptions = newoptions.filter((a: any, b: any) => a.in_forms == true);
            setOptions(tempOptions);
        }

    }, [rest])
    return (
        <Form>
            {rest && rest.values && rest.values.length ? <div>
                {label ? <Label htmlFor={name} className="form-label fw-bold fs-14 mb-0">{label}<span id={`${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label> : null}
                <div className="hstack justify-content-start gap-4 flex-wrap">
                    {formoptions && formoptions.length ? formoptions.map((item: any, index: number) => {
                        let isChecked = value && value?.length ? value?.filter((x: any) => (x == item.value || x == item)) : false
                        return <div className="ps-4">
                            <Input
                                className={"form-check-input"}
                                type={"checkbox"}
                                id={item.value}
                                checked={isChecked?.length > 0 || false}
                                onChange={(e) => onhandleChange(item)}
                            />
                            <Label className="form-check-label" htmlFor={name}>
                               {item.label}
                            </Label>
                            {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}
                        </div>
                    }): null}
                </div></div>
                :
                <div className="form-check">
                    <Input
                        className={"form-check-input"}
                        type={"checkbox"}
                        // id={value}
                        checked={value == name}
                        onChange={(e) => onhandleChange(name)}
                    />
                    <Label className="fs-16 mb-0 fw-semibold" htmlFor={name}>{label}</Label>
                    {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}
                </div>
            }
        </Form>
    );
};

export default DtsCheckbox;