import { Fragment, useEffect, useState } from "react";
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { Table, Row } from "reactstrap";
import Paginator from "Components/Common/Paginator";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { checkTheyOwn } from "utils";

interface TableContainerProps {
    columns?: any;
    data?: any;
    tableClass?: any;
    customPageSize?: any;
    isPageSizeChange?: any;
    handleLeadClick: (e: any) => void,
    setisDeleteOpen: (e: any) => void,
    pageChanged: (e: any) => void,
    pagination: any;
    pageSize: any;
    setpreview: (e: any) => void;
    setPagination: (e: any) => void;
    onChangePageSize: (e: any) => void;
    props: any;
    access: any;
    setUpdateModal: (e: any) => void;
    redirect: (e: any) => void;
    theadClass: any;
}

const TableContainer = ({
    columns,
    data,
    isPageSizeChange,
    customPageSize,
    tableClass,
    handleLeadClick,
    setisDeleteOpen,
    pageChanged,
    pagination,
    onChangePageSize,
    setpreview,
    pageSize,
    props,
    access,
    setUpdateModal,
    redirect,
    theadClass
}: TableContainerProps) => {
    const {
        getTableProps,
        getTableBodyProps,
        page,
        headerGroups,
        prepareRow,
        setPageSize,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0, pageSize: customPageSize
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );
    const onChangeInSelect = (event: any) => {
        setPageSize(Number(event.target.value));
        onChangePageSize(event.target.value);
    };
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);

    const [view, setView] = useState(false);
    const [edit, setEdit] = useState<any>(null);
    const [deleteaccess, setDeleteAccess] = useState<any>(null);

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setView(true)
            setEdit("all")
            setDeleteAccess("all")
        }
        else if (access && access.length) {
            access.map((item: any, index: any) => {
                if (item.name) {
                    switch (item.name) {
                        case "View": item.value == "" || item.value == "none" ? setView(false) : setView(true)
                            break
                        case "Edit": item.value == "" || item.value == "none" ? setEdit(null) : setEdit(item.value)
                            break
                        case "Delete": item.value == "" || item.value == "none" ? setDeleteAccess(null) : setDeleteAccess(item.value)
                            break
                    }
                }

            })
        }
        else {
            setView(false)
            setEdit(false)
            setDeleteAccess(false)
        }
    }, [access, userProfile])
    
    return (
        <div className="table-responsive table-card mt-4">
            <Table responsive={true} hover {...getTableProps()} className={tableClass + 'm-0 table table-hover table-centered align-middle table-nowrap mb-0'} size="sm">
            <thead className={theadClass}>
                    {headerGroups.map((headerGroup: any) => (
                        <tr key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th key={column.id} className={ " fw-bold"}
                                    {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}
                                >
                                    <div   className={"cursor-pointer" + `${column.accessor == 'channel_name' ? '': ' otherth'}`}  title={column.Header}>
                                        {column.render("Header")}
                                    </div>   
                                </th>
                            ))}
                            <th  className={ "text-center fw-bold"}  >
                                Actions
                            </th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row: any) => {
                        prepareRow(row);
                        let they_own = checkTheyOwn(row.original, userProfile?.id)
                        return (
                            <Fragment key={row.getRowProps().key}>
                                <tr style={{ verticalAlign: 'middle' }}>
                                    {row.cells.map((cell: any) => {
                                        let classList = cell.column.className;
                                        return (
                                            <td key={cell.id} style={{ verticalAlign: 'middle' }} className='align-items-center justify-content-between' {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                    <td className="text-center">
                                        <ul className="list-inline hstack gap-2 mb-0 hstack justify-content-center text-center align-self-center">
                                            {view ?
                                                <li className="list-inline-item edit" title="View">
                                                    <button className="btn btn-sm btn-soft-info remove-list fs-12" onClick={() => { const LeadData = row.original; handleLeadClick(LeadData); redirect(LeadData) }}
                                                    >
                                                        <i className="ri-eye-fill"></i>{" "}
                                                    </button>

                                                </li> : null}
                                                {edit && (edit?.toLowerCase() === "all" || (edit?.toLowerCase() === "they_own" && they_own)) ?
                                                <li className="list-inline-item edit" title="Edit">
                                                    <button className="btn btn-sm btn-soft-secondary remove-list" onClick={() => { const LeadData = row.original; handleLeadClick(LeadData);  redirect(LeadData) }}
                                                    >
                                                        <i className="ri-pencil-fill"></i>{" "}
                                                    </button>{" "}
                                                </li> : null}
                                                {deleteaccess && (deleteaccess?.toLowerCase() === "all" || (deleteaccess?.toLowerCase() === "they_own" && they_own)) ?
                                                    <li className="list-inline-item edit" title="Delete">
                                                        <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => { const LeadData = row.original; handleLeadClick(LeadData); setisDeleteOpen(true); setpreview(false); }}
                                                        >
                                                            <i className="ri-delete-bin-fill"></i>{" "}
                                                        </button>{" "}
                                                    </li>
                                                    : null}
                                        </ul>
                                    </td>
                                </tr>
                            </Fragment>
                        );
                    })}
                </tbody>
            </Table>
            <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                <div className="col-sm">
                    <div className="text-muted">{props.t("channel.showing")}<span className="fw-semibold ms-1">{data.length}</span> {props.t("channel.of")}<span className="fw-semibold"> {pagination && pagination.elements ? pagination.elements : 0}</span> {props.t("channel.results")}</div>
                </div>
                <div className="col-sm-auto">
                    <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                        currentPage={pagination?.page}
                        pageChanged={(e: any) => pageChanged(e)}
                        pageSize={pageSize}
                        isPageSizeChange={isPageSizeChange}
                        onChangeInSelect={(e: any) => onChangeInSelect(e)}
                        pagination={pagination}
                        props={props}
                    />
                </div>
            </Row>
        </div>
    );
};

export default TableContainer;