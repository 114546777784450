import BreadCrumb from 'Components/Common/BreadCrumb';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Button,
    Col,
    Container,
    Nav,
    NavItem,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import RoleName from './RoleName';
import { Link } from 'react-router-dom';
import Permissions from './Permissions';
import Review from './Review';
import accessjson from './AccessData.json';
import { permissionCreateRequest, permissionsGetRequest, roleListGetRequest } from 'store/role/action';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';

interface Options {
    label: string;
    value: string;
}

interface Permission {
    name: string;
    options?: Options[];
    value: string; 
}

interface Feature {
    description: string;
    editable: boolean;
    name: string;
    value: boolean; 
    type: string;
    permissions: Permission[];
    options?: Options[];
}

interface SubItem {
    name: string;
    children: Feature[];
    permissions: any;
}

interface MenuItem {
    name: string;
    children: SubItem[];
}

interface CreateInputs {
    role: string;
}
interface permissionData {
    permissionId: string,
    value: string
}

interface RolesProps extends WithTranslation {
    onCloseClick: () => void;
    show: boolean;
    record: any;
    updateId: any;
    predefinedPermissions: any
}

const Roles: React.FC<RolesProps> = ({ onCloseClick, show, t, record, updateId, predefinedPermissions }: RolesProps) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch(); 

    const permissionsdata = useSelector((state: ApplicationState) => state.role.permissionsdata);
    const roleDetails = useSelector((state: ApplicationState) => state.role.roleDetails);
    const [activeArrowTab, setactiveArrowTab] = useState<number>(1);
    const [passedarrowSteps, setPassedarrowSteps] = useState<number[]>([1]);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [accessData, setAccessData] = useState<any[]>([]);
    const [createdRole, setCreatedRole] = useState<any>({});
    const [createInputs, setCreateInputs] = useState<CreateInputs>({
        role: ''
    });
    const [accessPermissions, setAccessPermissions] = useState<permissionData[]>([]);

    const toggleArrowTab = (tab: number) => {
        if (activeArrowTab !== tab) {
            const modifiedSteps = [...passedarrowSteps, tab];
            if (tab >= 1 && tab <= 3) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    };

    const updateRole = () => {
        const data =  {
            title: createInputs.role,
            rolePermissionValue: accessPermissions
        } 
        const handleSuccess = (body: any): void => {
            setAccessPermissions([])
            onCloseClick()
            setactiveArrowTab(1);
            dispatch(roleListGetRequest()); 
            setAccessData(permissionsdata)
        }
        const handleError = (body: any): void => {
        }
        dispatch(permissionCreateRequest(data,updateId, handleSuccess, handleError));     
    };

    const nextClicked = (value: any) => {
        switch(value) {
            case 1: 
                toggleArrowTab(activeArrowTab + 1);
                break;
            case 2: 
                toggleArrowTab(activeArrowTab + 1);
                break;
            case 3: 
                updateRole()
                toggleArrowTab(activeArrowTab + 1);
                break;
        }
        
    };

    const backClicked = () => {
        toggleArrowTab(activeArrowTab - 1);
    };

    useEffect(() => {
        switch (activeArrowTab) {
            case 1:
                setDisabled(!createInputs.role);
                break;
            // Add cases for other tabs if needed
            default:
                break;
        }
    }, [activeArrowTab, createInputs]);

    const updateObjectInHierarchy = (array: any, updatedObject: any) => {        
        return array && array.length && array.map((item: any) => {
            if (item.id === updatedObject.id) { 
                 return { ...item, ...updatedObject }; 
            } else if (item.children && item.children.length > 0) {
                return { ...item, children: updateObjectInHierarchy(item.children, updatedObject) };
            } else if (item.permissions && item.permissions.length > 0) {
                return { ...item, permissions: updateObjectInHierarchy(item.permissions, updatedObject) };
            } else {
                return item;
            }
        });
    };

    const updateObject = (updatedObject: any) => {
        const updatedArray = updateObjectInHierarchy(accessData, updatedObject);
        setAccessData(updatedArray);
    };
    

    useEffect(() => {
        dispatch(permissionsGetRequest())       
        setAccessData(permissionsdata)
    },[permissionsdata !== undefined])

    useEffect(() => {
        let newrole = roleDetails && roleDetails.role?.title ? roleDetails.role.title : '';
        setCreateInputs({...createInputs, role: newrole });
        
        if(roleDetails && roleDetails.permissions && roleDetails.permissions.length) {     
            let newAccessPermission : any = []       
            roleDetails.permissions.map((permission: any, index: any) => {
                let permissionItem: any = {
                    permissionId: permission.id,
                    value: permission.value
                }
                newAccessPermission.push(permissionItem);
                // setAccessPermissions([...newAccessPermission, permissionItem])     
            })
            setAccessPermissions(newAccessPermission) 
        }
        setAccessData(predefinedPermissions)
    }, [roleDetails]);

    useEffect(() => {
        setAccessData(predefinedPermissions)
    }, [predefinedPermissions]);
    return (
        <React.Fragment>
            <Offcanvas
                direction="end"
                isOpen={show}
                id="offcanvasWithBothOptions"
                toggle={onCloseClick}
                className="w-100"
            >
                <OffcanvasBody id="offcanvasWithBothOptions">
                    <div className="hstack step-arrow-nav mb-4">
                        <Row className="w-100 hstack justify-content-center">
                            <Col lg={8} className="hstack justify-content-between">
                                <Nav
                                    className="w-100 nav-pills custom-nav nav-justified"
                                    role="tablist"
                                >
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            id="basic-info"
                                            className={classnames({
                                                active: activeArrowTab === 1,
                                                done: activeArrowTab <= 3 && activeArrowTab > 0,
                                            })}
                                        >
                                            {t('roles.role_name')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            id="field-type"
                                            className={classnames({
                                                active: activeArrowTab === 2,
                                                done: activeArrowTab <= 3 && activeArrowTab > 1,
                                            })}
                                        >
                                             {t('roles.permissions')}                                            
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            id="rules"
                                            className={classnames({
                                                active: activeArrowTab === 3,
                                                done: activeArrowTab <= 4 && activeArrowTab > 2,
                                            })}
                                        >
                                            {t('roles.review')}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </div>
                    <TabContent activeTab={activeArrowTab}>
                        <TabPane id="basic-info" tabId={1}>
                            <Row className="hstack justify-content-center">
                                <Col lg={5}>
                                    <div className="hstack justify-content-center">
                                        <RoleName
                                            createInputs={createInputs}
                                            setCreateInputs={setCreateInputs}
                                            record={record}
                                            nextClicked={() => nextClicked(activeArrowTab)}

                                        />
                                    </div>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane id="field-type" tabId={2}>
                            <Row className="hstack justify-content-center">
                                <Col lg={10}>
                                    <Permissions
                                        createInputs={createInputs}
                                        setAccessData={setAccessData}
                                        accessData={accessData}
                                        setAccessPermissions={setAccessPermissions}
                                        accessPermissions={accessPermissions}
                                    />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane id="rules" tabId={3}>
                            <Row className="hstack justify-content-center">
                                <Col lg={8}>
                                    <Review
                                        createInputs={createInputs}
                                        accessData={accessData}
                                    />
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </OffcanvasBody>
                <div className="offcanvas-footer bg-info bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                    <div className="hstack gap-2">
                        {activeArrowTab > 1 && activeArrowTab < 4 ? (
                            <Button
                                color="info"
                                className="w-auto"
                                onClick={() => backClicked()}
                                outline={true}
                            >
                                {t('roles.back')}
                            </Button>
                        ) : null}
                        <Link
                            to="#"
                            className="text-danger fw-bold w-auto"
                            onClick={() => {onCloseClick(); setactiveArrowTab(1); setAccessPermissions([]); setAccessData(permissionsdata)}}
                        >
                            {t('roles.cancel')}
                        </Link>
                    </div>
                    <h5 className='text-dark fw-bold'>{t("roles.editing_permissions_for_role")} {createInputs && createInputs.role ? createInputs.role:""}</h5>
                    <Button
                        color="info"
                        className="btn btn-info w-auto"
                        disabled={disabled}
                        onClick={() => nextClicked(activeArrowTab)}
                    >
                        {activeArrowTab === 3 ? t('roles.update') : t('roles.next')}
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    );
};

export default withTranslation()(Roles);
