import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Form,
    Button,
    Col,
    Row,
} from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { isJson } from "utils";
import { getAvailableStatuses } from "helpers/workflowStatusHelper";
import DynamicTable from "./DynamicTable";
import _ from "lodash";

const QuickFormComponent = ({ dataFields, btnAction, btn_text, APAId , data, btndisabled, xl, lg, md, sm, xs, BtnClassName, theme, key, commissionJson, tier, preFilledKey, pathParts}: any) => {
    const filterFields: any = [];   
   
    dataFields && dataFields.length && dataFields.map((field: any) => {           
        let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {...field.valuesJson}   
        if (field.valuesJson) return filterFields.push(item)
    })
    const [formValues, setFormValues] = useState<any>({});
    const [disabled, setdisabled] = useState<any>(false);
    const [formerror, setError] = useState<any>([]);
    const [tableData , setTableData] = useState<any>([]);
    const [request, setRequest] = useState<any>([]);
    // const [data, setData] = useState<any>([])

    const onChange = (option: any, key: any, field: any) => {  
        if(field.type === "selectboxes" || field.isMulti) {   
            setFormValues({ ...formValues, [key]: option })
        } 
        else if (typeof option === 'object') {            
            setFormValues({ ...formValues, [key]: option?.value || option })
        }
        else {
            setFormValues({ ...formValues, [key]: option })
        }
    };
    const allMandatoryFieldsFilled = () => {
        for (const field of filterFields) {
            if (field && field.validate && field.validate.required && !formValues[field.key]) {
                return false;
            }
        }
        return true;
    };

    useEffect(() => {  
        if(preFilledKey && pathParts.includes('create')) {
            let newValues = {...formValues,...preFilledKey}
            setFormValues(newValues)
        } 
        else if(data) {
            let values=data;
            setFormValues({...values})
            if(data?.data?.length) {
                let newData = _.cloneDeep(data?.data)
                setTableData(newData)
            }
        }
    }, [data, preFilledKey])

    const formdisabled = Object.keys(formerror).length === 0;

    useEffect(() => {
        setRequest({...formValues, data: tableData})
    }, [formValues, tableData])
    

    useEffect(() => {
        if(tier) {
            if(commissionJson && formValues?.clone_from_basic) {
                 let newData = JSON.parse(JSON.stringify(commissionJson?.basic?.data))
                 let newFormValues = {...formValues,commissionable_period: commissionJson?.basic?.commissionable_period}
                 setFormValues(newFormValues)     
                 setTableData(newData)
                 setRequest({...newFormValues , data: newData})
             }
             else if(data) {
                 let values=data;
                 setFormValues({...values,clone_from_basic: formValues?.clone_from_basic, multiple_payments: true})
                 if(data?.data?.length) {
                     let newData = _.cloneDeep(data?.data)
                     setTableData(newData)
                 }
             }
             else {
                 let newFormValues = {...formValues, clone_from_basic: formValues?.clone_from_basic, commissionable_period: null, multiple_payments: true}
                 setFormValues(newFormValues)      
                 setTableData([])
             }
        }
    }, [formValues?.clone_from_basic])
                
    return (
        <Form action="" className="d-flex flex-column justify-content-end h-100">
            <div className="live-preview vstack gap-2">
                <Row>
                    {filterFields && filterFields.length ? filterFields.map((field: any) => { 
                        let width = field.key === 'notes' || field.key === 'channelId' || field.key === 'instituteId' ? 12 : xl?xl:12;
                        if(field.quickCreate) return <Col xl={width} lg={width} md={width} sm={width} xs={width} className={field.type === 'checkbox' ? "hstack align-items-center" : ''}><FormBuilder
                            placeholder={field.placeholder}
                            name={field.key}
                            onChange={(e: any) => onChange(e, field.key, field)}
                            options={(field.values && field.values.reverse()) || []}
                            label={field.label}
                            type={field.type}
                            value={formValues[field.key]}
                            isEditState={true}
                            actionButtons={false}
                            defaultValue={formValues[field.key]}
                            Editable={true}
                            rest={field}
                            invalid={true}
                            formValues={formValues}
                            dataFields={filterFields}
                            setdisabled={setdisabled}
                            setError={setError}
                            formerror={formerror}
                            resetValue={formValues[field.key]}
                        /></Col>
                    }): null}
                </Row>
                <Row>
                    {
                        formValues?.commissionable_period && formValues?.multiple_payments ?
                        <DynamicTable paymentsCount={formValues?.number_of_payments} setFormValues={setFormValues} formValues={formValues}
                        period={formValues?.commissionable_period} setTableData={setTableData} dataValues={data?.data ? [...data?.data] : []} tableData={tableData}
                        /> : null
                    }
                </Row>
            </div>
            <div className="offcanvas-footer bg-opacity-10 border-top p-3 justify-content-center hstack gap-2">
                <Button
                    color={theme ? theme : ''}
                    className={ BtnClassName ? BtnClassName : "btn btn-success w-100"}
                    disabled={!allMandatoryFieldsFilled() || disabled || !formdisabled || btndisabled}
                    onClick={() => btnAction(request)}
                >
                    {btn_text}
                </Button>
            </div>
        </Form>
    );
};

export default QuickFormComponent;

