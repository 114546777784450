import {Reducer} from "redux";
import {ReportActionTypes, ReportState} from "./types";


export const initialState: ReportState = { 
    tables: [],
    tables_loading: false,
    error: null,
    columns: [],
    columns_loading: false,
    generated_report: null,
    generated_report_loading: false,
    generated_report_detail: null,
    generated_report_detail_loading: false,
    pagination_detail: null,
    report_feature_status_loading: false,
    report_feature_status: null,
    report_feature_activation_loading: false,
    report_save_loading: false,
    report_delete_loading: false,
    reports_loading: false,
    reports: null,
    reports_pagination: null,
    report_loading: false,
    report: null,
    dashboard_save_loading: false,
    dashboards_loading: false,
    dashboards: null,
    dashboards_pagination: null,
    dashboard_loading: false,
    dashboard: null,
    dashboard_delete_loading: false
};

const reducer: Reducer<ReportState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case ReportActionTypes.GET_TABLES_REQUEST: {
            return {...state, tables_loading: true};
        }
        case ReportActionTypes.GET_TABLES_SUCCESS: {
            return {
                ...state,
                tables_loading: false,
                tables: action.payload
            };
        }
        case ReportActionTypes.GET_TABLES_ERROR: {
            return {...state, tables_loading: false, error: action.payload};
        }
        case ReportActionTypes.GET_COLUMNS_REQUEST: {
            return {...state, columns_loading: true};
        }
        case ReportActionTypes.GET_COLUMNS_SUCCESS: {
            return {
                ...state,
                columns_loading: false,
                columns: action.payload
            };
        }
        case ReportActionTypes.GET_COLUMNS_ERROR: {
            return {...state, columns_loading: false, error: action.payload};
        }
        case ReportActionTypes.GENERATE_REPORT_REQUEST: {
            return {...state, generated_report_loading: true};
        }
        case ReportActionTypes.GENERATE_REPORT_SUCCESS: {
            return {
                ...state,
                generated_report_loading: false,
                generated_report: action.payload,
                pagination_detail: {
                    page: action.payload?.pagination?.page,
                    size: action.payload?.pagination?.size,
                    pages: action.payload?.pagination?.pages,
                    elements: action.payload?.pagination?.total,
                    first: action.payload?.pagination?.first,
                    last: action.payload?.pagination?.last,
                    offset: action.payload?.pagination?.offset,
                    hasNext: action.payload?.pagination?.hasNext,
                    hasPrevious: action.payload?.pagination?.hasPrevious,
                }
            };
        }
        case ReportActionTypes.GENERATE_REPORT_ERROR: {
            return {...state, generated_report_loading: false, error: action.payload};
        }
        case ReportActionTypes.GENERATE_DETAIL_REPORT_REQUEST: {
            return {...state, generated_report_detail_loading: true};
        }
        case ReportActionTypes.GENERATE_DETAIL_REPORT_SUCCESS: {
            return {
                ...state,
                generated_report_detail_loading: false,
                generated_report_detail: action.payload,
                pagination_detail: {
                    page: action.payload.pagination?.page,
                    size: action.payload.pagination?.size,
                    pages: action.payload.pagination?.pages,
                    elements: action.payload.pagination?.total,
                    first: action.payload.pagination?.first,
                    last: action.payload.pagination?.last,
                    offset: action.payload.pagination?.offset,
                    hasNext: action.payload.pagination?.hasNext,
                    hasPrevious: action.payload.pagination?.hasPrevious,
                }
            };
        }
        case ReportActionTypes.GENERATE_DETAIL_REPORT_ERROR: {
            return {...state, generated_report_detail_loading: false, error: action.payload};
        }
        case ReportActionTypes.GET_REPORTS_FEATURE_STATUS_REQUEST: {
            return {...state, report_feature_status_loading: true};
        }
        case ReportActionTypes.GET_REPORTS_FEATURE_STATUS_SUCCESS: {
            return {
                ...state,
                report_feature_status_loading: false,
                report_feature_status: action.payload
            };
        }
        case ReportActionTypes.GET_REPORTS_FEATURE_STATUS_ERROR: {
            return {...state, report_feature_status_loading: false, error: action.payload};
        }
        case ReportActionTypes.GET_REPORTS_FEATURE_ACTIVATION_REQUEST: {
            return {...state, report_feature_activation_loading: true};
        }
        case ReportActionTypes.GET_REPORTS_FEATURE_ACTIVATION_SUCCESS: {
            return {
                ...state,
                report_feature_activation_loading: false
            };
        }
        case ReportActionTypes.GET_REPORTS_FEATURE_ACTIVATION_ERROR: {
            return {...state, report_feature_activation_loading: false, error: action.payload};
        }
        case ReportActionTypes.GET_REPORTS_REQUEST: {
            return {...state, reports_loading: true};
        }
        case ReportActionTypes.GET_REPORTS_SUCCESS: {
            return {
                ...state,
                reports_loading: false,
                reports: action.payload.content,
                reports_pagination: {
                    page: action.payload?.pagination?.page,
                    size: action.payload?.pagination?.size,
                    pages: action.payload?.pagination?.pages,
                    elements: action.payload?.pagination?.total,
                    first: action.payload?.pagination?.first,
                    last: action.payload?.pagination?.last,
                    offset: action.payload?.pagination?.offset,
                    hasNext: action.payload?.pagination?.hasNext,
                    hasPrevious: action.payload?.pagination?.hasPrevious,
                }
            };
        }
        case ReportActionTypes.GET_REPORTS_ERROR: {
            return {...state, reports_loading: false, error: action.payload};
        }
        case ReportActionTypes.POST_REPORT_REQUEST: {
            return {...state, report_save_loading: true};
        }
        case ReportActionTypes.POST_REPORT_SUCCESS: {
            return {
                ...state,
                report_save_loading: false
            };
        }
        case ReportActionTypes.POST_REPORT_ERROR: {
            return {...state, report_save_loading: false, error: action.payload};
        }
        case ReportActionTypes.PUT_REPORT_REQUEST: {
            return {...state, report_save_loading: true};
        }
        case ReportActionTypes.PUT_REPORT_SUCCESS: {
            return {
                ...state,
                report_save_loading: false
            };
        }
        case ReportActionTypes.PUT_REPORT_ERROR: {
            return {...state, report_save_loading: false, error: action.payload};
        }
        case ReportActionTypes.DELETE_REPORT_REQUEST: {
            return {...state, report_delete_loading: true};
        }
        case ReportActionTypes.DELETE_REPORT_SUCCESS: {
            return {
                ...state,
                report_delete_loading: false
            };
        }
        case ReportActionTypes.DELETE_REPORT_ERROR: {
            return {...state, report_delete_loading: false, error: action.payload};
        }
        case ReportActionTypes.GET_REPORT_REQUEST: {
            return {...state, report_loading: true};
        }
        case ReportActionTypes.GET_REPORT_SUCCESS: {
            return {
                ...state,
                report_loading: false,
                report: action.payload
            };
        }
        case ReportActionTypes.GET_REPORT_ERROR: {
            return {...state, report_loading: false, error: action.payload};
        }
        case ReportActionTypes.POST_DASHBOARD_REQUEST: {
            return {...state, dashboard_save_loading: true};
        }
        case ReportActionTypes.POST_DASHBOARD_SUCCESS: {
            return {
                ...state,
                dashboard_save_loading: false
            };
        }
        case ReportActionTypes.POST_DASHBOARD_ERROR: {
            return {...state, dashboard_save_loading: false, error: action.payload};
        }
        case ReportActionTypes.GET_DASHBOARDS_REQUEST: {
            return {...state, dashboards_loading: true};
        }
        case ReportActionTypes.GET_DASHBOARDS_SUCCESS: {
            return {
                ...state,
                dashboards_loading: false,
                dashboards: action.payload.content,
                dashboards_pagination: {
                    page: action.payload?.pagination?.page,
                    size: action.payload?.pagination?.size,
                    pages: action.payload?.pagination?.pages,
                    elements: action.payload?.pagination?.total,
                    first: action.payload?.pagination?.first,
                    last: action.payload?.pagination?.last,
                    offset: action.payload?.pagination?.offset,
                    hasNext: action.payload?.pagination?.hasNext,
                    hasPrevious: action.payload?.pagination?.hasPrevious,
                }
            };
        }
        case ReportActionTypes.GET_DASHBOARDS_ERROR: {
            return {...state, dashboards_loading: false, error: action.payload};
        }
        case ReportActionTypes.GET_DASHBOARD_REQUEST: {
            return {...state, dashboard_loading: true};
        }
        case ReportActionTypes.GET_DASHBOARD_SUCCESS: {
            return {
                ...state,
                dashboard_loading: false,
                dashboard: action.payload
            };
        }
        case ReportActionTypes.GET_DASHBOARD_ERROR: {
            return {...state, dashboard_loading: false, error: action.payload};
        }
        case ReportActionTypes.PUT_DASHBOARD_REQUEST: {
            return {...state, dashboard_save_loading: true};
        }
        case ReportActionTypes.PUT_DASHBOARD_SUCCESS: {
            return {
                ...state,
                dashboard_save_loading: false
            };
        }
        case ReportActionTypes.PUT_DASHBOARD_ERROR: {
            return {...state, dashboard_save_loading: false, error: action.payload};
        }
        case ReportActionTypes.DELETE_DASHBOARD_REQUEST: {
            return {...state, dashboard_delete_loading: true};
        }
        case ReportActionTypes.DELETE_DASHBOARD_SUCCESS: {
            return {
                ...state,
                dashboard_delete_loading: false
            };
        }
        case ReportActionTypes.DELETE_DASHBOARD_ERROR: {
            return {...state, dashboard_delete_loading: false, error: action.payload};
        }
        default: {
            return state;
        }
    }
};

export {reducer as ReportReducer};
