import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Config from "../Common/Config";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ApplicationState } from "store";
import { dashboardGetRequest } from "store/report/action";

const Edit: React.FC = () => {
    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const dashboard_loading = useSelector((state: ApplicationState) => state.report.dashboard_loading);
    const dashboard = useSelector((state: ApplicationState) => state.report.dashboard);

    useEffect(() => {
        if(id) {
            dispatch(dashboardGetRequest(id))
        }
    }, [id])

    return (
        <Config dashboard={dashboard} />
    );
};
export default Edit;