import React, { useEffect, useState } from "react";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { useDispatch } from "react-redux";
import { Table } from "reactstrap";
import { InvoiceListGetRequest, invoiceUpdateRequest } from "store/invoices/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { isJson } from "utils";


const ApplicationInfo = ({ onCloseClick, record, metaData, data }: any) => {
	const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
	const [formValues, setFormValues] = useState<{ [key: string]: any }>({});
	const [datafields, setdatafields] = useState<any>([])
	const [newpaid_amount, setnewpaid_amount] = useState('')
	const [open, setOpen] = useState(false)
	const [filterObject, setFilterObject] =  useState<any>({});
	const model = "students"
	const onChange = (option: any, key: any) => {
		if (typeof option == 'object') setFormValues({ ...formValues, [key]: option.value })
		else setFormValues({ ...formValues, [key]: option })
	};

	const handlestatus = (paid: any, amount: any) => {
		let balance = amount - paid;
		if (balance > 0 && paid == 0) return 'unpaid'
		else if (balance > 0 && paid > 0) return 'partially_paid'
		else if (balance == 0) return 'paid';
		else if (balance < 0) return 'advanced_paid';
	}



	const valuesChanged = (invoiceDetails: any) => {
		let value: any = parseFloat(newpaid_amount);
		let newTotalpaid = data.paid_amount ? parseFloat(data.paid_amount) + parseFloat(value) : parseFloat(value);
		let balanceDue = parseFloat(data.invoice_amount) - newTotalpaid;
		let payment_status = handlestatus(newTotalpaid, data.invoice_amount)
		let newValues = { ...data, balanceDue: balanceDue, paid_amount: newTotalpaid, payment_status: payment_status }
		// setnewpaid_amount(value)
		const handleSuccess = () => {
			setOpen(false)
			onCloseClick()
			const message = () => toast(`Invoice updated Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
			message();
			dispatch(InvoiceListGetRequest(model));
		}
		const handleFailure = () => {

		}
		dispatch(invoiceUpdateRequest(data.id, newValues, handleSuccess, handleFailure));
	}

	const handleInputChange = (e: React.ChangeEvent<any>) => {
		const { name, value, type } = e.target;
		let fieldValue: any = value;
		if (type === "checkbox") {
			const target = e.target as HTMLInputElement;
			fieldValue = target.checked;
		}

		setFormValues({
			...formValues,
			[name]: fieldValue,
		});
	};


	useEffect(() => {
		if (metaData && metaData.length > 0) {
			let datafields = metaData.map((fieldName: any) => {
				let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) && JSON.parse(fieldName.valuesJson)
				return fields
			});
			let nameColumnIndex = false;
			datafields && datafields.length && datafields.map((datafield: any) => {
				if(datafield?.key === "paid_amount") nameColumnIndex = true
			});
			if (!nameColumnIndex) {
				datafields.push({
					key: 'paid_amount',
					label: 'Paid Amount',
					type: 'textfield',
					placeholder: 'Enter paid Amount'
				})
			}
			setdatafields(datafields)
			
		}
	}, [metaData]);

	return (
		<Table className="table table-borderless mb-0">
			{datafields && datafields.length && datafields.map((field: any) => {
				return <tbody>
					{field.key == "paid_amount" ? <>
						<tr className="border border-0 border-bottom">
							<td className="fw-semibold">
								{field.label}
							</td>
							<td>
								<div className="w-100">
									{data?.[field.key]}{ data?.[field.key] >= data?.['invoice_amount'] ? null :  <i className=" ri-add-fill fs-18 cursor-pointer" onClick={() => setOpen(true)} />}
								</div>
							</td>
						</tr>
						<div style={{minWidth: 200, position: 'relative'}}>
						{
							open ?
								<div className="search-icon">
									<FormBuilder
										placeholder={field.placeholder}
										onChange={(value: any) => setnewpaid_amount(value)}
										options={field.values && field.values.reverse() || []}
										actionButtons={true}
										onSaveClick={(e: any) => valuesChanged(data)}
										type={field.type}
										value={newpaid_amount}
										className="w-100 h-100 text-start"
								/></div> : null
						}
						</div></>
						:
						<tr className="border border-0 border-bottom">
							<td className="fw-semibold">
								{field.label}
							</td>
							<td className="fw-semibold" style={{overflowWrap:"anywhere"}}>
								{data?.[field.key]}
							</td>
						</tr>}
				</tbody>
			})}
		</Table>
	);
};

export default ApplicationInfo;



