import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const RestoreModal = ({ props, show, onCloseClick, record, onRestore }: any) => {
    return (
        <Modal id="myModal" isOpen={show} toggle={() => { onCloseClick(); }} centered>
            <ModalHeader className="modal-title fw-bold" id="myModalLabel" toggle={() => { onCloseClick(); }}>
                {props.t("properties.restore_property")}
            </ModalHeader>
            <ModalBody className='text-center p-5'>
                <div className="mt-1">
                    <i className="ri-refresh-line display-5 text-success"></i>
                    <p className="text-muted fs-15 mb-4">{props.t("properties.restore_property")} {record.name}.</p>
                    <div className="hstack gap-2 justify-content-center">
                        <Button className="btn btn-success" onClick={() => onRestore()}>
                            {props.t("properties.restore")}
                        </Button>
                        <Button color="danger" onClick={() => onCloseClick()} outline>
                            {props.t("properties.cancel")}
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    ) as unknown as JSX.Element;
};

export default RestoreModal;