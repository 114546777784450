const COLORS = [
    "#74d2e7",
    "#48a9c5",
    "#0085ad",
    "#8db9ca",
    "#4298b5",
    "#005670",
    "#00205b",
    "#009f4d",
    "#84bd00",
    "#efdf00",
    "#fe5000",
    "#e4002b",
    "#da1884",
    "#a51890",
    "#0077c8",
    "#008eaa",
    "#3369e7",
    "#8e43e7",
    "#b84592",
    "#ff4f81",
    "#ff6c5f",
    "#ffc168",
    "#2dde98",
    "#1cc7d0",
    "#003666",
    "#00aeff"
]

// Convert hex color to RGB (returns a tuple of [r, g, b] values)
const hexToRgb = (hex: string): [number, number, number] => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
};

// Convert RGB to hex color (returns a string in hex format)
const rgbToHex = (r: number, g: number, b: number): string =>
    `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;

// Generate a tinted color by blending the color with white
const tintColor = (hex: string, percentage: number): string => {
    const [r, g, b] = hexToRgb(hex);
    const tintAmount = (color: number, percentage: number) =>
        Math.round(color + (255 - color) * (percentage / 100));

    return rgbToHex(
        tintAmount(r, percentage),
        tintAmount(g, percentage),
        tintAmount(b, percentage)
    );
};

// Generate a shaded color by darkening the RGB values
const shadeColor = (hex: string, percentage: number): string => {
    const [r, g, b] = hexToRgb(hex);
    const shadeAmount = (color: number, percentage: number) =>
        Math.round(color * (1 - percentage / 100));

    return rgbToHex(
        shadeAmount(r, percentage),
        shadeAmount(g, percentage),
        shadeAmount(b, percentage)
    );
};
  
export {
    COLORS,
    tintColor,
    shadeColor
};