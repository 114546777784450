import { startOfToday, endOfToday, subDays, startOfWeek, endOfWeek, subWeeks, startOfMonth, endOfMonth, subMonths, startOfQuarter, endOfQuarter, subQuarters, startOfYear, endOfYear, subYears, addDays, addWeeks, addMonths, addQuarters, addYears } from "date-fns";

export const getDateRange = (value: string) => {
  const today = new Date();
  switch (value) {
    case "today":
      return [startOfToday(), endOfToday()];

    case "yesterday":
      return [subDays(startOfToday(), 1), subDays(endOfToday(), 1)];

    case "tomorrow":
      return [addDays(startOfToday(), 1), addDays(endOfToday(), 1)];

    case "last_7_days":
      return [subDays(startOfToday(), 6), endOfToday()];

    case "next_7_days":
      return [addDays(startOfToday(), 1), addDays(endOfToday(), 7)];

    case "last_30_days":
      return [subDays(startOfToday(), 29), endOfToday()];

    case "next_30_days":
      return [addDays(startOfToday(), 1), addDays(endOfToday(), 30)];

    case "this_week":
      return [startOfWeek(today), endOfWeek(today)];

    case "last_week":
      return [startOfWeek(subWeeks(today, 1)), endOfWeek(subWeeks(today, 1))];

    case "next_week":
      return [startOfWeek(addWeeks(today, 1)), endOfWeek(addWeeks(today, 1))];

    case "this_month":
      return [startOfMonth(today), endOfMonth(today)];

    case "last_month":
      return [startOfMonth(subMonths(today, 1)), endOfMonth(subMonths(today, 1))];

    case "next_month":
      return [startOfMonth(addMonths(today, 1)), endOfMonth(addMonths(today, 1))];

    case "this_quarter":
      return [startOfQuarter(today), endOfQuarter(today)];

    case "last_quarter":
      return [startOfQuarter(subQuarters(today, 1)), endOfQuarter(subQuarters(today, 1))];

    case "next_quarter":
      return [startOfQuarter(addQuarters(today, 1)), endOfQuarter(addQuarters(today, 1))];

    case "year_to_date":
      return [startOfYear(today), endOfToday()];

    case "this_year":
      return [startOfYear(today), endOfYear(today)];

    case "last_year":
      return [startOfYear(subYears(today, 1)), endOfYear(subYears(today, 1))];

    case "next_year":
      return [startOfYear(addYears(today, 1)), endOfYear(addYears(today, 1))];

    default:
      return [null, null];
  }
};
