import { useEffect, useState } from "react";
import {
    Col,
    Input,
    Label,
    Row
} from "reactstrap";
import Select from "react-select";
import { GroupGetByModelRequest } from "store/properties/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { ModelListGetRequest } from "store/auth/action";

const BasicInfo = ({ setEditInputs, EditInputs, activeArrowTab }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const modelList = useSelector((state: ApplicationState) => state.auth.models)
    const optionList = useSelector((state: ApplicationState) => state.properties.groups);
    const loading = useSelector((state: ApplicationState) => state.properties.loading);
    const [selectedObjectedType, SetSelectedObjectedType] = useState<any>({});
    const [options, setOptions] = useState([]);
    const [selectedGroup, setselectedGroup] = useState<any>({});

    const handleselected = (option: any, name: string,) => {
        switch (name) {
            case 'object-type': SetSelectedObjectedType(option)
                setEditInputs({ ...EditInputs, objectType: option.value })
                break
            case 'group': setselectedGroup(option)
                setEditInputs({ ...EditInputs, groupId: option.value })
                break
        }
    }
    const objectTypeOption = modelList && modelList.length ?
        modelList.map((item: any) => {
            return { label: item.charAt(0).toUpperCase() + item.slice(1), value: item }
        }) : []

    const onchange = (value: any) => {        
        const cleanStr = value.replace(/['"/]/g, "");
        setEditInputs({ ...EditInputs, label: cleanStr })
    }

    useEffect(() => {
        if (selectedObjectedType && selectedObjectedType.value) {
            const filterObject = {
                pageSize: 100
            }
            dispatch(GroupGetByModelRequest(selectedObjectedType.value, filterObject))
        }
    }, [selectedObjectedType.value])

    useEffect(() => {
        let options: any = optionList && optionList.length && optionList.map((item: any) => {
            return { label: item.groupTitle, value: item.id }
        })
        setOptions(options)
    }, [optionList])

    const renderField = (value: any, objectType: any) => {
        switch (objectType) {
            case "objectType": objectTypeOption.map((field: any) => {
                if (field.value == value) {
                    SetSelectedObjectedType(field)
                }
            })
                break;
            case "group": options && options.length && options.map((field: any) => {
                if (field.label == value) {
                    setselectedGroup(field)
                    setEditInputs({ ...EditInputs, groupId: field.value })
                }
            })
                break;
            default: return null;
        }
    }

    useEffect(() => {
        renderField(EditInputs.objectType, "objectType")
        dispatch(ModelListGetRequest())
    }, [])

    useEffect(() => {
        if (EditInputs.groupId) {
            options && options.length && options.map((field: any) => {
                if (field.value === EditInputs.groupId || field.label === EditInputs.groupId) {
                    setselectedGroup(field)
                    setEditInputs({ ...EditInputs, groupId: field.value })
                }
            })
        }
    }, [options, activeArrowTab, EditInputs.groupId])
    return (
        <div>
            <Row className="hstack gap-3 flex-column justify-content-center">
                <Col lg={12} className="d-flex gap-2 flex-column justify-content-center">
                    <div>
                        <Label className="fs-16 fw-medium">Module <span className="fw-bold text-danger">{" "}*</span></Label>
                        <Select
                            defaultOptions
                            placeholder={'Select property type'}
                            className="fs-16 fw-medium"
                            value={selectedObjectedType}
                            onChange={(option: any) => handleselected(option, 'object-type')}
                            options={objectTypeOption}
                            isDisabled={true}
                            name="choices-single-default"
                        ></Select>
                    </div>
                    <div>
                        <Label className="fs-16 fw-medium">Field Name <span className="fw-bold text-danger">{" "}*</span></Label>
                        <Input
                            type={"text"}
                            name={"label"}
                            className="fs-16 fw-medium"
                            value={EditInputs.label}
                            onChange={(e) => onchange(e.target.value)}
                        />
                    </div>

                    <div>
                        <Label className="fs-16 fw-medium">Section <span className="fw-bold text-danger">{" "}*</span></Label>
                        <Select
                            defaultOptions
                            placeholder={'Select Group'}
                            className="fs-16 fw-medium"
                            value={selectedGroup}
                            onChange={(option: any) => handleselected(option, 'group')}
                            options={options || []}
                            name="choices-single-default"
                        ></Select>
                        {selectedObjectedType && selectedObjectedType.value && !options.length && !loading ?
                            <span className="fw-bold text-danger fs-12">If you are not able to see options in dropdown, Please create sections for {selectedObjectedType.label}.</span>
                            : null}
                    </div>
                    <div></div>
                    <div>
                        <Label className="fs-16 fw-medium">Description</Label>
                        <div>
                            <Input
                                type={"text"}
                                className="form-controlt fs-14 fw-medium"
                                name="description"
                                value={EditInputs.description}
                                onChange={(e) => setEditInputs({ ...EditInputs, description: e.target.value })}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default BasicInfo;
