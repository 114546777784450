import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Row,
    UncontrolledAccordion,
} from 'reactstrap';
import { isJson } from 'utils';

interface Options {
    label: string;
    value: string;
}

interface Permission {
    name: string;
    options?: Options[];
    value: string; // or the appropriate type
}

interface Feature {
    description: string;
    editable: boolean;
    name: string;
    value: boolean; // or the appropriate type
    type: string;
    permissions: Permission[];
    options?: Options[];
}

interface SubItem {
    name: string;
    children: Feature[];
    permissions: any
}

interface MenuItem {
    name: string;
    children: SubItem[];
    permissions: any;
}

interface CreateInputs {
    role: string;
}

interface ReviewProps extends WithTranslation {
    createInputs: CreateInputs;
    accessData: MenuItem[];
}

const Review: React.FC<ReviewProps> = ({ createInputs, accessData, t }) => {
    const [opentab, setOpentab] = useState<string[]>(['CRM Objects', 'CRM Tools','Marketing', 'Sales', 'Reporting', 'Service', 'Automation', 'Settings Access', 'User & Account Access'])
    const renderOption = (options: Options[] | undefined, value: string) => {
        const selectedOption = options && options.find((option) => option.value === value);

        if (selectedOption) {
            let value = selectedOption.label == "ON" ? "" : selectedOption.label 
            return value;
        }
        return '';
    };

    useEffect(() => {
        accessData && accessData.length &&
            accessData.map((item: MenuItem, index: number) => (
                item.children.map((subItem: SubItem, key: number) => {
                    let name = subItem.name;
                    setOpentab(prevOpentab => [...prevOpentab, name]);
                })))
    }, []);

    const renderPermissions = (subItem: any, key: any) => {
        const shouldRenderAccordionItem =
            subItem.permissions &&
            subItem.permissions.some((permission: any) => (permission.value  && permission.value != 'false'));
        return shouldRenderAccordionItem && subItem.name ? (
            <div key={subItem.id}>
                <AccordionItem className="border border-1 border-secoundary">
                    <AccordionHeader
                        targetId={subItem.name}
                        className="border border-1 border-secoundary"
                    >
                        {subItem.name}
                    </AccordionHeader>
                    <AccordionBody
                        className="mt-2 mb-2"
                        accordionId={subItem.name}
                    >
                        {subItem.permissions && subItem.permissions.length ? (
                            subItem.permissions.map((permission: any, index: any) =>
                                permission.value  && permission.value != 'false' && permission.value != 'FALSE' ? (
                                    <div key={permission.id}>
                                        <span className="mt-2 mb-2 fw-bold">
                                            {permission.name}
                                        </span>
                                        <p className="mt-2 mb-2">
                                            {permission.permissions &&
                                                permission.permissions.length ? (
                                                permission.permissions.map(
                                                    (permission: any, index: any) => (
                                                        permission.options && permission.value && permission.value != 'false' && permission.value != 'FALSE' && permission.type == 'dropdown' ? (
                                                            <span key={permission.id}>
                                                                <span className="fw-semibold">
                                                                    {permission.name}:
                                                                </span>{' '}
                                                                &nbsp;
                                                                {permission.value && permission.value != 'false' && (
                                                                    <span>
                                                                        {renderOption(isJson(permission.options),
                                                                            permission.value
                                                                        )}
                                                                    </span>
                                                                )}
                                                                , &nbsp;&nbsp;&nbsp;
                                                            </span>
                                                        ) : (
                                                            <span
                                                                className="fw-semibold py-3"
                                                                key={permission.id}
                                                            >
                                                                {permission.value  && permission.value != 'false' ? (
                                                                    <span className="fw-semibold">
                                                                        {permission.name}
                                                                        <br />
                                                                    </span>
                                                                ) : null}
                                                            </span>
                                                        )
                                                    )
                                                )
                                            ) :
                                                <span className="fw-semibold">
                                                    {
                                                        typeof permission.value === 'string'  && permission.value != 'false' && permission.value != 'FALSE' ?
                                                            <span>
                                                                {renderOption(isJson(permission.options),
                                                                    permission.value
                                                                )}</span>
                                                            : null
                                                    }
                                                    <br />
                                                </span>
                                            }
                                        </p>
                                    </div>
                                ) : null
                            )
                        ) : null}
                    </AccordionBody>
                </AccordionItem>
            </div>
        ) : null;
    }
    return (
        <div className="hstack mt-0 mb-4">
        <Row className="p-2 w-100 hstack gap-3 justify-items-center">
          {/* <Col lg={12} className="vstack justify-content-between"> */}
          <div className="vstack gap -1 justify-content-center">
            <h4 className="">Access</h4>
            </div>
            <UncontrolledAccordion className="custom-accordion-border" toggle={() => { }} defaultOpen={opentab} stayOpen>
                {accessData && accessData.length ? (
                    accessData.map((item: any, index: number) => (
                        <React.Fragment key={item.id}>
                            <>
                                {
                                    item.children && item.children.length ? item.children.map((subItem: SubItem, key: number) => {
                                        return <React.Fragment>
                                            <>{renderPermissions(subItem, key)}</>
                                        </React.Fragment>
                                    }) : (
                                    <React.Fragment>
                                        {renderPermissions(item, index)}
                                    </React.Fragment>)
                                }
                            </>
                        </React.Fragment>
                    ))
                ) : null}
            </UncontrolledAccordion>
            </Row>
        </div>
    );
};

export default withTranslation()(Review);
