export interface Note {
  createdAt: string,
  createdBy: {
    email: string,
    id: string,
    name: string,
    phoneNumber: string,
  },
  deletedAt: string,
  detailedNote: string,
  id: string,
  parentNote: string,
  replies: {
    createdAt: string,
    createdBy: {
      email: string,
      id: string,
      name: string,
      phoneNumber: string,
    },
    deletedAt: string,
    detailedNote: string,
    id: string,
  },
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
  }

export enum NoteActionTypes {
    POST_NOTE_REQUEST = "@@/POST_NOTE_REQUEST",
    POST_NOTE_SUCCESS = "@@/POST_NOTE_SUCCESS",
    POST_NOTE_ERROR = "@@/POST_NOTE_ERROR",
    GET_NOTE_REQUEST = "@@auth/GET_NOTE_REQUEST",
    GET_NOTE_SUCCESS = "@@auth/GET_NOTE_SUCCESS",
    GET_NOTE_ERROR = "@@auth/GET_NOTE_ERROR",
    GET_NOTES_REQUEST = "@@auth/GET_NOTES_REQUEST",
    GET_NOTES_SUCCESS = "@@auth/GET_NOTES_SUCCESS",
    GET_NOTES_ERROR = "@@auth/GET_NOTES_ERROR",
    DELETE_NOTE_REQUEST = "@@auth/DELETE_NOTE_REQUEST",
    DELETE_NOTE_SUCCESS = "@@auth/DELETE_NOTE_SUCCESS",
    DELETE_NOTE_ERROR = "@@auth/DELETE_NOTE_ERROR",
}

export interface NoteState {
    // readonly loading: boolean;
    readonly notes: Note[] | null;
    readonly note: Note  | null;
    readonly pagination: Pagination | null;
    // readonly errors?: string;
    // readonly content?:any;
    // readonly sort: any;
    // readonly sortBy: any;
    // readonly sortColumn: string;
    // readonly pageable: any;
    // readonly page: any;
    // readonly size: number;
    // readonly totalPages: number;
    // readonly totalElements: number;
    // readonly number: number;
    // readonly numberOfElements: number;
    // readonly first: boolean;
    // readonly last: boolean;
    // readonly empty: boolean;
    // readonly list: []
}
