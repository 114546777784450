import { v4 as uuidv4 } from 'uuid';
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

interface CreateProcessFlowDrawerProps {
    isOpen: boolean;
    toggle: (value: boolean) => void;
    processFlows: any[];
    onSubmit: (value: any) => void;
    t: (key: string) => string;
}

const CreateProcessFlowDrawer: React.FC<CreateProcessFlowDrawerProps> = ({
    isOpen,
    toggle,
    processFlows,
    onSubmit,
    t
}) => {
    // State management for the form
    const [processFlowName, setProcessFlowName] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    // Handle changes to the processFlow name input field
    const handleProcessFlowNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value;
        setProcessFlowName(name);

        // Check for existing processFlows with the same value
        const processFlowExists = processFlows.some((processFlow) => processFlow.label?.toLowerCase() === name?.toLowerCase());
        setErrorMessage(processFlowExists ? t('properties.process_flow.common.process_flow.create.name_error') : "");
    };

    // Handle adding a new processFlow
    const handleAddProcessFlow = () => {
        const newProcessFlow = {
            label: processFlowName,
            value: uuidv4(),
            in_forms: true
        };
        onSubmit(newProcessFlow)
        toggle(false);
        resetFormValues();
    };

    // Reset form values after adding or closing the drawer
    const resetFormValues = () => {
        setProcessFlowName('');
        setErrorMessage('');
    };

    return (
        <Offcanvas
            direction="end"
            isOpen={isOpen}
            id="processFlowCreate"
            backdrop={false}
            toggle={() => toggle(false)}
        >
            <ToastContainer />
            <OffcanvasHeader
                className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary"
                id="processFlowCreateHeader"
                toggle={() => toggle(false)}
            >
                <span className="m-0 me-2 text-white">
                    {t('properties.process_flow.common.process_flow.create.header')}
                </span>
            </OffcanvasHeader>
            <OffcanvasBody className="bg-light" id="processFlowCreateBody">
                <div className="d-flex flex-column gap-2 p-3">
                    <div className="form-group mb-3">
                        <Label className="form-label fw-bold fs-6">
                            {t('properties.process_flow.common.process_flow.create.process_flow_label')}:
                        </Label>
                        <Input
                            type="text"
                            placeholder={t('properties.process_flow.common.process_flow.create.process_flow_placeholder')}
                            onChange={handleProcessFlowNameChange}
                            value={processFlowName}
                        />
                        {errorMessage && <p className="form-text text-danger">{errorMessage}</p>}
                    </div>
                </div>
            </OffcanvasBody>
            <div className="offcanvas-footer bg-info bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                <Link
                    to="#"
                    className="text-danger fw-bold w-auto"
                    onClick={() => toggle(false)}
                >
                    {t('properties.process_flow.common.process_flow.create.btn_cancel')}
                </Link>
                <Button
                    color="primary"
                    className="btn btn-primary w-auto"
                    disabled={!processFlowName || !!errorMessage}
                    onClick={handleAddProcessFlow}
                >
                    {t('properties.process_flow.common.process_flow.create.btn_add')}
                </Button>
            </div>
        </Offcanvas>
    );
};

export default withTranslation()(CreateProcessFlowDrawer);
