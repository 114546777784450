import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';


const General = ({model, ...props}: any) => {
    return (
        <React.Fragment>
            <Card>
                <CardBody className='border border-dashed border-end-0 border-start-0 vstack gap-2'>
                    <div className='d-flex align-items-center justify-content-center' style={{height: '300px'}}>
                        <h4 className='text-muted'>{props.t('properties.process_flow.general.heading_line')}</h4>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(General);