import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Listing from 'Components/Common/Views/Listing';
import { sourceCreateRequest, sourceDelete, sourceGetRequest, sourceListGetRequest, sourcesMetadataGetRequest } from 'store/sources/action';

const Source = (props: any) => {
    const { viewId, isCreateModal, setCreateOpen } = props;    
    return (
        <Listing 
            viewId={viewId}
            isCreateModal={isCreateModal}
            setCreateOpen={setCreateOpen}
            getListAction={sourceListGetRequest}
            deleteAction={sourceDelete}
            detailsGetAction={sourceGetRequest}
            metaDatagetRequest={sourcesMetadataGetRequest}
            createAction={sourceCreateRequest}
            model={"sources"}
            reducerState={"sources"}
            translater={"source"}
        />
    );
};
export default withTranslation()(Source);


