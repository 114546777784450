import React from "react";
import { withTranslation } from "react-i18next";
import GroupBy from "./common/GroupBy";
import Value from "./common/Value";
import Fields from "./common/Fields";

interface PieConfigOptionsProps {
    groupBy: any;
    value: any;
    fields: any;
    onModifyGroupBy: (property: any, dataSource: any) => void;
    onModifyValue: (property: any, dataSource: any) => void;
    moveFields: (dragIndex: number, hoverIndex: number, isDragging: boolean) => void
    onFieldUpdated: (newfilter: any) => void
    onFieldDelete: (deleteColumn: any) => void
    t: (key: string) => string;
}

const PieConfigOptions: React.FC<PieConfigOptionsProps> = ({
    t,
    groupBy,
    value,
    fields,
    onModifyGroupBy,
    onModifyValue,
    moveFields,
    onFieldUpdated,
    onFieldDelete
}) => {
    return (
        <div className="d-flex flex-column gap-4">
            <GroupBy
                groupBy={groupBy}
                onModifyGroupBy={onModifyGroupBy}
            />
            <Value
                value={value}
                onModifyValue={onModifyValue}
            />
            <Fields
                fields={fields}
                moveFields={moveFields}
                onFieldUpdated={onFieldUpdated}
                onFieldDelete={onFieldDelete}
            />
        </div>
    )
};

export default withTranslation()(PieConfigOptions);
