import React, { useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ViewsTab from 'Components/Common/Views/ViewsTab';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { Action } from 'redux';
import Course from './Courses';
import { coursesMetadataGetRequest } from 'store/course/action';

const Courses = (props: any) => {   
    const { isSubAgent } = props;  
    const metaData = useSelector((state: ApplicationState) => state.universities.metaDataFields);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();

    useEffect(() => {
        const filters = { pageSize: 500 }
        dispatch(coursesMetadataGetRequest("courses", filters));
    }, [])
    document.title="DTS | Zilter";
    return (
        <React.Fragment>                         
            <Row>
                <ViewsTab 
                    component={Course}
                    metadata={metaData}
                    model={"courses"}
                    title={props.t("courses.courses")} 
                    child={true}
                    isSubAgent={isSubAgent}
                />
            </Row>
        </React.Fragment>
    );
};

export default withTranslation()(Courses);