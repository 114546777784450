import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Col, Container } from 'reactstrap';
import UserDetailsModal from './UserDetailsModal';

interface UserProps extends WithTranslation { }

const User: React.FC<UserProps> = ({ t }: UserProps) => {
    document.title="Users | Zilter";
    const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
    const [userInputs, setUserInputs] = useState<any>({
        first_name: "vikranti",
        last_name: "Jadhav",
        main_phone: "22555222",        
        alt_phone_number: "22555222",
        job_title: "XYZ",
        email: "asas@gmail.com",
        status: "Invite Accepted"
    });
    return (
        <React.Fragment>
            <UserDetailsModal  show={isOpenEdit} onCloseClick={() => setIsOpenEdit(false)} userInputs={userInputs} setUserInputs={setUserInputs}/>
            <div className="page-content hstack gap-2">
                <Container className="hstack gap-2" fluid>
                    <Button color="primary" className="w-auto" onClick={() => setIsOpenEdit(true)}>
                        User Edit
                    </Button>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(User);
