import React from "react";
import { Button, Modal, ModalBody, Spinner } from "reactstrap";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";

interface DeleteModelProps {
    isOpen: boolean;
    report: any;
    toggle: (isClosed: any) => void;
    onDelete: () => void;
    t: (key: string) => string;
}

const DeleteModal = ({ isOpen, toggle, report , onDelete, t}: DeleteModelProps) => {
    const loading = useSelector((state: ApplicationState) => state.report.dashboard_delete_loading);
    return (
        <Modal backdrop="static" isOpen={isOpen} toggle={loading ? toggle : () => {}} centered={true}>
            <ModalBody className='text-center p-5'>
                <div className="mt-1">     
                    <i className="ri-delete-bin-5-line display-5 text-danger"></i>                                  
                    <h4 className="mb-2 mt-2">{report?.name}</h4>
                    <p className="text-muted fs-15 mb-4">{t('properties.process_flow.common.status.delete.helper_text_1')} {report?.name}. {t('properties.process_flow.common.status.delete.helper_text_2')}</p>
                    <div className="hstack gap-2 justify-content-center">
                        {
                            loading ? 
                                <Button color="danger" className="btn-label btn-load" disabled>
                                    <i className="ri-delete-bin-line label-icon align-middle fs-16 me-2"></i>
                                    <span className="d-flex align-items-center">
                                        <span className="flex-grow-1 me-2">
                                            Deleting...
                                        </span>
                                        <Spinner size="sm" className="flex-shrink-0" role="status"> Deleting... </Spinner>
                                    </span>
                                </Button> : <Button
                                    onClick={() => onDelete()}
                                    color="danger"
                                    className="btn-label">
                                        <i className="ri-delete-bin-line label-icon align-middle fs-16 me-2"></i>
                                        Delete
                                </Button>
                        }
                        <Button color="danger" onClick={() => toggle(false)} outline disabled={loading}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
};

export default withTranslation()(DeleteModal);