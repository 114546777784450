import BreadCrumb from 'Components/Common/BreadCrumb';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Button,
    Col,
    Container,
    Nav,
    NavItem,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Card,
} from 'reactstrap';
import avtarImage2 from '../../assets/images/users/avatar-1.jpg';
import avtarImage3 from '../../assets/images/users/user-dummy-img.jpg';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import FormBuilder from 'Components/Common/FormBuilder/FormBuilder';
import ConnectedAccounts from './ConnectedAccounts';
import Profile from './Profile';

const Preferences = ({setUserInputs, userInputs}: any) => {

    const [activeArrowTab, setactiveArrowTab] = useState<number>(1);
    const [passedarrowSteps, setPassedarrowSteps] = useState<number[]>([1]);
    const toggleArrowTab = (tab: number) => {
        if (activeArrowTab !== tab) {
            const modifiedSteps = [...passedarrowSteps, tab];
            if (tab >= 1 && tab <= 3) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    };

    const CreateRole = () => {
        // Implement your logic for creating a role
    };

    return (
        <React.Fragment>
            <div className="step-arrow-nav mt-4 mb-4">
                <Row className="w-100 hstack gap-1 justify-content-center">
                    <Col lg={3} md={3} className="vstack justify-content-between">
                        <Nav
                            pills className="flex-column" id="v-pills-tab"
                        >
                            <NavItem>
                                <NavLink
                                    href="#"
                                    id="basic-info"
                                    onClick={() => { toggleArrowTab(1); }}
                                    className={classnames({
                                        active: activeArrowTab === 1
                                    })}
                                >
                                    Profile
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="#"
                                    id="field-type"
                                    onClick={() => { toggleArrowTab(2); }}
                                    className={classnames({
                                        active: activeArrowTab === 2
                                    })}
                                >
                                    Connected accounts
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                    <Col lg={8} md={8}  className="justify-content-between">
                        <TabContent activeTab={activeArrowTab}>
                            <TabPane tabId={1}>
                                <Card>
                                    <Row className="justify-content-start">
                                        <Col lg={10}>
                                            <Profile setUserInputs={setUserInputs} userInputs={userInputs} />
                                        </Col>
                                    </Row>
                                </Card>
                            </TabPane>
                            <TabPane  tabId={2}>
                                <Card>
                                    <Row className="justify-content-start">
                                        <Col lg={10}>
                                            <ConnectedAccounts />
                                        </Col>
                                    </Row>
                                </Card>
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Preferences);