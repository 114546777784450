import React from 'react'
import { Button } from 'reactstrap';
import DashboardCharts from './DashboardCharts';

interface FProps {
    percentage: number
    file: File | null
}
const Uploading: React.FC<FProps> = ({percentage, file}) => {
    return (
        <div className='m-4'>
          <div className="flex-shrink-0">
            <DashboardCharts
              seriesData={percentage}
              dataColors={'["--vz-success"]'}
            />
            <div className='title text-center'>Uploading </div>
            <div className='text-nowrap text-truncate text-nowrap-ellipsis'>
            {file?.name}
            </div>
          </div>            
        </div>
    )
}

export default Uploading
