import {  Button, Input, Label, Modal, ModalBody,  ModalHeader } from "reactstrap";

const AddView = ({ props, show, onCloseClick, onUpdate, viewname, setViewname, record}: any) => {
    return (
        <Modal backdrop="static" isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={onCloseClick}>
                <span className='text-light'>{props.t("views.update_view")}</span>
            </ModalHeader>
            <ModalBody className='text-center p-5'>
                <div className="mt-1">     
                    <div  className="mb-3 text-left">
                        <Label>
                            View Name
                        </Label>
                        <Input type={"text"} className="form-control w-100" placeholder={"Enter View name"} value={viewname} onChange={(e: any) => setViewname(e.target.value)}/>                      
                    </div>
                    <div className="hstack gap-2 justify-content-center">                          
                        <Button
                            onClick={() => onUpdate()}
                            disabled={!viewname}
                            color="primary"
                            className="btn-soft-primary">
                                {props.t("properties.update")}
                        </Button>
                        <Button color="danger" onClick={() => onCloseClick()} outline>
                            {props.t("properties.cancel")}
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    ) as unknown as JSX.Element;
};

export default AddView;