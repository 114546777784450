import produce from 'immer';
import { Reducer } from "redux";
import { CompanyActionTypes, CompanyState } from "./types";


export const initialState: CompanyState =
{
    metaDataFields: [],
    details: {},
    pagination: null,
    list: [],
    banks: [],
    loading: false,
    metaLoading: false,
    dataLoading: false
}
const reducer: Reducer<CompanyState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case CompanyActionTypes.GET_COMPANYMETADATA_REQUEST: {
            return { ...state, metaLoading: true,  metaDataFields: []};
        }
        case CompanyActionTypes.GET_COMPANYMETADATA_SUCCESS: {
            return {
                ...state,
                metaLoading: false,
                metaDataFields: action.payload.content
            };
        }
        case CompanyActionTypes.GET_COMPANYMETADATA_ERROR: {
            return { ...state, metaLoading: false, errors: action.payload,  metaDataFields: []};
        }
        case CompanyActionTypes.GET_COMPANY_REQUEST: {
            return { ...state, loading: true };
        }
        case CompanyActionTypes.GET_COMPANY_SUCCESS: {
            return {
                ...state,
                loading: false,
                details: action.payload
            };
        }
        case CompanyActionTypes.GET_COMPANY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case CompanyActionTypes.POST_COMPANY_REQUEST: {
            return { ...state, loading: true };
        }
        case CompanyActionTypes.POST_COMPANY_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case CompanyActionTypes.POST_COMPANY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case CompanyActionTypes.GET_COMPANIES_REQUEST: {
            return { ...state, dataLoading: true };
        }
        case CompanyActionTypes.GET_COMPANIES_SUCCESS: {
            return {
                ...state,
                dataLoading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case CompanyActionTypes.GET_COMPANIES_ERROR: {
            return { ...state, dataLoading: false, errors: action.payload };
        }
        case CompanyActionTypes.PUT_COMPANY_REQUEST: {
            return { ...state, loading: true };
        }
        case CompanyActionTypes.PUT_COMPANY_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case CompanyActionTypes.PUT_COMPANY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case CompanyActionTypes.DELETE_COMPANY_REQUEST: {
            return { ...state, loading: true };
        }
        case CompanyActionTypes.DELETE_COMPANY_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case CompanyActionTypes.DELETE_COMPANY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        } 
        case CompanyActionTypes.GET_COMPANY_BANKS_REQUEST: {
            return { ...state, loading: true };
        }
        case CompanyActionTypes.GET_COMPANY_BANKS_SUCCESS: {
            return {
                ...state,
                loading: false,
                banks: action.payload.content
            };
        }
        case CompanyActionTypes.GET_COMPANY_BANKS_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }   
        default: {
            return state;
        }
    }
};

export { reducer as CompanyReducer };
