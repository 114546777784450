import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Button } from 'reactstrap';

interface BreadCrumbProps {
    title: string;
    pageTitle : string;
    pageTitleLink?: string;
    icon?: string;
}

const BreadCrumb = ({ title, pageTitle, icon, pageTitleLink } : BreadCrumbProps) => {
    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="page-title-box d-flex align-items-center justify-content-start gap-2">
                        <h4 className="mb-sm-0 brand-dark-color d-flex gap-2 align-items-center"><Button size='sm' color="primary" className="btn-icon"> <i className={icon || "ri-team-fill"} /> </Button></h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to={pageTitleLink ? pageTitleLink : "#"}>{pageTitle}</Link></li>
                                <li className="breadcrumb-item active">{title}</li>
                            </ol>
                        </div>

                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;