import { Reducer } from "redux";
import { ImportActionTypes, ImportState } from "./types";


export const initialState: ImportState =
{
    importList: [],
    loading: false,
    pagination: null
}
const reducer: Reducer<ImportState> = (state: any = initialState, action: any) => {
    switch (action.type) {       
        case ImportActionTypes.PUT_IMPORT_REQUEST: {
            return { ...state, loading: true };
        }
        case ImportActionTypes.PUT_IMPORT_SUCCESS: {
            return {
                ...state,
                loading: false,
                // documentlist: action.payload.content
            };
        }
        case ImportActionTypes.PUT_IMPORT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case ImportActionTypes.GET_IMPORT_HISTORY_REQUEST: {
            return { ...state, loading: true };
        }
        case ImportActionTypes.GET_IMPORT_HISTORY_SUCCESS: {
            return {
                ...state,
                loading: false,
                importList: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case ImportActionTypes.GET_IMPORT_HISTORY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        default: {
            return state;
        }
    }
};

export { reducer as ImportReducer };