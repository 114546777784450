import React, { useEffect, useRef, TextareaHTMLAttributes } from 'react';

interface AutoGrowTextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
}

const AutoGrowTextarea: React.FC<AutoGrowTextareaProps> = ({
  className = '',
  ...props
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const handleInput = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto'; // Reset height
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set new height
      }
    };

    const textarea = textareaRef.current;
    if (textarea) {
      textarea.addEventListener('input', handleInput);
      // Initial call to set height based on default value
      handleInput();

      // Cleanup on component unmount
      return () => {
        textarea.removeEventListener('input', handleInput);
      };
    }
  }, []);

  return (
    <textarea
      ref={textareaRef}
      className={`form-control ${className}`}
      rows={1} // Minimum row count
      {...props} // Spread the remaining props to the textarea
    />
  );
};

export default AutoGrowTextarea;