import FormBuilder from 'Components/Common/FormBuilder/FormBuilder'
import React, { useState, useRef, useEffect } from 'react'
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom'
import { Card, Col, Label, Modal, Row, Tooltip } from 'reactstrap'

interface FProps {
    setselectedFiles: (event: any) => void;
    setDocumentType: (event: any) => void;
    selectedFiles: any;
    documentType: any;
    SubmitUpload: (str: boolean) => void;
    model: any;
}
const New: React.FC<FProps> = ({ setselectedFiles, setDocumentType, selectedFiles, SubmitUpload, documentType, model}) => {
    const [modal_small, setmodal_small] = useState<boolean>(false);
    const [isDisabled, setDisabled] = useState<boolean>(true);
    const [fileList, setFileList] = useState<any>([]);
    const formatBytes = (bytes: any, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const handleAcceptedFiles = (files: File[]) => {
        files.map((file: any) => {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        });
        setselectedFiles(files);
        const updatedFiles = files.map((file: File) => {
        const fileExtension = file.name.split('.').pop();
        return {
          ...file,
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
          fileType: file.type, // Use a different property name
          extension: fileExtension
        }});
        setFileList(updatedFiles);
      };
      
    const handleDisable = () => {
        const allowedFileTypes = ['png', 'jpg', 'jpeg', 'tiff', 'pdf' ];
        const totalSize = selectedFiles.reduce((acc: any, file: { size: any }) => acc + file.size, 0);
        const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
        const invalidFileType = fileList.some((file: any) => !allowedFileTypes.includes(file?.extension))
        return !selectedFiles.length || !documentType || totalSize >= maxSize || invalidFileType;
    };

    useEffect(() => {
        setDisabled(handleDisable())
    }, [selectedFiles, documentType])

    const tog_small = () => setmodal_small(!modal_small);

    let rest = {
        validate:{
            required: true
        }
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleAcceptedFiles,
        maxSize: 10 * 1024 * 1024, // 10 MB
    });
    return (
        <div>
                {selectedFiles.some((f: { size: number }) => f.size >= (10 * 1024 * 1024)) ? (
                <Modal
                    size="sm"
                    isOpen={modal_small}
                    toggle={tog_small}
                    className="modal-top"
                >
                    <Label className="text-warning fs-16 fw-medium">File size is greater than 500 KB</Label>
                </Modal>
            ) : null}
             <div className="mt-3">
                <div {...getRootProps()} className="dropzone dz-clickable cursor-pointer">
                    <div className="dz-message needsclick pt-4">
                        <div className="mb-3">
                            <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                        </div>
                        <h4>
                            {isDragActive ? 'Drop the file here ...' : 'Drop files here or click to upload.'}
                        </h4>
                    </div>
                    <input {...getInputProps()} />
                </div>
            </div>
            <div  className='mt-2'>       
                <FormBuilder rest={rest} label={"Document Type"} type={'textfield'} onChange={(value: any) => {
                    setDocumentType(value)
                }} />
            </div>     
            <div className="list-unstyled mb-0" id="file-previews">
                {selectedFiles.map((f: any, i: any) => {
                    return (
                        <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-primary dz-complete"
                            key={i + "-file"}
                        >
                            <div className="p-2">
                                <Row className="me-1 text-nowrap text-truncate text-nowrap-ellipsis text-muted align-items-center">
                                    <Col className="col-auto">
                                        <img
                                        
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="text-nowrap text-truncate text-nowrap-ellipsis avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                        />
                                    </Col>
                                    <Col>
                                        <Link
                                            to="#"
                                            className="text-decoration-none text-ellipsis text-muted font-weight-bold"
                                        >
                                            {f.name}
                                        </Link>
                                        <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    );
                })}
            </div>
            {selectedFiles.some((f: { size: number }) => f.size >= (10 * 1024 * 1024)) ? (
            <div className='justify-items-center'>
                        {/* <Row className="align-items-center">
                                   <Col className="col-auto"> */}
                                   <Label className='fs-15 fw-medium text-danger'>File size exceeds 10 Mb</Label>
                                        {/* </Col> 
                                        </Row> */}
                        </div>
            ) : null}
            <div className='d-flex flex-column fs-15 fw-medium mt-1 mb-0'>
                <Label className=''>File size accepted {`<`} 10 Mb</Label>
                <Label className=''>File formats accepted: JPEG, PNG, GIF</Label>
            </div>
            <div className="d-flex align-items-start gap-3 mt-4">
                {
                    model == "students" ?
                <div className='hstack gap-1'>
                    <button
                        onClick={() => {
                            SubmitUpload(true)
                        }}
                        disabled={isDisabled}
                        type="button"
                        className="btn btn-primary btn-label right ms-auto nexttab"
                        data-nexttab="pills-finish-tab"
                    >
                        <i className="ri-save-line label-icon align-middle fs-16 ms-2"></i>
                        Submit & Magic Fill
                    </button>
                    <div>
                        <span id="TooltipTop" title="Magic Fill: Automatically fills in the form with extracted data. Supported File type: PNG, JPEG, TIFF and PDF">
                            <i className="ri-information-line icon-md icon-dark cursor-pointer" />
                        </span>
                    </div>
                </div>: null}
                <button
                    onClick={() => {
                        SubmitUpload(false)
                    }}
                    disabled={!selectedFiles.length || documentType === "" ||  documentType === null ||  documentType === undefined}
                    type="button"
                    className="btn btn-primary btn-label right ms-auto nexttab"
                    data-nexttab="pills-finish-tab"
                >
                    <i className="ri-save-line label-icon align-middle fs-16 ms-2"></i>
                    Submit
                </button>
            </div>
        </div> 
    )
}

export default New
