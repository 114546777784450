const data = [
    // Your JSON data here
];

// Function to filter permissions with values that are true, "true", "ON", or any non-null value
const filterPermissionsWithTrueValue = (permissions: any) => {    
    return permissions.filter((permission: any) => {
        const value = permission.value;
        return value !== null && value !== false && value !== "false";
    });
}

// Function to traverse through nested children and extract permission items
const traverseAndExtractPermissions = (node: any) => {
    let permissionItems: any = [];
    // Check if the node has permissions
    if (node.permissions && node.permissions.length > 0) {
        const filteredPermissions = filterPermissionsWithTrueValue(node.permissions);
        // Create permission items
        filteredPermissions.forEach((permission: any) => {
            const permissionItem = {
                permissionId: permission.id,
                value: permission.value,
            };
            permissionItems.push(permissionItem);
            if(permission.permissions && permission.permissions.length) {  
                const filteredPermissions = filterPermissionsWithTrueValue(permission.permissions);
                filteredPermissions.forEach((permission: any) => {
                    const permissionItem = {
                        permissionId: permission.id,
                        value: permission.value,
                    };
                    permissionItems.push(permissionItem);
                });
            }
        });
    }

    // Recursively traverse children
    if (node.children && node.children.length > 0) {
        node.children.forEach((child: any) => {
            const childPermissionItems = traverseAndExtractPermissions(child);
            permissionItems = permissionItems.concat(childPermissionItems);
        });
    }

    return permissionItems;
}

// Array to store the permission items


// Traverse the data and extract permission items
const traverse = (data: any) => {
    let permissionItems: any = [];
    let categoryPermissionItems: any = []
    data && data.length && data.forEach((category: any) => {        
         categoryPermissionItems = traverseAndExtractPermissions(category);
        permissionItems = permissionItems.concat(categoryPermissionItems);
    });
    return permissionItems
}
export default traverse;