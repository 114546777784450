import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import TableComponent from './TableComponent';
import { importListGetRequest } from 'store/import/action';
import { useDispatch, useSelector } from 'react-redux';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { ApplicationState } from 'store';
import { isJson } from 'utils';
import { startCase} from 'lodash';
import { Container } from 'reactstrap';
import moment from 'moment';
import { documentDownload } from 'store/documents/action';
import FeatherIcon from "feather-icons-react";


const RecentImports = (props: any) => {   
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const importlist = useSelector((state: ApplicationState) => state.import.importList);
    const pagination = useSelector((state: ApplicationState) => state.import.pagination);
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])

    useEffect(() => {
        dispatch(importListGetRequest());
    }, [])

   
    useEffect(() => {
        const parsedData: any = importlist && importlist.length ? importlist.map((item: any) => {
            const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson): {};
            let values = { ...valuesJson, ...item,  id: item.id , createdAt: item.createdAt, paid_amount: valuesJson.paid_amount}
            return values
        }) : []
        let list = parsedData && parsedData.length && parsedData;
        setData(list)
    }, [importlist])

    const onClickFileDownload = (documentKey: any, key: any) => {
        const handleSuccess = async (body: any) => {
            try {
                // Create a Blob from the response data
                const fileBlob = await body.blob();
    
                // Create a temporary URL for the Blob
                const url = window.URL.createObjectURL(fileBlob);
    
                // Create a temporary <a> element to trigger the download
                const tempLink = document.createElement("a");
                tempLink.href = url;
                tempLink.setAttribute("download", key); // Set the desired filename for the downloaded file
    
                // Append the <a> element to the body and click it to trigger the download
                document.body.appendChild(tempLink);
                tempLink.click();
    
                // Clean up the temporary elements and URL
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("Error downloading file:", error);
            }
        }
        const handleFailure = () => {
    
        }
        // const encodedString = encodeURIComponent(documentKey).split('.')[0]+"."+encodeURIComponent(documentKey).split('.')[1];
        dispatch(documentDownload(documentKey, handleSuccess, handleFailure))
    }

    useEffect(() => {
        if(importlist && importlist.length) {
            let fields = importlist[0];  
            const keys = Object.keys(fields)
            const columns: any = []
            if(keys && keys.length) {
                keys.map((field,index) => { 
                    if(field === 'documentName' || field ===  'createdAt') {
                        let column = {
                                Header: field === 'documentName'? 'File Name'  : startCase(field),
                                accessor: field,
                                filterable: true,
                                disableFilters: true,
                                isSortable: false,
                                show: true,
                                Cell: (cellProps: any) => { 
                                   if(field ===  'createdAt') {
                                        return  <div className="d-flex align-items-center justify-content-between">                                
                                                <div className="hstack flex-grow-1  gap-2">
                                                    <div className="flex-shrink-0 text-decoration-none hstack flex-grow-1 gap-2" >                                  
                                                    {cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll') :
                                            ''}
                                                    </div>
                                                </div>
                                        </div>  }
                                    if(field === 'documentName' ) {
                                        return <div className='hstack gap-2'>
                                                       <span className='text-primary cursor-pointer' onClick={() => onClickFileDownload(cellProps.row.original?.['documentKey'], `${field}_${cellProps.row.original?.id}`)}> <FeatherIcon icon="download" className="icon-xs text-primary cursor-pointer" />
                                                       {" "} {cellProps.row.original?.[field]}</span>
                                                    </div>
                                    }
                                    else return <div className="d-flex align-items-center justify-content-between">                                
                                                <div className="hstack flex-grow-1  gap-2">
                                                    <div className="flex-shrink-0 text-decoration-none hstack flex-grow-1 gap-2" >                                  
                                                        {cellProps.row.original[field]}
                                                    </div>
                                                </div>
                                        </div>                
                                }
                            }
                        columns.push(column)
                    }
                })
                setColumns(columns);
            } 
            
            // if (columns && columns.length > 0) {
            //     columns.push({
            //         Header: "Imported Date",
            //         accessor: "createdAt",
            //         id: "createdAt",
            //         filterable: true,
            //         isSortable: false,
            //         disableFilters: true,
            //         show: true,
            //         Cell: (cellProps: any) => {
            //             return <div className="d-flex align-items-center justify-content-between">
            //                 {cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll') :
            //                     ''}
            //             </div>
            //         }
            //     })
            // }
        }
    }, [importlist])
    
    return (
        <React.Fragment>
            <div>
                <h5 className='pt-2 ms-2'>Recent Imports</h5>
            </div>
            <div>
                <div>
                    <TableComponent 
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                        thClass="border-bottom-1 table-soft-primary" 
                        columns={columns} 
                        data={data} />                   
                
                </div>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(RecentImports);
