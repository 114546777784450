import React from "react";
import { withTranslation } from "react-i18next";
import { Button, Label, UncontrolledTooltip } from "reactstrap";
import TableConfigOptions from "./ConfigOptions/TableConfigOptions";
import KPIConfigOptions from "./ConfigOptions/KPIConfigOptions";
import BarConfigOptions from "./ConfigOptions/BarConfigOptions";
import LineConfigOptions from "./ConfigOptions/LineConfigOptions";
import AreaConfigOptions from "./ConfigOptions/AreaConfigOptions";
import PieConfigOptions from "./ConfigOptions/PieConfigOptions";
import DonutConfigOptions from "./ConfigOptions/DonutConfigOptions";

interface ConfigureProps {
    chart: string;
    columns: any;
    groupBy: any;
    value: any;
    compareBy: any;
    xAxis: any;
    yAxis: any;
    fields: any;
    setChart: (chart: string) => void
    moveColumns: (dragIndex: number, hoverIndex: number, isDragging: boolean) => void
    onColumnUpdated: (newfilter: any) => void
    onColumnDelete: (deleteColumn: any) => void
    onModifyGroupBy: (property: any, dataSource: any) => void;
    onModifyValue: (property: any, dataSource: any) => void;
    onModifyCompareBy: (property: any, dataSource: any) => void;
    setCompareBy: (compareBy: any) => void;
    onModifyXAxis: (property: any, dataSource: any) => void;
    onModifyYAxis: (property: any, dataSource: any) => void;
    setXAxis: (xAxis: any) => void;
    moveFields: (dragIndex: number, hoverIndex: number, isDragging: boolean) => void
    onFieldUpdated: (newfilter: any) => void
    onFieldDelete: (deleteColumn: any) => void
    t: (key: string) => string;
}

const Configure: React.FC<ConfigureProps>  = ({
    t,
    chart,
    columns,
    groupBy,
    value,
    compareBy,
    xAxis,
    yAxis,
    fields,
    setChart,
    moveColumns,
    onColumnUpdated,
    onColumnDelete,
    onModifyGroupBy,
    onModifyValue,
    onModifyCompareBy,
    setCompareBy,
    onModifyXAxis,
    onModifyYAxis,
    setXAxis,
    moveFields,
    onFieldUpdated,
    onFieldDelete
}) => {
    const handleConfigOptions = (chart: string) => {
        switch(chart) {
            case 'count':
                return (
                    <KPIConfigOptions
                        groupBy={groupBy}
                        value={value}
                        compareBy={compareBy}
                        fields={fields}
                        onModifyGroupBy={onModifyGroupBy}
                        onModifyValue={onModifyValue}
                        onModifyCompareBy={onModifyCompareBy}
                        setCompareBy={setCompareBy}
                        moveFields={moveFields}
                        onFieldUpdated={onFieldUpdated}
                        onFieldDelete={onFieldDelete}
                    />)
            case 'bar':
                return (
                    <BarConfigOptions
                        groupBy={groupBy}
                        compareBy={compareBy}
                        xAxis={xAxis}
                        yAxis={yAxis}
                        fields={fields}
                        onModifyGroupBy={onModifyGroupBy}
                        onModifyCompareBy={onModifyCompareBy}
                        setCompareBy={setCompareBy}
                        onModifyXAxis={onModifyXAxis}
                        onModifyYAxis={onModifyYAxis}
                        setXAxis={setXAxis}
                        moveFields={moveFields}
                        onFieldUpdated={onFieldUpdated}
                        onFieldDelete={onFieldDelete}
                    />)
            case 'line':
                return (
                    <LineConfigOptions
                        groupBy={groupBy}
                        compareBy={compareBy}
                        xAxis={xAxis}
                        yAxis={yAxis}
                        fields={fields}
                        onModifyGroupBy={onModifyGroupBy}
                        onModifyCompareBy={onModifyCompareBy}
                        setCompareBy={setCompareBy}
                        onModifyXAxis={onModifyXAxis}
                        onModifyYAxis={onModifyYAxis}
                        setXAxis={setXAxis}
                        moveFields={moveFields}
                        onFieldUpdated={onFieldUpdated}
                        onFieldDelete={onFieldDelete}
                    />)
            case 'area':
                return (
                    <AreaConfigOptions
                        groupBy={groupBy}
                        compareBy={compareBy}
                        xAxis={xAxis}
                        yAxis={yAxis}
                        fields={fields}
                        onModifyGroupBy={onModifyGroupBy}
                        onModifyCompareBy={onModifyCompareBy}
                        setCompareBy={setCompareBy}
                        onModifyXAxis={onModifyXAxis}
                        onModifyYAxis={onModifyYAxis}
                        setXAxis={setXAxis}
                        moveFields={moveFields}
                        onFieldUpdated={onFieldUpdated}
                        onFieldDelete={onFieldDelete}
                    />)
            case 'pie':
                return (
                    <PieConfigOptions
                        groupBy={groupBy}
                        value={value}
                        fields={fields}
                        onModifyGroupBy={onModifyGroupBy}
                        onModifyValue={onModifyValue}
                        moveFields={moveFields}
                        onFieldUpdated={onFieldUpdated}
                        onFieldDelete={onFieldDelete}
                    />)
            case 'donut':
                return (
                    <DonutConfigOptions
                        groupBy={groupBy}
                        value={value}
                        fields={fields}
                        onModifyGroupBy={onModifyGroupBy}
                        onModifyValue={onModifyValue}
                        moveFields={moveFields}
                        onFieldUpdated={onFieldUpdated}
                        onFieldDelete={onFieldDelete}
                    />)
            default:
                return (
                    <TableConfigOptions
                        columns={columns}
                        fields={fields}
                        moveColumns={moveColumns}
                        onColumnUpdated={onColumnUpdated}
                        onColumnDelete={onColumnDelete}
                        moveFields={moveFields}
                        onFieldUpdated={onFieldUpdated}
                        onFieldDelete={onFieldDelete}
                    />)
        }
    }
    return (
        <div className="d-flex flex-column gap-4 p-2 h-100">
            <div className="d-flex flex-column gap-1">
                <Label className="m-0 fw-bold fs-5">Charts</Label>
                <div className="d-flex flex-wrap gap-2 overflow-y-scroll">
                    <UncontrolledTooltip
                        placement="top"
                        target="tooltip-table"
                    >
                        Table
                    </UncontrolledTooltip>
                    <Button
                        id="tooltip-table"
                        color="primary"
                        className={`btn-icon ${chart !== 'table' ? 'btn-soft-primary' : ''}`}
                        onClick={() => setChart('table')}
                        placement="bottom"
                        >
                        <i className="ri-table-fill" />
                    </Button>
                    <UncontrolledTooltip
                        placement="top"
                        target="tooltip-kpi"
                    >
                        KPI
                    </UncontrolledTooltip>
                    <Button
                        id="tooltip-kpi"
                        color="primary"
                        className={`btn-icon ${chart !== 'count' ? 'btn-soft-primary' : ''}`}
                        onClick={() => setChart('count')}
                        >
                        <i className="ri-focus-fill" />
                    </Button>
                    <UncontrolledTooltip
                        placement="top"
                        target="tooltip-bar"
                    >
                        Bar
                    </UncontrolledTooltip>
                    <Button
                        id="tooltip-bar"
                        color="primary"
                        className={`btn-icon ${chart !== 'bar' ? 'btn-soft-primary' : ''}`}
                        onClick={() => setChart('bar')}
                        >
                        <i className="ri-bar-chart-2-fill" />
                    </Button>
                    <UncontrolledTooltip
                        placement="top"
                        target="tooltip-line"
                    >
                        Line
                    </UncontrolledTooltip>
                    <Button
                        id="tooltip-line"
                        color="primary"
                        className={`btn-icon ${chart !== 'line' ? 'btn-soft-primary' : ''}`}
                        onClick={() => setChart('line')}
                        >
                        <i className="ri-line-chart-fill" />
                    </Button>
                    <UncontrolledTooltip
                        placement="top"
                        target="tooltip-area"
                    >
                        Area
                    </UncontrolledTooltip>
                    <Button
                        id="tooltip-area"
                        color="primary"
                        className={`btn-icon ${chart !== 'area' ? 'btn-soft-primary' : ''}`}
                        onClick={() => setChart('area')}
                        >
                        <i className="mdi mdi-chart-areaspline-variant" />
                    </Button>
                    <UncontrolledTooltip
                        placement="top"
                        target="tooltip-pie"
                    >
                        Pie
                    </UncontrolledTooltip>
                    <Button
                        id="tooltip-pie"
                        color="primary"
                        className={`btn-icon ${chart !== 'pie' ? 'btn-soft-primary' : ''}`}
                        onClick={() => setChart('pie')}
                        >
                        <i className="ri-pie-chart-2-fill" />
                    </Button>
                    <UncontrolledTooltip
                        placement="top"
                        target="tooltip-donut"
                    >
                        Donut
                    </UncontrolledTooltip>
                    <Button
                        id="tooltip-donut"
                        color="primary"
                        className={`btn-icon ${chart !== 'donut' ? 'btn-soft-primary' : ''}`}
                        onClick={() => setChart('donut')}
                        >
                        <i className="ri-donut-chart-fill" />
                    </Button>
                </div>
            </div>
            {handleConfigOptions(chart)}
        </div>
    )
};

export default withTranslation()(Configure);
