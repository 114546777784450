export interface ApplicationsMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface notes   {
    id: string,
    shortNote: string,
    detailedNote: string,
    createdBy: string,
    createdAt: string,
    replies: any,
    updatedAt: string,
    deletedAt: string
}
export interface Applications {
    id: string,
    userId: string,
    valuesJson: any,
    notes: notes[],
    tasks: any,
    emails: any,
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
  }

export enum ApplicationsActionTypes {
    GET_APPLICATIONSMETADATA_REQUEST = "@@application/GET_APPLICATIONSMETADATA_REQUEST",
    GET_APPLICATIONSMETADATA_SUCCESS = "@@application/GET_APPLICATIONSMETADATA_SUCCESS",
    GET_APPLICATIONSMETADATA_ERROR = "@@application/GET_APPLICATIONSMETADATA_ERROR",
    GET_APPLICATIONS_EMAILS_REQUEST = "@@application/GET_APPLICATIONS_EMAILS_REQUEST",
    GET_APPLICATIONS_EMAILS_SUCCESS = "@@application/GET_APPLICATIONS_EMAILS_SUCCESS",
    GET_APPLICATIONS_EMAILS_ERROR = "@@application/GET_APPLICATIONS_EMAILS_ERROR",
    GET_APPLICATIONS_REQUEST = "@@application/GET_APPLICATIONS_REQUEST",
    GET_APPLICATIONS_SUCCESS = "@@application/GET_APPLICATIONS_SUCCESS",
    GET_APPLICATIONS_ERROR = "@@application/GET_APPLICATIONS_ERROR",
    GET_APPLICATION_REQUEST = "@@application/GET_APPLICATION_REQUEST",
    GET_APPLICATION_SUCCESS = "@@application/GET_APPLICATION_SUCCESS",
    GET_APPLICATION_ERROR = "@@application/GET_APPLICATION_ERROR",
    PUT_APPLICATIONS_REQUEST = "@@application/PUT_APPLICATIONS_REQUEST",
    PUT_APPLICATIONS_SUCCESS = "@@application/PUT_APPLICATIONS_SUCCESS",
    PUT_APPLICATIONS_ERROR = "@@application/PUT_APPLICATIONS_ERROR",
    POST_APPLICATIONS_EMAIL_REQUEST = "@@application/POST_APPLICATIONS_EMAIL_REQUEST",
    POST_APPLICATIONS_EMAIL_SUCCESS = "@@application/POST_APPLICATIONS_EMAIL_SUCCESS",
    POST_APPLICATIONS_EMAIL_ERROR = "@@application/POST_APPLICATIONS_EMAIL_ERROR",
    DELETE_APPLICATIONS_REQUEST = "@@application/DELETE_APPLICATIONS_REQUEST",
    DELETE_APPLICATIONS_SUCCESS = "@@application/DELETE_APPLICATIONS_SUCCESS",
    DELETE_APPLICATIONS_ERROR = "@@application/DELETE_APPLICATIONS_ERROR",
    POST_APPLICATIONS_REQUEST = "@@application/POST_APPLICATIONS_REQUEST",
    POST_APPLICATIONS_SUCCESS = "@@application/POST_APPLICATIONS_SUCCESS",
    POST_APPLICATIONS_ERROR = "@@application/POST_APPLICATIONS_ERROR",    
    POST_NOTE_REQUEST = "@@application/POST_NOTE_REQUEST",
    POST_NOTE_SUCCESS = "@@application/POST_NOTE_SUCCESS",
    POST_NOTE_ERROR = "@@application/POST_NOTE_ERROR",
    GET_NOTE_REQUEST = "@@application/GET_NOTE_REQUEST",
    GET_NOTE_SUCCESS = "@@application/GET_NOTE_SUCCESS",
    GET_NOTE_ERROR = "@@application/GET_NOTE_ERROR",
    GET_NOTES_REQUEST = "@@application/GET_NOTES_REQUEST",
    GET_NOTES_SUCCESS = "@@application/GET_NOTES_SUCCESS",
    GET_NOTES_ERROR = "@@application/GET_NOTES_ERROR",
    DELETE_NOTE_REQUEST = "@@application/DELETE_NOTE_REQUEST",
    DELETE_NOTE_SUCCESS = "@@application/DELETE_NOTE_SUCCESS",
    DELETE_NOTE_ERROR = "@@application/DELETE_NOTE_ERROR",
    POST_TASK_REQUEST = "@@application/POST_TASK_REQUEST",
    POST_TASK_SUCCESS = "@@application/POST_TASK_SUCCESS",
    POST_TASK_ERROR = "@@application/POST_TASK_ERROR",
    GET_TASK_REQUEST = "@@application/GET_TASK_REQUEST",
    GET_TASK_SUCCESS = "@@application/GET_TASK_SUCCESS",
    GET_TASK_ERROR = "@@application/GET_TASK_ERROR",
    GET_TASKS_REQUEST = "@@application/GET_TASKS_REQUEST",
    GET_TASKS_SUCCESS = "@@application/GET_TASKS_SUCCESS",
    GET_TASKS_ERROR = "@@application/GET_TASKS_ERROR",
    DELETE_TASK_REQUEST = "@@application/DELETE_TASK_REQUEST",
    DELETE_TASK_SUCCESS = "@@application/DELETE_TASK_SUCCESS",
    DELETE_TASK_ERROR = "@@application/DELETE_TASK_ERROR"
}

export interface ApplicationsState {
    readonly list: Applications[] | null;
    readonly details: Applications | null;
    readonly pagination: Pagination | null;
    readonly metaDataFields: ApplicationsMetadata[];
    readonly emails: any;
    readonly notes: any;
    readonly tasks: any;
    readonly loading: boolean;
    readonly dataLoading: boolean;
    readonly metaLoading: boolean;
}
