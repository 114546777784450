import React, { useEffect, useState, useCallback } from 'react';
import { Col, Nav, NavItem, NavLink, Row, UncontrolledDropdown, UncontrolledCollapse, Button, UncontrolledButtonDropdown, Modal, ModalBody, ModalHeader, Input, Card } from 'reactstrap';
import classnames from "classnames";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { withTranslation } from "react-i18next";
//SimpleBar
import SimpleBar from "simplebar-react";
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { SendEmail, getStudentsEmails, studentGetRequest } from 'store/student/action';
import { ApplicationState } from 'store';
import DtsEmailsInputs from 'Components/Common/FormBuilder/DtsEmailsInputs';
import DeleteModal from '../Views/innerComponent/DeleteModal';
// Import Images
import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import { getGoogleCalendarData } from 'store/calendarSync/action';
import ConnectAccount from './ConnectAccount';

const editorConfig = {
    removePlugins: ['Table', 'TableToolbar', 'TableTools', 'InsertTable', 'about'],
};

const EmailToolbar = (props: any) => {
    const { communicate, details, model, to } = props;
    let { id } = useParams();
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const emails = useSelector((state: ApplicationState) => state.student.emails);
    const googleConnect = useSelector((state: ApplicationState) => state.calendarSync.data);
    const paginationDetails = useSelector((state: ApplicationState) => state.student.emailspagination);
    const [emailOpen, setEmailOpen] = useState<any>(false)
    const [isccOn, setisccOn] = useState(false)
    const [isBccOn, setisBccOn] = useState(false)
    const [disabled, setdisabled] = useState(true)
    const [composeDisabled, setComposeDisabled] = useState(false)
    const [studentId, setStudentId] = useState<any>(null);
    const [applicationId, setApplicationId] = useState<any>(null);
    const [emailData, setEmailData] = useState<any>({
        studentId: studentId,
        applicationId: applicationId,
        to: to
    })
    const [search, setsearch] = useState<string>("");
    const [isTypeTab, setIsTypeTab] = useState<string>("primary");
    const [displayCategory, setCategory] = useState<string>("all");
    const [filterObject, setFilterObject] = useState<any>({});
    const [pageSize, setPageSize] = useState<number>(10)
    const [pagesList, setPagesList] = useState<any>([]);
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )
    const [deleteData, setDeleteData] = useState<any>(false)

    useEffect(() => {
        dispatch(getGoogleCalendarData())
    }, [])

    const handleGetList = (filters?: any, page?: any) => {
        if (model == "students") {
            if (id) {
                setStudentId(id)
                setEmailData({ to: to, studentId: id, })
                dispatch(getStudentsEmails(id, filters));
            }
        }
        else {
            if (details && details.id) {
                setApplicationId(details.id)
                let studentsId = details?.valuesJson?.student ? details?.valuesJson?.student?.id : "";
                setStudentId(studentsId)
                setEmailData({ to: to, studentId: studentsId, applicationId: id, subject: "" })
                let filterObject = { ...filters, applicationId: id }
                dispatch(getStudentsEmails(studentsId, filterObject, page))
            }
        }
    }

    useEffect(() => {
        handleGetList()
    }, [details])

    const onChangePageSize = (event: any) => {
        const { value } = event.target;
        setPagination({ ...pagination, pageSize: value })
        setPageSize(value)
        const filters = { ...filterObject, pageSize: value }
        setFilterObject(filters)
        handleGetList(filters)
    }


    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            default:
                newPageNo = pageVal;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        handleGetList(filterObject, newPageNo)
    };

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    }, [paginationDetails])

    useEffect(() => {
        if (details && details.id) {
            setEmailData({ ...emailData, to: to })
        }
    }, [details])
    const onSend = () => {
        setComposeDisabled(true)
        const data = {
            ...emailData,
            parentId: "",
            studentId: studentId,
            applicationId: applicationId
        }
        const handleSuccess = () => {
            handleGetList()
            setModal(false)
            setInnitial()
            setComposeDisabled(false)
        }
        const handleFailure = () => {
            setComposeDisabled(false)
        }
        dispatch(SendEmail(data, handleSuccess, handleFailure))
    }

    const onReply = () => {
        let toemail = emailinfo && emailinfo.to ? emailinfo.to : "";
        const data = {
            parentId: emailinfo.id,
            studentId: studentId,
            applicationId: applicationId,
            to: toemail,
            subject: emailinfo?.subject ? emailinfo?.subject : "",
            body: emailData?.body ? emailData?.body : "",
            bcc: emailinfo?.bcc ? [...emailinfo?.bcc?.split(",")] : null,
            cc: emailinfo?.cc ? [...emailinfo?.cc?.split(",")] : null
        }
        const handleSuccess = () => {
            handleGetList()
            setInnitial()
            setEmailData({ to: to, applicationId: applicationId, studentId: studentId })
            setEmailOpen(false)
        }
        const handleFailure = () => { }
        dispatch(SendEmail(data, handleSuccess, handleFailure))
    }

    useEffect(() => {
        let element: any = document.getElementById('emailsrighbBar');
        if (element && element[0]) element[0].classList.remove("email-detail-show")
    }, []);


    const [modal, setModal] = useState<boolean>(false);

    const toggle = () => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    };

    // delete button toggle
    const onChangeCheckBox = (value: any, check: any) => {
        const element: any = document.getElementById("email-topbar-actions");
        const checkedCount = document.querySelectorAll('.checkbox-wrapper-mail input:checked').length;
        const activeList: any = document.getElementById(value);

        if (checkedCount >= 1) {
            element.style.display = 'block';
        } else {
            element.style.display = 'none';
        }

        if (check) {
            activeList.classList.add("active");
        } else {
            activeList.classList.remove("active");
        }
    };

    // Delete Email
    const removeEmail = () => {
        const element: any = document.querySelectorAll(".message-list li");
        const ele: any = document.getElementById("email-topbar-actions");
        const checkall: any = document.getElementById("checkall");
        let emailelement: any = document.getElementById('emailsrighbBar');
        if (emailelement && emailelement[0]) emailelement[0].classList.remove("email-detail-show")

        if (deleteData) {
            document.getElementById(emailinfo.forId)?.classList.add("active")
        }

        element.forEach((element: any) => {

            if (element.classList.contains('active')) {
                var forId = element.querySelector('.form-check-input').value;

                if (displayCategory === "trash") {
                    // (forId);
                } else {
                    // (forId);
                }
            }
            element.classList.remove("active");
            element.querySelector('.form-check-input').checked = false;


        });

        setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
        checkall.checked = false;
        ele.style.display = 'none';

        setDeleteData(false)
        setEmailinfo([])
    };

    // Stared Mail
    const favouriteBtn = (item: any) => {
        if (!item) {
            document.querySelectorAll(".message-list li.active:not(:has(button.active))").forEach(function (element: any) {
            });
        } else {
            if (emailinfo.id === item.id) {
                document.querySelector(".email-detail-content")?.querySelector(".favourite-btn")?.classList.toggle("active")
            }
        }

    };

    const [emailinfo, setEmailinfo] = useState<any>([]);

    //delete order
    const [deleteModal, setDeleteModal] = useState<boolean>(false);

    // Refresh Mails

    const [refreshLoader, setRefreshLoader] = useState(false)
    const messageText = "You need to add your social email client account to use Email feature to log emails in Zilter."
    function handleDocumentClick(event: any) {
        const emailMenuSidebar = document.querySelector(".email-menu-sidebar");
        const emailMenuBtn = document.querySelector(".email-menu-btn");

        if (emailMenuBtn && !emailMenuBtn.contains(event.target)) {
            emailMenuSidebar?.classList.remove("menubar-show");
        } else {
            document.querySelector(".email-menu-sidebar")?.classList.add("menubar-show")
        }
    }

    document.addEventListener("click", handleDocumentClick);


    useEffect(() => {
        let element: any = document.getElementById('emailtab');
        if (element) {
            emailOpen ? element.classList.add("email-detail-show") : element.classList.remove("email-detail-show")
        }
    }, [emailOpen])

    const handleChange = (value: any) => {
        setsearch(value);
        const filters = { ...filterObject, search: value }
        setFilterObject(filters)
        handleGetList(filters)
    };

    useEffect(() => {
        if (isBccOn) {
            const getID = document.getElementById('BccRecipientsCollapse') as HTMLElement
            if (getID) getID.classList.add("show");
        }
        else {
            const getID = document.getElementById('BccRecipientsCollapse') as HTMLElement
            if (getID) getID.classList.remove("show");
        }
        if (isccOn) {
            const getID = document.getElementById('CcRecipientsCollapse') as HTMLElement
            if (getID) getID.classList.add("show");
        }
        else {
            const getID = document.getElementById('CcRecipientsCollapse') as HTMLElement
            if (getID) getID.classList.remove("show");
        }
    }, [isBccOn, isccOn])

    const setInnitial = () => {
        if (details && details.id) {
            setEmailData({ to: to, studentId: studentId, applicationId: applicationId })
            handleGetList()
        }
    }


    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => {
                    removeEmail();
                    setDeleteModal(false);
                }}
                onCloseClick={() => setDeleteModal(false)}
            />
            {googleConnect && googleConnect.length > 0 ? (
                <div className="email-content">
                    <div className="pb-0">
                        <div className="">
                            <Row className="mt-n2">
                                <div className="">
                                    <div className="d-flex justify-content-between gap-sm-1 p-2 email-topbar-link">

                                        <div className="search-box w-50">
                                            <Input
                                                type="text"
                                                size={14}
                                                className="search"
                                                placeholder={props.t("student.search_for")}
                                                onChange={(e) => handleChange(e.target.value)}
                                                value={search}
                                            />
                                            {search == "" ? <i className="ri-search-line search-icon ms-2"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer ms-2" onClick={() => handleChange("")}></i>}

                                        </div>
                                        {(userProfile?.subAgent?.enable_communication !== "false" ||
                                            (userProfile?.subAgent?.enable_communication !== "false" && communicate)) && (
                                                <Button
                                                    onClick={() => { setModal(true); setInnitial() }}
                                                    color="primary"
                                                    disabled={composeDisabled}
                                                    className="btn-label">
                                                    <i className="ri-mail-add-fill label-icon align-middle fs-16 me-2"></i>
                                                    {props.t("student.compose")}
                                                </Button>)}
                                    </div>
                                </div>
                            </Row>
                            <Row className="row align-items-end mt-3">
                                <Col>
                                    <div id="mail-filter-navlist">
                                        <Nav
                                            className="nav nav-tabs nav-tabs-custom nav-primary gap-1 text-center border-bottom-0"
                                            role="tablist"
                                        >
                                            <NavItem>
                                                <NavLink
                                                    className={classnames(
                                                        { active: isTypeTab === "primary" },
                                                        "fw-semibold text-primary"
                                                    )}
                                                >
                                                    <i className="ri-inbox-fill align-bottom d-inline-block"></i>
                                                    <span className="ms-1 d-none d-sm-inline-block">{props.t("student.primary")}</span>
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Card>
                            <div className="message-list-content mx-n4 px-4 message-list-scroll">
                                {refreshLoader ? <div id="elmLoader">
                                    <div className="spinner-border text-primary avatar-sm" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                    :
                                    <ul className="message-list" id="mail-list">
                                        {!emails || !emails.length ? (
                                            <li className="text-center">{props.t("student.no_messages_available")}</li>
                                        ) : <div>{

                                            (emails.map((item: any, key: any) => {
                                                let teaser = item.body
                                                    ? item.body
                                                        .replace(/<[^>]+>/g, '') // remove HTML tags
                                                        .replace(/&nbsp;/g, '') // remove non-breaking spaces
                                                        .replace(/&amp;/g, '') // remove ampersands
                                                        .replace(/&quot;/g, '') // remove quotes
                                                        .replace(/&#\d+;/g, '') // remove HTML entities (e.g., &#39;)
                                                        .trim() // remove leading/trailing whitespace
                                                    : "";
                                                return <li className={classnames({ unread: item.unread })} key={key} id={item.id}>
                                                    <div className="col-mail col-mail-1">
                                                        <div className="form-check checkbox-wrapper-mail fs-14">
                                                            <input
                                                                onChange={(e: any) => onChangeCheckBox(e.target.value, e.target.checked)}
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value={item.id}
                                                                id={item.id}
                                                            />
                                                            <label className="form-check-label" htmlFor={item.id}></label>
                                                        </div>
                                                        <button type="button" className={item.category === "starred" ? "btn avatar-xs p-0 favourite-btn fs-15 active" : "btn avatar-xs p-0 favourite-btn fs-15"} onClick={() => favouriteBtn(item)}>
                                                            <i className='ri-star-fill' />
                                                        </button>
                                                        <Link to="#" className="title" onClick={() => { setEmailOpen(!emailOpen); setEmailinfo(item) }}>{item?.createdBy?.name}</Link>
                                                    </div>
                                                    <div className="col-mail col-mail-2" onClick={() => { setEmailOpen(!emailOpen); setEmailinfo(item) }}>
                                                        <Link to="#" className="subject"> {item.badge ? <span className="me-2 ">{item.badge}</span> : null} {item.subject} - <span className="teaser">{teaser}</span>
                                                        </Link>
                                                        <div className="date">{moment(item.createdAt).format('MMM DD')}</div>
                                                    </div>
                                                </li>
                                            }))}
                                            <div className="col-auto">
                                                <div className="col-sm">
                                                    <div className="text-muted">{props.t("student.showing")}<span className="fw-semibold ms-1">{emails.length}</span> {props.t("student.of")} <span className="fw-semibold">{pagination && pagination.elements ? pagination.elements : 0}</span> {props.t("student.results")}
                                                    </div>
                                                </div>
                                            </div>
                                            <Row className="align-items-center mt-2 g-3 justify-content-between">

                                                <Col className='justify-content-start'>
                                                    <ul className="pagination pagination-separated pagination-md justify-content-between justify-content-sm-start mb-0">
                                                        <li className={pagination && pagination.first ? "page-item disabled" : "page-item"}>
                                                            <Link to="#" className="page-link" onClick={() => pageChanged('prev')}>{props.t("student.previous")}</Link>
                                                        </li>
                                                        {pagesList.map((item: any, key: any) => (
                                                            <React.Fragment key={key}>
                                                                <li className="page-item">
                                                                    <Link to="#" className={pagination && pagination.page == item ? "page-link active" : "page-link"} onClick={() => pageChanged(item)}>{item + 1}</Link>
                                                                </li>
                                                            </React.Fragment>
                                                        ))}
                                                        <li className={pagination && pagination.last ? "page-item disabled" : "page-item"}>
                                                            <Link to="#" className="page-link" onClick={() => pageChanged('next')}>{props.t("student.next")}</Link>
                                                        </li>
                                                    </ul>
                                                </Col>
                                                <Col className='hstack justify-content-end'>
                                                    <li>
                                                        <div>
                                                            <select
                                                                className="form-select w-auto"
                                                                value={pageSize}
                                                                onChange={onChangePageSize}
                                                            >
                                                                {[10, 20, 30, 40, 50, 100].map((pageSize: any) => (
                                                                    <option key={pageSize} value={pageSize}>
                                                                        {props.t("student.show")} {pageSize}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </li>
                                                </Col>
                                            </Row>
                                        </div>
                                        }
                                    </ul>}
                            </div>
                        </Card>
                    </div>
                </div>
            ) :
                (
                    <>
                        <ConnectAccount infomessage={messageText} />
                    </>)}

            <div className="email-detail-content email-detail-content-scroll h-100">
                <div className="p-4 d-flex flex-column">
                    <div className="pb-4 border-bottom border-bottom-dashed">
                        <Row>
                            <Col className="col">
                                <div className="">
                                    <button type="button" className="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" onClick={() => setEmailOpen(false)}>
                                        <i className="ri-close-fill align-bottom"></i>
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <SimpleBar className="mx-n4 px-4 email-detail-content-scroll">
                        <div className="mt-4 mb-3">
                            <h5 className="fw-bold email-subject-title">{emailinfo.subject}</h5>
                        </div>
                        <div className="accordion accordion-flush">
                            {/* <h6>Replies</h6> */}
                            {emailinfo && <div className="accordion-item border-dashed left">
                                <div className="accordion-header">
                                    <a role="button" href='/#' className="btn w-100 text-start px-0 bg-transparent shadow-none collapsed" id={`email-collapse1-parent`}>
                                        <div className="d-flex align-items-center text-muted">
                                            <div className="flex-shrink-0 avatar-xs me-3">
                                                <img src={avatar1} alt="" className="img-fluid rounded-circle" />
                                            </div>
                                            <div className="flex-grow-1 overflow-hidden">
                                                <h5 className="fs-14 text-truncate mb-0 email-user-name">{emailinfo?.createdBy?.name}</h5>
                                                <div className="text-truncate fs-12">To : {emailinfo?.to}</div>
                                                {emailinfo.cc && emailinfo.cc != null ? (
                                                    <div className="text-truncate fs-12">CC : {emailinfo?.cc}</div>
                                                ) : null}
                                                {emailinfo.bcc && emailinfo.bcc != null ? (
                                                    <div className="text-truncate fs-12">BCC : {emailinfo?.bcc}</div>
                                                ) : null}
                                            </div>
                                            <div className="flex-shrink-0 align-self-start">
                                                <div className="text-muted fs-12">{moment(emailinfo?.createdAt).format('DD MMM YYYY, HH:mm A')}</div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <UncontrolledCollapse toggler={`#email-collapse1-parent`}>
                                    <div className="accordion-body text-body px-0">
                                        <div dangerouslySetInnerHTML={{ __html: emailinfo?.body }} />
                                    </div>
                                </UncontrolledCollapse>
                            </div>}

                            {emailinfo && emailinfo.replies && emailinfo.replies.length ? emailinfo.replies.map((item: any, key: any) => {
                                return <div className="accordion-item border-dashed left">
                                    <div className="accordion-header">
                                        <a role="button" href='/#' className="btn w-100 text-start px-0 bg-transparent shadow-none collapsed" id={`email-collapse1-${key}`}>
                                            <div className="d-flex align-items-center text-muted">
                                                <div className="flex-shrink-0 avatar-xs me-3">
                                                    <img src={avatar1} alt="" className="img-fluid rounded-circle" />
                                                </div>
                                                <div className="flex-grow-1 overflow-hidden">
                                                    <h5 className="fs-14 text-truncate mb-0 email-user-name">{item?.createdBy?.name}</h5>
                                                    <div className="text-truncate fs-12">to:{item?.to}</div>
                                                </div>
                                                <div className="flex-shrink-0 align-self-start">
                                                    <div className="text-muted fs-12">{moment(item?.createdAt).format('DD MMM YYYY, HH:mm A')}</div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <UncontrolledCollapse toggler={`#email-collapse1-${key}`}>
                                        <div className="accordion-body text-body px-0">
                                            <div dangerouslySetInnerHTML={{ __html: item?.body }} />
                                        </div>
                                    </UncontrolledCollapse>
                                </div>
                            }) : <h6>{props.t("student.no_reples_yet")}</h6>}
                        </div>
                        <div className="mt-auto email-detail-content-scroll">
                            <form className="mt-2">
                                <div>
                                    <label htmlFor="exampleFormControlTextarea1" className="form-label">{props.t("student.reply")} :</label>
                                    <div className="ck-editor-reverse">
                                        <CKEditor
                                            editor={ClassicEditor}
                                            config={editorConfig}
                                            onReady={(editor) => { }}
                                            data={emailData?.body ? emailData?.body : ""}
                                            onChange={(event: any, editor: any) => {
                                                const data = editor.getData();
                                                setEmailData({ ...emailData, body: data })
                                            }}
                                        />
                                    </div>
                                    <div className="bg-light px-2 py-1 rouned-bottom border">
                                        <Row>
                                            <Col className="col-auto">
                                                {(userProfile?.subAgent?.enable_communication !== "false" ||
                                                    (userProfile?.subAgent?.enable_communication !== "false" && communicate)) && (
                                                        <UncontrolledButtonDropdown>
                                                            <Button color="success" disabled={!emailData.body} className="btn-sm" onClick={(e: any) => { e.preventDefault(); onReply() }}><i className="ri-send-plane-2-fill align-bottom" /></Button>

                                                        </UncontrolledButtonDropdown>
                                                    )}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </SimpleBar>
                </div>
            </div>
            <Modal backdrop="static" id="composemodal" className="modal-lg" isOpen={modal} toggle={toggle} centered>
                <ModalHeader className="p-3 bg-light" toggle={() => { setModal(!modal); setInnitial() }}>
                    New Message
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="mb-3 position-relative">
                            <Row className="hstack mb-3 position-relative">
                                <Col md={10} lg={10} className="pe-0">
                                    <DtsEmailsInputs disabled={true} placeholder={"To"} defaultValue={`${emailData.to}`} setdisabled={setdisabled} onChange={(values: any) => setEmailData({ ...emailData, to: values })} />
                                </Col>
                                <Col md={2} lg={2} className="ps-0 hstack justify-content-end">
                                    <div className="d-flex">
                                        <button
                                            className="btn btn-link text-reset fw-semibold px-2"
                                            type="button"
                                            onClick={() => setisccOn(!isccOn)}
                                        >
                                            Cc
                                        </button>
                                        <button
                                            className="btn btn-link text-reset fw-semibold px-2"
                                            type="button"
                                            onClick={() => setisBccOn(!isBccOn)}
                                        >
                                            Bcc
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {
                            isccOn ?
                                <div className="collapse" id="CcRecipientsCollapse">
                                    <div className="mb-3">
                                        <label>Cc:</label>
                                        <DtsEmailsInputs placeholder={"Cc"} setdisabled={setdisabled} onChange={(values: any) => setEmailData({ ...emailData, cc: values })} />
                                    </div>
                                </div> :
                                null}
                        {
                            isBccOn ? <div className="collapse" id="BccRecipientsCollapse">
                                <div className="mb-3">
                                    <label>Bcc:</label>
                                    <DtsEmailsInputs placeholder={"Bcc"} setdisabled={setdisabled} onChange={(values: any) => setEmailData({ ...emailData, bcc: values })} />
                                </div>
                            </div>
                                : null
                        }

                        <div className="mb-3">
                            <Input type="text" value={emailData.subject || ""} className="form-control" placeholder="Subject" onChange={(e: any) => setEmailData({ ...emailData, subject: e.target.value })} />
                        </div>
                        <div className="ck-editor-reverse">
                            <CKEditor
                                editor={ClassicEditor}
                                config={editorConfig}
                                onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.

                                }}
                                onChange={(event: any, editor: any) => {
                                    const data = editor.getData();
                                    setEmailData({ ...emailData, body: data })
                                }}
                            />
                        </div>
                    </div>
                </ModalBody>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-ghost-danger"
                        onClick={() => {
                            setModal(false);
                            setInnitial()
                        }}
                    >
                        Discard
                    </button>

                    <UncontrolledDropdown className="btn-group">
                        <button
                            type="button"
                            disabled={disabled || !emailData.subject || !emailData.body}
                            className="btn btn-success"
                            onClick={() => {
                                onSend();
                                setModal(false);
                            }}
                        >
                            Send
                        </button>
                    </UncontrolledDropdown>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default withTranslation()(EmailToolbar);