import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  UncontrolledAlert,
  ListGroup,
  ListGroupItem,
  Button,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Label,
} from "reactstrap";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import SyncOptionModal from "./SyncOptionModal";
import ConfirmSyncModal from "./ConfirmSyncModal";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { aU } from "@fullcalendar/core/internal-common";
import CircleBadge from "pages/StudentsDetails/CircleBadge";
import googleicon from "../../assets/images/googleicon.png";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import {
  disconnectGoogleAccount,
  getGoogleCalendarData,
} from "store/calendarSync/action";
import { toast } from "react-toastify";
import UserDetailsModal from "./User/UserDetailsModal";

const UserPreferences = (props: any) => {
  const auth = useSelector((state: ApplicationState) => state.auth);
  const googleConnect = useSelector(
    (state: ApplicationState) => state.calendarSync?.data
  );
  const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
  const [customActiveTab, setcustomActiveTab] = useState<any>("1");
  const [modal, setModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);
  const [tokenResponse, setTokenResponse] = useState<[] | any>();

  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  useEffect(() => {
    dispatch(getGoogleCalendarData());
    setTokenResponse(googleConnect);
  }, []);

  const handleGoogleAccountDisconnect = (email: any) => {
    const handleSuccess = (body: any): void => {
      dispatch(getGoogleCalendarData());
    };
    const handleError = (body: any): void => {
      const message = () =>
        toast(`${body.status}`, {
          position: "top-center",
          hideProgressBar: true,
          className: "bg-danger text-white",
        });
      message();
    };
    dispatch(disconnectGoogleAccount(email, handleSuccess, handleError));
  };
  return (
    <>
      {/* <Card> */}
      <UserDetailsModal />
        {/* <CardBody className="">
          <Nav
            tabs
            className="nav nav-tabs nav-tabs-custom nav-primary fs-18 mb-3"
          >
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "1",
                })}
                onClick={() => {
                  toggleCustom("1");
                }}
              >
                {props.t("userpreferences.profile")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "2",
                })}
                onClick={() => {
                  toggleCustom("2");
                }}
              >
                {props.t("userpreferences.calendar")}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={customActiveTab}>
            <TabPane tabId="1" id="home1">
              <UserDetailsModal />
            </TabPane>
            <TabPane tabId="2">
              <div>
                <text className="fs-14">
                  {props.t(
                    "userpreferences.these_preferences_only_apply_to_you"
                  )}
                </text>
                <hr />
                {googleConnect?.length === 0 ? (
                  <div>
                    <UncontrolledAlert color="danger" className="mb-xl-0">
                      <strong>
                        {props.t(
                          "userpreferences.your_calendar_is_disconnected"
                        )}
                      </strong>
                      &nbsp;
                      <span>
                        {props.t(
                          "userpreferences.connect_it_to_access_all_the_features_in_the_meetings_tool"
                        )}
                      </span>
                    </UncontrolledAlert>
                    <div className="mt-3">
                      <div>
                        <h4>{props.t("userpreferences.calendar")}</h4>
                        <p>
                          {props.t(
                            "userpreferences.connect_your_calendar_to_use_zilter_meetings_and_calendar_sync"
                          )}
                        </p>
                        <div>
                          <ListGroup>
                            <ListGroupItem>
                              <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i>{" "}
                              {props.t(
                                "userpreferences.send_meetings_directly_from_zilter"
                              )}
                            </ListGroupItem>
                            <ListGroupItem>
                              <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i>{" "}
                              {props.t(
                                "userpreferences.log_outgoing_meetings_automatically"
                              )}
                            </ListGroupItem>
                            <ListGroupItem>
                              <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i>{" "}
                              {props.t(
                                "userpreferences.sync_your_calendar_so_contacts_can_schedule_time_with_you"
                              )}
                              <p className="mx-4 fs-12">
                                &nbsp;
                                {props.t(
                                  "userpreferences.requires_calendar_sync"
                                )}
                              </p>
                            </ListGroupItem>
                            <ListGroupItem>
                              <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i>{" "}
                              {props.t(
                                "userpreferences.see_your_zilter_tasks_in_your_calendar"
                              )}
                              <p className="mx-4 fs-12">
                                &nbsp;
                                {props.t(
                                  "userpreferences.requires_an_additional_step_with_task_sync_only_available_on_Sales_starter_and_up_plans"
                                )}
                              </p>
                            </ListGroupItem>
                          </ListGroup>
                        </div>
                        <div className="my-3">
                          <Button
                            onClick={() => setModal(true)}
                            color="primary"
                            className="bg-gradient"
                          >
                            {" "}
                            {props.t(
                              "userpreferences.connect_your_calendar"
                            )}{" "}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <Card>
                      <CardBody>
                        <div className="border p-3 mt-n3 mx-n3 bg-light rounded-top">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                              <h5 className="mb-0">
                                {props.t("userpreferences.account")}
                              </h5>
                            </div>
                          </div>
                        </div>
                        {googleConnect?.map((item: { email: any }) => (
                          <div
                            key={item.email}
                            className="d-flex justify-content-between my-3 mx-3"
                            onMouseEnter={() => setIsHovered(item.email)}
                            onMouseLeave={() => setIsHovered(false)}
                          >
                            <div className="d-flex justify-content-start">
                              <div>
                                <img
                                  className="avatar-sm"
                                  src={googleicon}
                                  alt="Google Icon"
                                />
                              </div>
                              <div>
                                <Label className="ms-1 mb-0 mt-1 fs-15 fw-medium">
                                  <strong>{item.email}</strong>
                                </Label>
                                <div>
                                  <span className="ms-1">
                                    <CircleBadge />
                                  </span>
                                  {props.t("userpreferences.connected")}
                                </div>
                              </div>
                            </div>
                            {isHovered === item.email && (
                              <div>
                                <Button
                                  onClick={() =>
                                    handleGoogleAccountDisconnect(item.email)
                                  }
                                  className="btn-danger"
                                >
                                  {props.t("userpreferences.disconnect")}
                                </Button>
                              </div>
                            )}
                          </div>
                        ))}
                      </CardBody>
                    </Card>
                  </div>
                )}
                {/* <div>
                  <h4>{props.t("userpreferences.meetings")}</h4>
                  <p>
                    {props.t(
                      "userpreferences.customize_your_zilter_meetings_url_and_domain"
                    )}
                  </p>
                  <div>
                    <p className="fs-14 fw-medium">
                      {props.t("userpreferences.meetings_url")}
                    </p>
                    <p className="fs-12 fw-bold">
                      {props.t(
                        "userpreferences.your_new_meetings_url_will_only_apply_to_future_meetings_and_won't_affect_links_created_before"
                      )}
                    </p>
                  </div>
                </div> */}
              {/* </div>
            </TabPane>
          </TabContent>
        // </CardBody> */} 
      {/* </Card> */}
    </>
  );
};

export default withTranslation()(UserPreferences);
